import MyButton from "../../components/buttons";

const ErrorPage = () => {
	return (
		<div className="h-full flex items-center justify-center">
			<div className="max-w-md p-8 bg-white flex flex-col aign-center">
				<h1 className="text-4xl font-bold text-text-zs-orange mb-4 text-center">
					Error
				</h1>
				<p className="text-gray-700 mb-8">
					Some unexpected error occured.
				</p>
				<MyButton
					type="primary"
					onClick={() => window.location.reload()}
				>
					Reload Page
				</MyButton>
			</div>
		</div>
	);
};

export default ErrorPage;
