import React from 'react';
import { Drawer, List, Spin, Typography, Button } from 'antd';
import { transcript } from '../../model/IAudioTranscription';
import './index.css';

const { Title, Text } = Typography;

interface AudioTranscriptSliderProps {
  transcriptData: transcript[];
  audioSrc: string;
  onClose: () => void;
  loading: boolean;
}

const AudioTranscriptSlider: React.FC<AudioTranscriptSliderProps> = ({ transcriptData, audioSrc, onClose, loading }) => {
  const blueDot = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="rgba(102,180,236,1)">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path>
    </svg>
  );

  const pinkDot = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="rgba(224,109,213,1)">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path>
    </svg>
  );

  return (
    <Drawer
      title="Audio & Transcript"
      placement="right"
      closable={true}
      onClose={onClose}
      open={true}
      width={600}  // Adjusted to match the screenshot width
      className="audio-transcript-drawer"
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button className='close-button' type="default" onClick={onClose}>
            Close
          </Button>
        </div>
      }
    >
      {loading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Title level={5} className="section-title">Recording_1.wav</Title>
          <div className='audio-container' style={{ backgroundColor: '#fff', padding: '5px', borderRadius: '4px' }}>
            <audio controls src={audioSrc} style={{ width: '100%' }} className='audio-player-1' />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
          </div>

          <hr />

          <List
            itemLayout="horizontal"
            dataSource={transcriptData}
            renderItem={(item, index) => (
              <div className='transcript-container'>
                <div className="dot-container">
                  {/* Conditionally render the correct dot */}
                  {index % 2 === 0 ? pinkDot : blueDot}
                  {index !== transcriptData.length - 1 && <div className="line"></div>}
                </div>
                <List.Item className="transcript-item">
                  <div className='speaker-time-container'>
                    <p><strong>{item.speaker}</strong></p>
                    <p>{item.start_time}</p>
                  </div>
                  <Text className="comment">{item.comment}</Text>
                </List.Item>
              </div>
            )}
          />
        </>
      )}
    </Drawer>
  );
};

export default AudioTranscriptSlider;
