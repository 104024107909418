import React, { useEffect, useRef, useState } from "react";
import { AnglesLeft } from "@styled-icons/fa-solid/AnglesLeft";
import { AnglesRight } from "@styled-icons/fa-solid/AnglesRight";
const HorizonalScrollCards = (props: any) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const featureListRef = useRef<HTMLDivElement>(null);
	const [featureContainerWidth, setFeatureContainerWidth] = useState(0);
	const [maxWidth, setMaxWidth] = useState(0);
	const scrollTo = (scrollOffset: number) => {
		if (featureListRef.current) {
			let newPosition = scrollPosition + scrollOffset;
			newPosition = Math.max(
				0,
				Math.min(newPosition, featureContainerWidth - maxWidth)
			);
			setScrollPosition(newPosition);

			featureListRef.current.scrollLeft = newPosition;
		}
	};
	useEffect(() => {
		// Update feature container height when features change
		if (featureListRef.current) {
			setFeatureContainerWidth(featureListRef.current.scrollWidth);
			setMaxWidth(
				document.getElementById(`${props.id}-scroll-container`)
					?.scrollWidth || 0
			);
		}
	}, [props.children, scrollPosition]);
	return (
		<div className="flex items-center h-full">
			{
				<div
					className={`flex justify-start transition-all duration-500 overflow-hidden text-text-zs-light-gray ${
						scrollPosition === 0 ? "w-0" : "w-8"
					}`}
				>
					<AnglesLeft
						size={16}
						onClick={() => scrollTo(-400)}
						className="cursor-pointer"
					/>
				</div>
			}

			<div
				className="flex-1 overflow-x-hidden"
				id={`${props.id}-scroll-container`}
			>
				<div
					className="flex overflow-x-hidden scroll-smooth"
					ref={featureListRef}
				>
					{props.children}
				</div>
			</div>

			{
				<div
					className={`flex justify-end transition-all duration-500 overflow-hidden text-text-zs-light-gray ${
						scrollPosition === featureContainerWidth - maxWidth
							? "w-0"
							: "w-8"
					}`}
				>
					<AnglesRight
						size={16}
						onClick={() => scrollTo(400)}
						className="cursor-pointer"
					/>
				</div>
			}
		</div>
	);
};

export default HorizonalScrollCards;
