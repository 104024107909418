import React from "react";
import { Modal, Button, Form, Input, DatePicker, Upload, Typography, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { selectStore } from "../../state/slice/appSlice";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;
const { TextArea } = Input;

interface AnalyzeCallModalProps {
  visible: boolean;
  onCancel: () => void;
}

const AnalyzeNewCallModal: React.FC<AnalyzeCallModalProps> = ({ visible, onCancel }) => {
  const store = useSelector(selectStore);
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState<any[]>([]); // To keep track of the uploaded file

  const handleFileChange = (info: any) => {
    setFileList(info.fileList); // Update fileList state
  };

  const fetchPresignedUrl = async (): Promise<string | null> => {
    const formData = new FormData();
    formData.append("file_name", form.getFieldValue("callTitle") || "default_filename.wav"); 

    try {
        const response = await axios.post(
            "https://intelligent-automation.api.demo.zsservices.com/generate-presigned-url",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Use multipart/form-data for FormData
                },
            }
        );

        if (response.data.success) {
            return response.data.presigned_url; // Return the presigned URL
        } else {
            throw new Error(response.data.error);
        }
    } catch (error) {
        message.error(`Error fetching presigned URL: ${error instanceof Error ? error.message : "Unknown error"}`);
        return null;
    }
};

  const uploadFileToS3 = async (presignedUrl: string, file: File): Promise<void> => {
    const uploadResponse = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error("File upload failed");
    }
  };

  const saveFileMetadata = async (metadata: any): Promise<void> => {
    const formData = new FormData();
    formData.append("batch_id", String(metadata.batch_id));  // Ensure batch_id is string
    formData.append("file_name", String(metadata.file_name));
    formData.append("file_description", String(metadata.file_description));
    formData.append("date_of_upload", new Date().toISOString().split('T')[0]); // Format to YYYY-MM-DD
    formData.append("date_of_recording", metadata.date_of_recording); // Already in YYYY-MM-DD format
    formData.append("doc_reviewer", String(metadata.doc_reviewer));
    formData.append("doc_status", String(metadata.doc_status));    

    try {
        const response = await axios.post(
            "https://intelligent-automation.api.demo.zsservices.com/upload_audio",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Use multipart/form-data for FormData
                },
            }
        );

        if (!response.data.success) {
            throw new Error(response.data.error);
        }
    } catch (error) {
        message.error(`Error saving metadata: ${error instanceof Error ? error.message : "Unknown error"}`);
        throw error; // Rethrow to handle in the main flow
    }
};

  const handleFinish = async () => {
    if (!form.getFieldValue("callTitle")) {
      message.error("Call Title is required");
      return;
    }

    if (fileList.length > 0) {
      const loadingMessage = message.loading("Processing...");

      try {
        const presignedUrl = await fetchPresignedUrl(); // Step 1: Get presigned URL

        if (presignedUrl) {
          const file = fileList[0].originFileObj;

          // Step 2: Upload the file to S3
          await uploadFileToS3(presignedUrl, file); // Upload the file

          // Step 3: Prepare metadata and send to the backend
          const metadata = {
            batch_id: "12", // Replace with dynamic value if needed
            file_name: file.name,
            file_description: form.getFieldValue("callDescription") || "",
            date_of_recording: form.getFieldValue("dateOfRecording")?.format("YYYY-MM-DD") || "", // Ensure it's in the correct format
            doc_reviewer: "Naman", // Replace with actual reviewer if needed
            doc_status: "pending",
          };

          await saveFileMetadata(metadata); // Save metadata

          loadingMessage(); // Close loading message
          message.success("File uploaded successfully");
          form.resetFields();
          setFileList([]); // Clear file list
          onCancel();
        }
      } catch (error) {
        loadingMessage(); // Close loading message
        message.error(`File upload failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    } else {
      message.error("Please select a file before saving.");
    }
  };

  return (
    <Modal
      title="Analyze New Call"
      className="new-call-modal"
      open={visible}
      onCancel={onCancel}
      footer={[
        <div style={{ textAlign: "right" }} key="footer">
          <Button type="default" className="close-action-button" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" className="share-response-action-button" onClick={handleFinish}>
            Save
          </Button>
        </div>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Call Title" className="call-header-labels" name="callTitle">
          <Input placeholder="Enter Call Title" />
        </Form.Item>
        <Form.Item label="Call Description" className="call-header-labels" name="callDescription">
          <TextArea placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          label="Date of Recording"
          tooltip="Select the date the call was recorded"
          className="call-header-labels"
          name="dateOfRecording"
        >
          <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item label="Call Recording" className="call-header-labels" name="file">
          <Upload 
            customRequest={handleFileChange} 
            onChange={handleFileChange} 
            beforeUpload={() => false} 
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>Click or drag file to this area to upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AnalyzeNewCallModal;
