import { useState } from "react";
import { Document, Page } from "react-pdf";
import "./index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Spin } from "antd";
//
function PdfView(props: { presignedUrl: string }) {
	const [numPages, setNumPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState<number>(1);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}

	return (
		<div
			style={{
				height: "90vh",
				overflowY: "auto",
				boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
			}}
		>
			<Document
				// file="https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"
				file={props.presignedUrl}
				// options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}
				onLoadSuccess={onDocumentLoadSuccess}
				loading={() => (
					<div
						className="flex items-center justify-center"
						style={{ height: "90vh" }}
					>
						<Spin size="default" />
					</div>
				)}
				noData={() => (
					<div
						className="flex items-center justify-center"
						style={{ height: "90vh" }}
					>
						<Spin size="default" />
					</div>
				)}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						width={550}
					/>
				))}
				{/* <Page pageNumber={pageNumber} /> */}
			</Document>
			{/* <p>Page Numner</p> */}
		</div>
	);
}

export default PdfView;
