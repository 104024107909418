import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tag, Spin, Button, Input, Checkbox, Typography, Modal, Form, DatePicker, Upload } from "antd";
import { ITableData } from "../../model/ITableData";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setContractDetails, setUserDetails } from "../../state/slice/appSlice";
import axios from "axios";
import { SearchOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ErrorPage from "../errorPage";
import ChatBot from "../../components/chatbot";
import AnalyzeNewCallModal from "../../components/analyzeNewCallModal";
import './index.css'; // Import the CSS file

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

interface QueryItem {
  count: number;
  q_id: number;
  query: string;
}

const AudioCatalog = () => {
  const store = useSelector(selectStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<ITableData[]>();
  const [error, setError] = useState<boolean>();
  const [batchId, setBatchId] = useState<any>();
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [filterText, setFilterText] = useState<string>(""); // State for filtering
  const [viewMyCallsOnly, setViewMyCallsOnly] = useState<boolean>(false); // State for checkbox
  const chatBotRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatQueries, setChatQueries] = useState([]);

  const reviewContract = (record: ITableData) => {
    dispatch(setContractDetails({ name: record.contractName, id: record.id }));
    sessionStorage.setItem("contractId", record.id.toString());
    sessionStorage.setItem("contractName", record.contractName);
    navigate("" + record.id);
  };

  const sortData = (data: ITableData[], order: "asc" | "desc") => {
    return data.sort((a, b) =>
      order === "asc"
        ? a.contractName.localeCompare(b.contractName)
        : b.contractName.localeCompare(a.contractName)
    );
  };

  const toggleSortOrder = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newOrder);
    if (tableData) {
      const sortedData = sortData([...tableData], newOrder);
      setTableData(sortedData);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filterData = (data: ITableData[], text: string, viewMyCallsOnly: boolean) => {
    return data.filter((item) => {
      const matchesText = item.contractName.toLowerCase().includes(text.toLowerCase()) ||
                          item.reviewerName.toLowerCase().includes(text.toLowerCase()) ||
                          item.contractDesc.toLowerCase().includes(text.toLowerCase());
      
      const matchesReviewer = !viewMyCallsOnly || item.reviewerEmpID === store.userDetails.username;
      
      return matchesText && matchesReviewer;
    });
  };

  const fetchData = async () => {
    console.log("store: ", { store });

    try {
      const res = await axios.get(
        `https://intelligent-automation.api.demo.zsservices.com/contracts/${store.userDetails.username}/${store.selectedActiveAgent.id}`
      );
      console.log("FETCHED DATA", res.data);

      if (Array.isArray(res.data)) {
        const sortedData = sortData(res.data, sortOrder);
        setBatchId(res.data);
        setTableData(sortedData);
      } else {
        const contracts = res.data.contracts;
        const batch_id = res.data.batch_id;
        setBatchId(batch_id);

        const sortedData = sortData(contracts, sortOrder);
        setTableData(sortedData);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (store.userDetails.username) fetchData();
  }, [store.userDetails.username]);

  useEffect(() => {
    if (!store.userDetails.username) {
      dispatch(setUserDetails({ username: sessionStorage.getItem("username") || "" }));
    }
  }, []);

  useEffect(() => {
    if (tableData !== undefined && batchId === 8) {
      // Specific logic for batchId 8, if needed
    }
  }, [tableData]);

  const filteredData = tableData ? filterData(tableData, filterText, viewMyCallsOnly) : [];

  // Fetch top chat queries when chatbot becomes visible
  const fetchChatQueries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/top_chat_queries`);
      if (response.data) {
        setChatQueries(response.data.data.map((query: any) => query.query)); // Extracting queries from response
        console.log("Chat Queries:", response.data.data);
      }
    } catch (error) {
      console.error('Error fetching chat queries:', error);
    }
  };

  useEffect(() => {
    if (store.selectedContract.id && store.userDetails.username) {
      fetchData();
      fetchChatQueries(); // Fetch the chat queries
    } else {
      setLoading(false);
      setError(true);
    }
  }, [store.selectedContract, store.userDetails]);

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      ) : error ? (
        <ErrorPage />
      ) : (
        <div>
          <div className="header-section">
            <Title level={3} className="gen-ai-header">GEN-AI SUPPORT AGENT</Title>
          </div>
          <div className="mycalls-section-container">
            <Title level={2} className="">My Calls</Title>

            <div className="seachbar-sort-filter-container">
              <div className="search-bar">
                <Input
                  placeholder="Search Calls"
                  suffix={<SearchOutlined />}
                  style={{ width: 300, marginRight: 10 }}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
                <Button type="default" className="button-container" onClick={toggleSortOrder}>
                  {sortOrder === "asc" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M19 3L23 8H20V20H18V8H15L19 3ZM14 18V20H3V18H14ZM14 11V13H3V11H14ZM12 4V6H3V4H12Z"></path>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M20 4V16H23L19 21L15 16H18V4H20ZM12 18V20H3V18H12ZM14 11V13H3V11H14ZM14 4V6H3V4H14Z"></path>
                    </svg>
                  )} Sort 
                </Button>
                {/* <Button type="default" className="button-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"></path></svg>
                  Filter
                </Button> */}
                <Checkbox
                  style={{ marginLeft: 10 }}
                  checked={viewMyCallsOnly}
                  onChange={(e) => setViewMyCallsOnly(e.target.checked)}
                >
                  View My Calls Only
                </Checkbox>
              </div>
              <Button type="primary" icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>} className="analyze-button" onClick={showModal}>
                Analyze New Call
              </Button>
            </div>
            <div className="card-container">
              {filteredData.map((record) => (
                <Card
                  key={record.id}
                  className="card"
                  onClick={() => reviewContract(record)}
                >
                  <div className="card-top-container">
                    <div className="card-title">
                      <div>
                        {record.contractName.split(".pdf")[0]}
                      </div>
                      <div className="status-container">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path></svg>                        <p>{record.status}</p>
                      </div>
                    </div>
                    <div>
                      <Paragraph className='summary-attributes-container'>
                        <Text className='summary-attributes'>Date of Recording: <p>{record.dateUploaded}</p></Text> | 
                        <Text className='summary-attributes'> Created By: <p>{record.reviewerName}</p></Text> | 
                        <Text className='summary-attributes'> Created On: <p>23 Jul 2024</p></Text>
                      </Paragraph>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="footer-left">
                      <div className="card-desc">{record.contractDesc}</div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        
        <AnalyzeNewCallModal visible={isModalVisible} onCancel={handleCancel} />
      </div>
      )}
       {!isChatBotVisible && (
            <div className="chatbot-toggle-1" onClick={() => setIsChatBotVisible(!isChatBotVisible)}>
              <span className="hover-text">Smart Bot</span>
              <div className="hexagon-container">
                <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9606 7.6155C19.7479 6.92473 18.2522 6.92473 17.0395 7.6155L8.23512 12.6303C7.0267 13.3186 6.28265 14.5883 6.28265 15.962V26.0378C6.28265 27.4115 7.0267 28.6812 8.23512 29.3695L17.0395 34.3843C18.2522 35.0751 19.7479 35.0751 20.9606 34.3843L29.765 29.3695C30.9734 28.6812 31.7174 27.4115 31.7174 26.0378V15.962C31.7174 14.5883 30.9734 13.3186 29.765 12.6303L20.9606 7.6155ZM37.587 12.6326C37.587 11.2589 36.8429 9.98924 35.6345 9.30095L20.9606 0.942884C19.7479 0.252113 18.2522 0.252111 17.0395 0.942882L2.36555 9.30094C1.15714 9.98924 0.413086 11.2589 0.413086 12.6326V29.3672C0.413086 30.7409 1.15714 32.0106 2.36555 32.6989L17.0395 41.0569C18.2522 41.7477 19.7479 41.7477 20.9606 41.057L35.6345 32.6989C36.8429 32.0106 37.587 30.7409 37.587 29.3672V12.6326Z" fill="white"/>
                  <path d="M17.0435 19.7605V4.63838L32.254 11.5664C33.703 12.2264 34.5991 13.6865 34.518 15.2554L33.674 31.5868L18.9726 23.0787C17.7774 22.3871 17.0435 21.1247 17.0435 19.7605Z" fill="white"/>
                </svg>
              </div>
              </div>
          )}
          {isChatBotVisible && (
            <div ref={chatBotRef} className="chatbot">
              <ChatBot isVisible={isChatBotVisible} onClose={() => setIsChatBotVisible(false)} questions={chatQueries} setInitialQuery=""/>
            </div>
          )}
    </>
  );
};

export default AudioCatalog;
