import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select, Spin, message } from "antd";
import { IActiveAgent, IFeatures } from "../../model/IActiveAgentsApiData";
import { IActiveAgentsApiData } from "../../model/IActiveAgentsApiData";
import axios from "axios";
import {
    selectStore,
    setSelectedActiveAgent,
    setSelectedFeature,
} from "../../state/slice/appSlice";
import ActiveAgentsCard from "../../components/activeAgentsCard";

const ActiveAgents = () => {
    const store = useSelector(selectStore);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fetchedData, setFetchedData] = useState<IActiveAgentsApiData>();
    const [filteredAgents, setFilteredAgents] = useState<IActiveAgent[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [batchDdn, setBatchDdn] = useState<any>({ options: [], defaultValue: undefined });
    const [selectedBatch, setSelectedBatch] = useState<string | undefined>(undefined);
    const [currentAgentId, setCurrentAgentId] = useState<number | null>(null);

    // Fetch all agents initially
    const fetchData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/agents/${store.userDetails.username}`)
            .then((res) => {
                if (res.data.agents && res.data.all_agents) {
                    const sortedData = res.data.all_agents.sort(
                        (a: any, b: any) => {
                            if (a.isActive && !b.isActive) {
                                return -1;
                            } else if (!a.isActive && b.isActive) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    );

                    setBatchDdn(res.data.batch_ddn_options);
                    setFetchedData({ agents: sortedData });
                    setFilteredAgents(sortedData);
                    setLoading(false);
                    dispatch(
                        setSelectedActiveAgent({
                            name: "",
                            id: -1,
                            icon: "",
                        })
                    );
                    dispatch(
                        setSelectedFeature({
                            name: "",
                            id: -1,
                            icon: "",
                        })
                    );
                } else {
                    setError(true);
                }
                setLoading(false);
            })
            .catch(() => setError(true));
    };

    // Handle navigation to agent detail
    const handleNavigationToAgent = (agent: IActiveAgent) => {
        dispatch(
            setSelectedActiveAgent({
                name: agent.name,
                id: Number(agent.id),
                icon: agent.iconName,
            })
        );
        sessionStorage.setItem("agentId", String(agent.id));
        sessionStorage.setItem("agentName", agent.name);

        const index =
            (store.sidebarOptions[0].children
                .find((el) => el.id === agent.id)
                ?.paths[0].route.lastIndexOf("/") ?? -1) + 1;

        const firstRoute = store.sidebarOptions[0].children
            .find((el) => el.id === agent.id)
            ?.paths[0].route.substring(index);
        navigate(agent.name.replace(/\W/g, "_") + "/" + firstRoute);
    };

    const fetchBatchesForAgent = async (agentId: string) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/agent_batches/${agentId}`
            );

            // Extract agent_id and batches from response
            const { agent_id, batches } = response.data;

            // Log fetched data
            console.log("Fetched agent ID:", agent_id);
            console.log("Fetched batches:", batches);

            // Update state
            setCurrentAgentId(Number(agent_id)); // Store the agent_id
            setBatchDdn({ options: batches });
            dispatch(
                setSelectedActiveAgent({
                    id: Number(agent_id),
                    name: "", // Provide default or empty value if needed
                    icon: ""  // Provide default or empty value if needed
                })
            );
        } catch (error) {
            console.error("Error fetching batches:", error);
        }
    };

    // Handle batch selection
    const handleBatchDdnChange = async (batchName: string) => {
        setSelectedBatch(batchName);
        setLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/save_batch_user_mapping/${store.userDetails.username}/${batchName}`
            );
            if (response.data.data) {
                const agentsResponse = await axios.get(
                    `${process.env.REACT_APP_API_URL}/agents/${store.userDetails.username}`
                );
                if (agentsResponse.data.agents) {
                    const sortedData = agentsResponse.data.agents.sort(
                        (a: any, b: any) => {
                            if (a.isActive && !b.isActive) {
                                return -1;
                            } else if (!a.isActive && b.isActive) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    );

                    setFilteredAgents(sortedData);
                } else {
                    setError(true);
                }
                setLoading(false);
                message.success("Updated successfully!");
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            console.error("Error updating batch:", error);
        }
    };

	const handleBatchClick = async (batchId: string, agentId: number) => {
		const username = store.userDetails.username;
		setSelectedBatch(batchId);  // Update selected batch
		setLoading(true);           // Set loading state
	
		try {
			// Step 1: Save user's batch selection
			const batchResponse = await axios.get(
				`${process.env.REACT_APP_API_URL}/save_batch_user_mappings/${username}/${batchId}`
			);
	
			if (batchResponse.data.data) {
				// Step 2: Fetch agents after saving batch selection
				const agentsResponse = await axios.get(
					`${process.env.REACT_APP_API_URL}/agents/${username}`
				);
	
				if (agentsResponse.data.agents) {
					// Step 3: Sort agents based on `isActive` status
					const sortedAgents = agentsResponse.data.agents.sort((a: any, b: any) => {
						if (a.isActive && !b.isActive) {
							return -1;
						} else if (!a.isActive && b.isActive) {
							return 1;
						} else {
							return 0;
						}
					});
					setFilteredAgents(sortedAgents);  // Update agent list with sorted data
				} else {
					setError(true);  // Set error if agents data is not available
				}
	
				// Step 4: Fetch contract data
				const contractResponse = await axios.get(
					`${process.env.REACT_APP_API_URL}/contracts/${username}/${agentId}/${batchId}`
				);
				console.log("Contract data:", contractResponse.data);
			} else {
				setError(true);  // Set error if batch saving fails
			}
	
			// message.success(`Agent ${store.selectedActiveAgent.name} opened successfully!`);
		} catch (error) {
			setError(true);  // Handle any error that occurs during batch or agent fetching
			console.error("Error updating batch or fetching contracts:", error);
		} finally {
			setLoading(false);  // Ensure loading state is reset
	
			// Step 5: Navigation to the agent detail page
			handleNavigationToAgent(filteredAgents.find(agent => agent.id === agentId)!);  // Ensure the agent is found before navigation
		}
	};
	
	

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Spin size="large" spinning={loading}>
            {store.isBatchDdn || sessionStorage.getItem("isBatchDdn") ? (
                <div className="pl-6 mt-8">
                    <h2
                        style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "31.92px",
                            letterSpacing: "-0.335999995470047px",
                            color: "rgba(69, 66, 80, 1)",
                            marginBottom: "10px"
                        }}
                    >
                        Gen AI Agents Gallery
                    </h2>
                    <div
                        style={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            marginRight: "20px",
                            justifyContent: "space-between"
                        }}
                    >
                        <p
                            style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "-0.2px",
                                color: "rgba(69, 66, 80, 1)",
                                width: "70%"
                            }}
                        >
                            Empower your business with AI-powered agents designed to streamline document management and data extraction. Transform raw data into actionable insights, driving faster, smarter decisions across all functions.
                        </p>
                        {/* <div>
                            <Select
                                options={batchDdn.options}
                                value={selectedBatch || undefined}
                                style={{ width: 280 }}
                                placeholder="Select Batch Name"
                                onChange={handleBatchDdnChange}
                            />
                        </div> */}
                    </div>
                </div>
            ) : null}
            <div className="p-6 text-text">
                <div>
                    {!loading &&
                        filteredAgents.map((agent) => (
                            <ActiveAgentsCard
								key={agent.id}
								agent={agent}
								handleNavigation={() => handleNavigationToAgent(agent)}
								fetchBatches={() => fetchBatchesForAgent(String(agent.id))}
								handleBatchClick={handleBatchClick}
								agentId={Number(agent.id)} // Pass the agentId as a prop
							/>
                        ))}
                </div>
            </div>
        </Spin>
    );
};

export default ActiveAgents;
