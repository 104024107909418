import React from "react";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import MyButton from "../buttons";
import { IDownloadExtractProps } from "../../model/IDownloadExtractProps";
const DownloadExtract = (props: IDownloadExtractProps) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToExcel = async () => {
		const wb: any = {
			Sheets: {},
			SheetNames: [],
		};

		// Add data for each sheet
		props.excelData.map((sheet: { sheetName: string; data: any }) => {
			const ws = XLSX.utils.json_to_sheet(sheet.data);
			wb.Sheets[
				sheet.sheetName.length > 30
					? sheet.sheetName.substring(0, 28) + "..."
					: sheet.sheetName
			] = ws;
			wb.SheetNames.push(
				sheet.sheetName.length > 30
					? sheet.sheetName.substring(0, 28) + "..."
					: sheet.sheetName
			);
		});

		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, props.fileName + fileExtension);
	};
	return <div onClick={(e) => exportToExcel()}>{props.children}</div>;
};

export default DownloadExtract;
