import { Input } from "antd";
import styles from "./style.module.css";
import { TextAreaProps } from "antd/es/input";

const MyTextarea = (props: TextAreaProps) => {
	const { ...rest } = props;
	const { TextArea } = Input;
	return (
		<TextArea
			{...rest}
			className={`${styles["textarea-container"]} font-normal text-base`}
		/>
	);
};

export default MyTextarea;
