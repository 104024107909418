const NotFound = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div style={{ textAlign: "center" }}>
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>Sorry, the page you are looking for could not be found.</p>
            </div>
        </div>
    );
};

export default NotFound;
