import React from 'react';
import { Drawer, Button } from 'antd';
import './index.css'

interface Ticket {
  [key: string]: string;
}

interface TicketSliderProps {
  ticket: Ticket;
  visible: boolean;
  onClose: () => void;
}

const TicketSlider: React.FC<TicketSliderProps> = ({ ticket, visible, onClose }) => {
  return (
    <Drawer
      title="Ticket Details"
      placement="right"
      onClose={onClose}
      open={visible}
      width={500}
      className="audio-transcript-drawer"
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button className='close-button' type="default" onClick={onClose}>
            Close
          </Button>
        </div>
      }
    >
      {Object.entries(ticket).map(([key, value]) => (
        <div>
            <p className='key-name' key={key}>{key}:</p>
            <p>{value}</p>
            <br />
        </div>
      ))}
    </Drawer>
  );
};

export default TicketSlider;
