import { IRouteConfig } from "../model/IRouteConfig";
import Pages from "../pages";
import HealthCheck from "../routes/HealthCheck";
import NotFound from "../routes/NotFound";

const routesConfig: IRouteConfig[] = [
	{
		path: "/",
		element: <Pages.Homepage />,
		children: [],
	},
	{
		path: "active-agents",
		element: <Pages.ActiveAgents />,
		children: [],
	},
	{
		path: "manage-users",
		element: <Pages.AccessManagement />,
		children: [],
	},
	{
		path: "active-agents/contracts_ai/contract-review",
		element: <Pages.ContractReview />,
		children: [],
	},
	{
		path: "active-agents/contracts_ai/contract-review/:id",
		element: <Pages.ContractReviewDetails />,
		children: [
			{
				path: "",
				element: <Pages.ContractReviewDetailsExtract />,
				children: [],
			},
			{
				path: "chat",
				element: <Pages.ContractReviewDetailsChat />,
				children: [],
			},
		],
	},
	{
		path: "active-agents/contracts_ai/ask-contracts",
		element: <Pages.AskContracts />,
		children: [],
	},
	{
		path: "active-agents/contracts_ai/configuration",
		element: <Pages.Configuration />,
		children: [],
	},
	{
		path: "active-agents/chat_ai/chat",
		element: <Pages.AskContracts chatOnly />,
		children: [],
	},
	{
		path: "active-agents/assistants_ai/ask-contracts",
		element: <Pages.AskContractsExtractAI />,
		children: [],
	},
	{
		path: "active-agents/formextractor_ai/contract-review",
		element: <Pages.FormExtractorContractReview />,
		children: [],
	},
	{
		path: "active-agents/formextractor_ai/contract-review/:id",
		element: <Pages.ContractReviewDetails />,
		children: [
			{
				path: "",
				element: <Pages.FormExtractContractReviewDetailsExtract />,
				children: [],
			},
			{
				path: "chat",
				element: <Pages.ContractReviewDetailsChat />,
				children: [],
			},
		],
	},
	{
		path: "active-agents/SupportAssist_ai/audio-catalog",
		element: <Pages.AudioCatalog />,
		children: [],
	},
	{
		path: "active-agents/SupportAssist_ai/audio-catalog/:id",
		element: <Pages.MyCalls />,
		children: [],
	},
	{
		path: "active-agents/SupportAssist_ai/document-repository",
		element: <Pages.DocumentRepository />,
		children: [],
	},
	{
		path: "active-agents/SupportAssist_ai/document-repository/:id",
		element: <Pages.PDFOnly />,
		children: [],
	},
	// {
	// 	path: "active-agents/SupportAgent_ai/document-repository/:id",
	// 	element: <Pages.ContractReviewDetails />,
	// 	children: [
	// 		{
	// 			path: "",
	// 			element: <Pages.FormExtractContractReviewDetailsExtract />,
	// 			children: [],
	// 		},
	// 		{
	// 			path: "chat",
	// 			element: <Pages.ContractReviewDetailsChat />,
	// 			children: [],
	// 		},
	// 	],
	// },
	{
		path: "active-agents/TrainAssist_ai/assistant",
		element: <Pages.TrainAssistsAI />,
		children: [],
	},
	{
		path: "active-agents/Invoice_ai/invoice-review",
		element: <Pages.ContractReview />,
		children: [],
	},
	{
		path: "active-agents/Invoice_ai/invoice-review/:id",
		element: <Pages.ContractReviewDetails />,
		children: [
			{
				path: "",
				element: <Pages.ContractReviewDetailsExtract />,
				children: [],
			},
			{
				path: "chat",
				element: <Pages.ContractReviewDetailsChat />,
				children: [],
			},
		],
	},
	{
		path: "active-agents/Invoice_ai/ask-invoices",
		element: <Pages.AskContracts />,
		children: [],
	},
	{
		path: "active-agents/Invoice_ai/configuration",
		element: <Pages.Configuration />,
		children: [],
	},
	// {
	// 	path: "parent",
	// 	element: <Pages.ParentPage />,
	// 	children: [
	// 		{
	// 			path: "child1",
	// 			element: <Pages.ChildPage1 />,
	// 			children: [],
	// 		},
	// 		{
	// 			path: "child2",
	// 			element: <Pages.ChildPage2 />,
	// 			children: [
	// 				{
	// 					path: "grand1",
	// 					element: <Pages.GrandChildPage1 />,
	// 					children: [],
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	path: "healthcheck",
	// 	element: <HealthCheck />,
	// 	children: [],
	// },
	{
		path: "*",
		element: <NotFound />,
		children: [],
	},
];

export { routesConfig };
