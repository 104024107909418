import Homepage from "./homePage";
import ActiveAgents from "./activeAgents";
import ContractReview from "./contractReview";
import ContractReviewDetails from "./contractReviewDetails";
import ContractReviewDetailsExtract from "./contractReviewDetailsExtract";
import ContractReviewDetailsChat from "./contractReviewDetailsChat";
import AskContracts from "./askContracts";
import Configuration from "./configuration";
import AskContractsExtractAI from "./askContractsExtractAI";
import FormExtractorContractReview from "./formExtractorContractReview";
import FormExtractContractReviewDetailsExtract from "./formExtractorContractExtract";
import FormExtractorContractReviewDetailsChat from "./formExtractorContractorChat";
import AudioTranscription from "./audioTranscription";
import TrainAssistsAI from "./trainAssistAI";
import AudioCatalog from "./audioCatalog";
import DocumentRepository from "./documentRepository";
import MyCalls from "./myCalls";
import PDFOnly from "./pdfOnly";
import AccessManagement from "./accessManagement";

const Pages = {
	Homepage,
	ActiveAgents,
	ContractReview,
	ContractReviewDetails,
	ContractReviewDetailsExtract,
	ContractReviewDetailsChat,
	AskContracts,
	AudioTranscription,
	Configuration,
	AskContractsExtractAI,
	FormExtractorContractReview,
	FormExtractContractReviewDetailsExtract,
	FormExtractorContractReviewDetailsChat,
	TrainAssistsAI,
	AudioCatalog,
	DocumentRepository,
	MyCalls,
	PDFOnly,
	AccessManagement
};
export default Pages;
