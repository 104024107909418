import {
	Card,
	Form,
	Modal,
	Spin,
	Switch,
	Table,
	Tooltip,
	Typography,
	message,
} from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { Edit } from "@styled-icons/boxicons-solid/Edit";
import { EyeOutline } from "@styled-icons/evaicons-outline/EyeOutline";
import { Undo } from "@styled-icons/material-rounded/Undo";
import { Save } from "@styled-icons/bootstrap/Save";
import MyButton from "../../components/buttons";
import MyModal from "../../components/modal";
import MyInput from "../../components/input";
import MyTextarea from "../../components/textarea";
import HorizonalScrollCards from "../../components/horizontalScrollCards";
import { SaveMsgData } from "../../model/ISaveMessages";
import { IConfigApiDataInteface } from "../../model/IConfigurationApiData";
import { configDummyData } from "../../dummyData/config";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import MyUpload from "../../components/upload";
import { Download } from "@styled-icons/boxicons-regular/Download";
import myMessages from "../../components/messages";
const { Paragraph, Text } = Typography;

const Configuration = () => {
	const state = useSelector(selectStore);
	const [dataSource, setDataSource] = useState<IConfigApiDataInteface>();
	// 1: New Variable, 2: New Table, 3: Show Data, 4: Delete Variable Confirmation, 5: Delete Table Confirmation, 6: Edit Variable, 7: Edit Table
	const [isModalOpen, setIsModalOpen] = useState<number>(0);
	const [tableData, setTableData] = useState<any>([]);
	const [dataSourceCopy, setDataSourceCopy] =
		useState<IConfigApiDataInteface>();
	const [tableLoading, setTableLoading] = useState(true);
	const [columns, setColumns] = useState<any>([]);
	const [form] = Form.useForm();
	const [corpusMessageChanged, setCorpusMessageChanged] = useState(false);
	const [documentMessageChanged, setDocumentMessageChanged] = useState(false);
	const [deletedId, setDeletedId] = useState("");
	const [editId, setEditId] = useState("");
	const [loading, setLoading] = useState(true);
	const [preSignedUrl, setPresignedUrl] = useState("-");
	const [batchId, setBatchId] = useState(-1);
	const [uploadUuid, setUploadUuid] = useState<string | number>(-1);
	const showModal = (modalNumber: number) => {
		if (modalNumber === 3) {
			const tableDatafromAPI = [
				{
					id: "1",
					contractName: "Contract 1",
					contractOverview: "abcd",
					rebateAmount: "5%",
					privacyClause: "Yes",
					variable1: "def",
					variable2: "xyz",
					variable3: "xyz",
					variable4: "xyz",
					variable5: "xyz",
					variable6: "xyz",
					variable7: "xyz",
					variable8: "xyz",
					variable9: "xyz",
					variable10: "xyz",
					variable11: "xyz",
				},
				{
					id: "1",
					contractName: "Contract 1",
					contractOverview: "abcd",
					rebateAmount: "5%",
					privacyClause: "Yes",
					variable1: "def",
					variable2: "xyz",
					variable3: "xyz",
					variable4: "xyz",
					variable5: "xyz",
					variable6: "xyz",
					variable7: "xyz",
					variable8: "xyz",
					variable9: "xyz",
					variable10: "xyz",
					variable11: "xyz",
				},
			];
		}
		setIsModalOpen(modalNumber);
	};
	const handleOk = () => {
		setIsModalOpen(0);
	};
	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(0);
	};
	const onSubmit = (e: any, isEdit: boolean, type: number) => {
		if (type === 1) {
			const payload = {
				id: isEdit ? editId : "-1",
				name: e.name,
				prompt: e.prompt,
				user_name: state.userDetails.username,
				batch_id: batchId,
			};

			const key = "updatable";
			message.loading({ content: "Saving...", key: key });
			axios
				.post(
					process.env.REACT_APP_API_URL + "/Edit/add_variables",
					payload
				)
				.then((res) => {
					if (res.data.data) {
						fetchData();
						message.success({
							content: "Saved successfully...",
							key: key,
						});
					} else message.error({ content: "Error!", key: key });
				})
				.catch((e) => message.error({ content: "Error!", key: key }));
			// if (isEdit) {
			// 	if (type === 2) console.log("Variable Edited Id", editId, e);
			// 	else console.log("Table Edit Id", editId, e);
			// } else {
			// 	if (type === 1) console.log("Create Variable", e);
			// 	else console.log("Create Table", e);
			// }
			form.resetFields();
			setIsModalOpen(0);
		} else {
			const payload = {
				tableId: isEdit ? editId : "-1",
				tableType: e.name,
				columnNames: e.columnHeaders,
				rowLabels: e.rowLabels || null,
				batch_id: batchId,
				uuid: uploadUuid,
			};

			myMessages({
				key: "Configuration",
				text: "Saving...",
				type: "loading",
				duration: 0,
			});
			axios
				.post(
					process.env.REACT_APP_API_URL + "/Edit/add_tables",
					payload
				)
				.then((res) => {
					if (res.data.data) {
						fetchData();
						myMessages({
							key: "Configuration",
							text: "Saved successfully...",
							type: "success",
							duration: 2,
						});
					} else
						myMessages({
							key: "Configuration",
							text: "Error!",
							type: "error",
							duration: 2,
						});
					setUploadUuid(-1);
				})
				.catch((e) => {
					myMessages({
						key: "Configuration",
						text: "Error!",
						type: "error",
						duration: 2,
					});
				});
			// if (isEdit) {
			// 	if (type === 2) console.log("Variable Edited Id", editId, e);
			// 	else console.log("Table Edit Id", editId, e);
			// } else {
			// 	if (type === 1) console.log("Create Variable", e);
			// 	else console.log("Create Table", e);
			// }
			form.resetFields();
			setIsModalOpen(0);
		}
	};

	const handleMessageChange = (msgType: number, message: string) => {
		const tempData: IConfigApiDataInteface = JSON.parse(
			JSON.stringify(dataSource)
		);
		if (msgType === 1) {
			tempData.corpusMessage = message;
			setCorpusMessageChanged(true);
		} else if (msgType === 2) {
			tempData.documentMessage = message;
			setDocumentMessageChanged(true);
		}
		setDataSource(tempData);
	};

	const undoMessageChange = (msgType: number) => {
		const tempData: IConfigApiDataInteface = JSON.parse(
			JSON.stringify(dataSource)
		);
		if (msgType === 1) {
			tempData.corpusMessage = dataSourceCopy?.corpusMessage ?? "";
			setCorpusMessageChanged(false);
		} else if (msgType === 2) {
			tempData.documentMessage = dataSourceCopy?.documentMessage ?? "";
			setDocumentMessageChanged(false);
		}
		setDataSource(tempData);
	};

	const saveMessage = (msgType: number) => {
		const saveMsgData: SaveMsgData = {};
		const currData: IConfigApiDataInteface = JSON.parse(
			JSON.stringify(dataSource)
		);
		const originalData: IConfigApiDataInteface = JSON.parse(
			JSON.stringify(dataSourceCopy)
		);
		if (msgType === 1) {
			originalData.corpusMessage = currData.corpusMessage;
			saveMsgData.corpusMessage = currData.corpusMessage;
			setCorpusMessageChanged(false);
		} else if (msgType === 2) {
			originalData.documentMessage = currData.documentMessage;
			saveMsgData.documentMessage = currData.documentMessage;
			setDocumentMessageChanged(false);
		}
		setDataSourceCopy(originalData);

		//POST API CALL WITH saveMsgData
		console.log("Save Data API call", saveMsgData);
		const key = "updatable";
		message.loading({ content: "Saving...", key: key });

		axios
			.post(
				"https://intelligent-automation.api.demo.zsservices.com/configuration/save_messages",
				saveMsgData
			)
			.then((res) => {
				if (res.data)
					message.success({
						content: "Message saved sccessfully",
						key: key,
					});
				else message.error({ content: "Error", key: key });
			})
			.catch((e) => message.error({ content: "Error", key: key }));
	};

	const handleDeletion = (type: number) => {
		const key = "updatable";
		message.loading({ content: "Saving...", key: key });
		if (type === 1) {
			axios
				.delete(
					process.env.REACT_APP_API_URL +
						"/configuration/delete_data/" +
						deletedId
				)
				.then((res) => {
					if (res.data.data) {
						message.success({
							content: "Deleted Successfully!",
							key: key,
						});

						setDeletedId("");

						fetchData();
					} else message.error({ content: "Error!", key: key });
				})
				.catch((e) =>
					message.error({
						content: "Error while deleting!",
						key: key,
					})
				);
		} else {
			axios
				.delete(
					process.env.REACT_APP_API_URL +
						"/configuration/delete_table_type/" +
						deletedId
				)
				.then((res) => {
					if (res.data.data) {
						message.success({
							content: "Deleted Successfully!",
							key: key,
						});

						setDeletedId("");

						fetchData();
					} else message.error({ content: "Error!", key: key });
				})
				.catch((e) =>
					message.error({
						content: "Error while deleting!",
						key: key,
					})
				);
		}
	};
	const handleToggle = (type: number, id: string, value: boolean) => {
		const temp: IConfigApiDataInteface = JSON.parse(
			JSON.stringify(dataSource)
		);

		const payload = {
			id: id,
			value: value,
		};
		const key = "updatable";
		message.loading({ content: "Saving...", key: key });
		axios
			.post(
				process.env.REACT_APP_API_URL + "/configuration/toggle_data",
				payload
			)
			.then((res) => {
				if (res.data.data) {
					if (type === 1) {
						console.log("Variable ID", id, value);
						//******************************************************** */
						//Toggle variable API call
						//******************************************************** */

						const idx =
							temp.metaDataConfig.newMetaDataVariable.findIndex(
								(el) => el.id === id
							);
						if (idx != -1)
							temp.metaDataConfig.newMetaDataVariable[
								idx
							].isSelected = value;
						setDataSource(temp);
					} else if (type === 2) {
						console.log("Deleted Table ID", id, value);
						//********************************************************** */
						//Toggle table API call
						//********************************************************** */

						const idx =
							temp.metaDataConfig.newMetaDataTable.findIndex(
								(el) => el.id === id
							);
						if (idx != -1)
							temp.metaDataConfig.newMetaDataTable[
								idx
							].isSelected = value;
						setDataSource(temp);
					}
					const tempCopy: IConfigApiDataInteface = JSON.parse(
						JSON.stringify(dataSourceCopy)
					);
					tempCopy.metaDataConfig = temp.metaDataConfig;
					setDataSourceCopy(tempCopy);
					message.success({
						content: "Saved successfully!",
						key: key,
					});
				} else {
					message.error({ content: "Error!", key: key });
				}
			})
			.catch((e) => message.error({ content: "Error!", key: key }));
	};

	const fetchData = () => {
		setLoading(true);
		axios
			.get(
				"https://intelligent-automation.api.demo.zsservices.com/configuration/" +
					state.userDetails.username
			)
			.then((res) => {
				const tempAllVariables: string[] = [];
				res.data.metaDataConfig.allVariables.map((el: string) =>
					tempAllVariables.push(el.toLowerCase())
				);
				res.data.metaDataConfig.allVariables = JSON.parse(
					JSON.stringify(tempAllVariables)
				);
				setBatchId(res.data.metaDataConfig.batchID);
				setDataSource(res.data);
				setDataSourceCopy(res.data);
				setLoading(false);
				fetchTableData(res.data.metaDataConfig.batchID);
			});
	};

	const fetchTableData = (batchIdProp: number) => {
		setTableLoading(true);
		axios
			.get(
				"https://intelligent-automation.api.demo.zsservices.com/configuration/view_table/" +
					batchIdProp
			)
			.then((res) => {
				if (res.data.length > 0) {
					const fixedKeys = Object.keys(res.data[0]).slice(0, 3);

					// Get the keys for the dynamic columns
					const dynamicKeys = Object.keys(res.data[0]).slice(3);

					// Create the columns array
					const columnsTbl = fixedKeys.map((key, idx) => ({
						title:
							key === "id"
								? "ID"
								: key === "contractName"
								? "Contract Name"
								: key === "contractOverview"
								? "Contract Description"
								: key,
						dataIndex: key,
						width: 150,
						fixed: key === fixedKeys[idx] ? "left" : false,
						align:
							key === "id"
								? "center"
								: key === "contractName"
								? "left"
								: key === "contractOverview"
								? "left"
								: "center",
					}));

					dynamicKeys.forEach((key) => {
						columnsTbl.push({
							title: key,
							dataIndex: key,
							width: 150,
							fixed: false,
							align: "center",
						});
					});
					setTableData(res.data);
					setColumns(columnsTbl);
				} else {
					setTableData(res.data);
					setColumns([]);
				}
				setTableLoading(false);
			});
	};

	const handleDownloadExcel = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "/template_pre_signed_url")
			.then((res) => {
				if (res.data) {
					const a = document.createElement("a");
					a.href = res.data;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				} else {
					message.error("Error downloading template");
				}
			})
			.catch((e) => message.error("Error downloading template"));
	};

	const handleGeneratePresignedUrl = async (tablTypeName: string) => {
		// return true;
		return await axios
			.put(
				process.env.REACT_APP_API_URL +
					"/table_type_pre_signed_url/" +
					batchId +
					"/" +
					"a"
			)
			.then((res: any) => {
				console.log(res.data["upload_url"]);
				setPresignedUrl(res.data["upload_url"]);
				setUploadUuid(res.data["uuid"]);
				return true;
			})
			.catch((e) => false);
	};

	//@ts-ignore
	const customRequest = async ({ file, onSuccess, onError }) => {
		try {
			await axios
				.put(
					process.env.REACT_APP_API_URL +
						"/table_type_pre_signed_url/" +
						batchId +
						"/" +
						"a"
				)
				.then((res: any) => {
					console.log(res.data["upload_url"]);
					setPresignedUrl(res.data["upload_url"]);
					setUploadUuid(res.data["uuid"]);
					axios
						.put(res.data["upload_url"], file, {
							headers: {
								"Content-Type": file.type,
							},
						})
						.then((res) => {
							onSuccess();
						})
						.catch((e) => onError(e));
					// Notify AntD that upload was successful
				})
				.catch((e) => onError(e));

			// Perform the upload using the presigned URL
		} catch (error) {
			console.error("Error uploading file:", error);
			onError(error); // Notify AntD that upload failed
		}
	};
	useEffect(() => {
		if (state.userDetails.username) fetchData();
	}, [state.userDetails.username]);

	useEffect(() => {
		return () => {
			myMessages({
				key: "Configuration",
				destroy: true,
			});
		};
	}, []);
	return (
		<>
			{!loading ? (
				<div className="ml-8 mr-8 mt-8 mb-8 text-text">
					<div>
						<div>
							<p
								className="mb-2"
								style={{ fontSize: "14px", fontWeight: 700 }}
							>
								Agent System Message - Corpus
								{corpusMessageChanged && (
									<>
										<Undo
											className="mr-1 ml-1 text-text-zs-orange"
											size={16}
											style={{ cursor: "pointer" }}
											title="Undo Changes"
											onClick={() => undoMessageChange(1)}
										/>
										<Save
											className="mr-1 ml-1 text-text-zs-orange"
											size={16}
											style={{ cursor: "pointer" }}
											title="Save Changes"
											onClick={() => {
												saveMessage(1);
											}}
										/>
									</>
								)}
							</p>
							<MyTextarea
								placeholder="Enter item description"
								autoSize={{
									minRows: 2,
									maxRows: 3,
								}}
								style={{ background: "#F3F4F6FF" }}
								value={dataSource?.corpusMessage}
								onChange={(e) =>
									handleMessageChange(1, e.target.value)
								}
							/>
						</div>
						<br />
						<div>
							<div
								className="mb-2"
								// w-full flex items-center justify-between"
								style={{ fontSize: "14px", fontWeight: 700 }}
							>
								Agent System Message - Document
								{documentMessageChanged && (
									<span>
										<Undo
											className=" mr-1 ml-1 text-text-zs-orange"
											size={16}
											style={{ cursor: "pointer" }}
											title="Undo Changes"
											onClick={() => undoMessageChange(2)}
										/>
										<Save
											className=" mr-1 ml-1 text-text-zs-orange"
											size={16}
											style={{ cursor: "pointer" }}
											title="Save Changes"
											onClick={() => saveMessage(2)}
										/>
									</span>
								)}
							</div>
							<MyTextarea
								placeholder="Enter item description"
								autoSize={{
									minRows: 2,
									maxRows: 3,
								}}
								style={{ background: "#F3F4F6FF" }}
								value={dataSource?.documentMessage}
								onChange={(e) =>
									handleMessageChange(2, e.target.value)
								}
							/>
						</div>
					</div>
					<br />
					<div style={{ background: "#F8F9FAFF" }} className="p-4">
						<p
							className=""
							style={{ fontSize: "14px", fontWeight: 700 }}
						>
							Meta Data Configuration
						</p>
						<div className=" mt-2 mb-4">
							<p
								className="mb-2"
								style={{ fontSize: "14px", fontWeight: 600 }}
							>
								Integrated Data From Source
							</p>
							<div>
								<HorizonalScrollCards id="scroll-hor-1">
									{dataSource?.metaDataConfig?.existingMetaData.map(
										(el: any) => (
											<Card
												className="configCard mr-2 mb-2"
												size="small"
												style={{
													width: 200,
													minWidth: 200,
													display: "flex",
													// justifyContent: "center",
													alignItems: "center",
													// overflowX: "auto",
												}}
											>
												<div
												// style={{
												// 	overflowY: "auto",
												// 	maxHeight: "3rem",
												// }}
												>
													<Paragraph
														ellipsis={{
															rows: 2,
															expandable: false,
															symbol: "more",
															tooltip: el.name,
														}}
														style={{
															fontSize: "14px",
															fontWeight: 400,
															textAlign: "left",
														}}
													>
														{el.name}
													</Paragraph>
												</div>
											</Card>
										)
									)}
								</HorizonalScrollCards>
							</div>
						</div>
						<div className="mb-4">
							<p
								className="mb-2"
								style={{ fontSize: "14px", fontWeight: 600 }}
							>
								Additional Key Value Meta Data
							</p>
							<div
							// style={{ display: "flex", overflowX: "auto" }}
							>
								<HorizonalScrollCards id="scroll-hor-2">
									{dataSource?.metaDataConfig
										?.newMetaDataVariable &&
										dataSource?.metaDataConfig
											?.newMetaDataVariable.length > 0 &&
										dataSource?.metaDataConfig?.newMetaDataVariable.map(
											(el) => (
												<div>
													<Card
														className="configCard mr-2 mb-2"
														size="small"
														style={{
															width: 350,
															minWidth: 350,
															// display: "flex",
															// justifyContent: "left",
															// alignItems: "center",
															// overflowX: "auto",
														}}
													>
														<div
															style={{
																// overflowY: "auto",
																// maxHeight: "8rem",
																height: "9rem",
																display: "flex",
																flexDirection:
																	"column",
																justifyContent:
																	"space-between",
															}}
														>
															<div className="flex items-center justify-between">
																<Paragraph
																	ellipsis={{
																		rows: 1,
																		expandable:
																			false,
																		symbol: "more",
																		tooltip:
																			el.name,
																	}}
																	style={{
																		fontSize:
																			"20px",
																		fontWeight: 700,
																		margin: 0,
																	}}
																>
																	{el.name}
																</Paragraph>
																<Tooltip
																	title={
																		el.isSelected
																			? "Disable Meta Data"
																			: "Enable Meta Data"
																	}
																>
																	<Switch
																		size="small"
																		checked={
																			el.isSelected
																		}
																		onChange={(
																			value
																		) =>
																			handleToggle(
																				1,
																				el.id,
																				value
																			)
																		}
																	/>
																</Tooltip>
															</div>

															<Paragraph
																ellipsis={{
																	rows: 3,
																	expandable:
																		false,
																	symbol: "more",
																	tooltip:
																		el.desc,
																}}
																style={{
																	fontSize:
																		"14px",
																	fontWeight: 400,
																}}
															>
																{el.desc}
															</Paragraph>
															{/* <p
										style={{
											fontSize: "14px",
											fontWeight: 400,
										}}
									>
										{el.desc}
									</p> */}
															<div
																style={{
																	display:
																		"flex",
																	justifyContent:
																		"end",
																}}
																className="text-text-zs-light-gray"
															>
																<Delete
																	className="hover:text-text-zs-orange cursor-pointer"
																	size={24}
																	onClick={() => {
																		setDeletedId(
																			el.id
																		);
																		setIsModalOpen(
																			4
																		);
																	}}
																/>
																<Edit
																	className="hover:text-text-zs-orange cursor-pointer"
																	size={24}
																	onClick={() => {
																		form.resetFields();
																		setIsModalOpen(
																			6
																		);
																		setEditId(
																			el.id
																		);
																	}}
																/>
															</div>
														</div>
													</Card>
												</div>
											)
										)}
									{dataSource?.metaDataConfig
										?.newMetaDataVariable &&
										dataSource?.metaDataConfig
											?.newMetaDataVariable.length ===
											0 && (
											<div className="text-xs italic">
												No additional variables
											</div>
										)}
								</HorizonalScrollCards>
							</div>
						</div>
						<div className="mb-4">
							<p
								className="mb-2"
								style={{ fontSize: "14px", fontWeight: 600 }}
							>
								Table Parse Types
							</p>
							<div>
								<HorizonalScrollCards id="scroll-hor-3">
									{dataSource?.metaDataConfig
										?.newMetaDataTable &&
										dataSource?.metaDataConfig
											?.newMetaDataTable.length > 0 &&
										dataSource?.metaDataConfig?.newMetaDataTable.map(
											(el) => (
												<Card
													className="configCard mr-2 mb-2"
													size="small"
													style={{
														width: 350,
														minWidth: 350,
														// display: "flex",
														// justifyContent: "left",
														// alignItems: "center",
														// overflowX: "auto",
													}}
												>
													<div
														style={{
															// overflowY: "auto",
															// maxHeight: "8rem",
															height: "9rem",
															display: "flex",
															flexDirection:
																"column",
															justifyContent:
																"space-between",
														}}
													>
														<div className="flex items-center justify-between">
															<Paragraph
																ellipsis={{
																	rows: 1,
																	expandable:
																		false,
																	symbol: "more",
																	tooltip:
																		el.name,
																}}
																style={{
																	fontSize:
																		"20px",
																	fontWeight: 700,
																	margin: 0,
																}}
															>
																{el.name}
															</Paragraph>
															{/* <Tooltip
																title={
																	el.isSelected
																		? "Disable Meta Data"
																		: "Enable Meta Data"
																}
															>
																<Switch
																	size="small"
																	checked={
																		el.isSelected
																	}
																	onChange={(
																		value
																	) =>
																		handleToggle(
																			2,
																			el.id,
																			value
																		)
																	}
																/>
															</Tooltip> */}
														</div>
														<div>
															{el.columnHeaders && (
																<div className="font-semibold">
																	Column Names
																</div>
															)}
															<Paragraph
																ellipsis={{
																	rows: 1,
																	expandable:
																		false,
																	symbol: "more",
																	tooltip:
																		el.columnHeaders,
																}}
																style={{
																	fontSize:
																		"14px",
																	fontWeight: 400,
																	marginBottom: 0,
																}}
															>
																{
																	el.columnHeaders
																}
															</Paragraph>
														</div>
														<div>
															{el.rowLabels && (
																<div className="font-semibold">
																	Row Labels
																</div>
															)}
															<Paragraph
																ellipsis={{
																	rows: 1,
																	expandable:
																		false,
																	symbol: "more",
																	tooltip:
																		el.rowLabels,
																}}
																style={{
																	fontSize:
																		"14px",
																	fontWeight: 400,
																	marginBottom: 0,
																}}
															>
																{el.rowLabels}
															</Paragraph>
														</div>
														<div
															style={{
																display: "flex",
																justifyContent:
																	"end",
															}}
															className="text-text-zs-light-gray"
														>
															<Delete
																className="hover:text-text-zs-orange cursor-pointer"
																size={24}
																onClick={() => {
																	setDeletedId(
																		el.id
																	);
																	setIsModalOpen(
																		5
																	);
																}}
															/>
															<Edit
																className="hover:text-text-zs-orange cursor-pointer"
																size={24}
																onClick={() => {
																	form.resetFields();
																	setIsModalOpen(
																		7
																	);
																	setEditId(
																		el.id
																	);
																}}
															/>
														</div>
													</div>
												</Card>
											)
										)}
									{dataSource?.metaDataConfig
										?.newMetaDataTable &&
										dataSource?.metaDataConfig
											?.newMetaDataTable.length === 0 && (
											<div className="text-xs italic">
												No additional tables
											</div>
										)}
								</HorizonalScrollCards>
							</div>
						</div>

						<div className="">
							<MyButton
								type="primary"
								style={{ width: "20%", marginRight: "1rem" }}
								onClick={() => {
									form.resetFields();
									showModal(1);
								}}
							>
								+ Add Key Value
							</MyButton>
							<MyButton
								type="primary"
								style={{ width: "20%" }}
								onClick={() => {
									form.resetFields();
									showModal(2);
								}}
							>
								+ Add New Table Parse Type
							</MyButton>
						</div>
					</div>

					<div className="mt-4">
						<MyButton
							type="primary"
							style={{ width: "20%" }}
							onClick={() => showModal(3)}
						>
							<div className="flex items-center w-full justify-center">
								<EyeOutline
									size={24}
									style={{
										cursor: "pointer",
										marginRight: "0.2rem",
									}}
								/>
								View Data
							</div>
						</MyButton>
					</div>
				</div>
			) : (
				<div className="h-full flex items-center justify-center">
					<Spin size="large" />
				</div>
			)}
			<MyModal
				title={
					isModalOpen === 1
						? "Create New Key Value"
						: isModalOpen === 2
						? "Create New Table Parse Type"
						: isModalOpen === 3
						? "Data"
						: isModalOpen === 4
						? "Variable Deletion"
						: isModalOpen === 5
						? "Table Deletion"
						: isModalOpen === 6
						? "Edit Key Value"
						: isModalOpen === 7
						? "Edit Table Parse Type"
						: ""
				}
				centered
				footer={null}
				open={isModalOpen !== 0}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose
				width={isModalOpen === 3 ? "80%" : undefined}
			>
				{(isModalOpen === 1 || isModalOpen === 6) && (
					<Form
						layout={"vertical"}
						form={form}
						onFinish={(e) => onSubmit(e, isModalOpen === 6, 1)}
					>
						<Form.Item
							className="my-8"
							label="Variable Name"
							name="name"
							rules={[
								{
									required: true,
									message: "Please enter variable name!",
								},
								{
									validator: (_, value) => {
										if (
											dataSourceCopy?.metaDataConfig.newMetaDataVariable.find(
												(el) => el.id === editId
											)?.name !== value &&
											dataSource?.metaDataConfig.allVariables.includes(
												value.toLowerCase()
											)
										) {
											return Promise.reject(
												"Meta data name already exists"
											);
										}
										return Promise.resolve();
									},
									message: "Meta data name already exists.",
								},
							]}
							initialValue={
								isModalOpen === 6
									? dataSource?.metaDataConfig.newMetaDataVariable.find(
											(el) => el.id === editId
									  )?.name
									: undefined
							}
						>
							<MyInput
								placeholder="Enter name"
								className="font-normal text-base"
							/>
						</Form.Item>

						<Form.Item
							className="my-8"
							label="Variable Prompt"
							name="prompt"
							initialValue={
								isModalOpen === 6
									? dataSource?.metaDataConfig.newMetaDataVariable.find(
											(el) => el.id === editId
									  )?.desc
									: undefined
							}
						>
							<MyTextarea
								placeholder="Enter prompt"
								className="font-normal text-base"
								rows={4}
							/>
						</Form.Item>
						<div className="w-full flex justify-end">
							<MyButton
								style={{ marginRight: "0.5rem" }}
								type="default"
								size="large"
								onClick={(e) => {
									e.preventDefault();
									handleCancel();
									setEditId("");
								}}
							>
								Cancel
							</MyButton>
							<MyButton
								type="primary"
								size="large"
								htmlType="submit"
							>
								{isModalOpen === 1 ? "Add" : "Edit"}
							</MyButton>
						</div>
					</Form>
				)}
				{(isModalOpen === 2 || isModalOpen === 7) && (
					<Form
						layout={"vertical"}
						form={form}
						onFinish={(e) => onSubmit(e, isModalOpen === 7, 2)}
					>
						<Form.Item
							className="my-8"
							label="Table Type"
							name="name"
							rules={[
								{
									required: true,
									message: "Please enter table type",
								},
								// {
								// 	validator: (_, value) => {
								// 		if (
								// 			dataSource?.metaDataConfig.allVariables.includes(
								// 				value.toLowerCase()
								// 			)
								// 		) {
								// 			return Promise.reject(
								// 				"Meta data name already exists"
								// 			);
								// 		}
								// 		return Promise.resolve();
								// 	},
								// 	message: "Meta data name already exists.",
								// },
							]}
							initialValue={
								isModalOpen === 7
									? dataSource?.metaDataConfig.newMetaDataTable.find(
											(el) => el.id === editId
									  )?.name
									: undefined
							}
						>
							<MyInput
								placeholder="Please enter table type"
								className="font-normal text-base"
							/>
						</Form.Item>

						<Form.Item
							className="my-8"
							label="Column Names"
							name="columnHeaders"
							rules={[
								{
									required: true,
									message: "Please enter column names",
								},
								// {
								// 	validator: (_, value) => {
								// 		if (
								// 			dataSource?.metaDataConfig.allVariables.includes(
								// 				value.toLowerCase()
								// 			)
								// 		) {
								// 			return Promise.reject(
								// 				"Meta data name already exists"
								// 			);
								// 		}
								// 		return Promise.resolve();
								// 	},
								// 	message: "Meta data name already exists.",
								// },
							]}
							initialValue={
								isModalOpen === 7
									? dataSource?.metaDataConfig.newMetaDataTable.find(
											(el) => el.id === editId
									  )?.columnHeaders
									: undefined
							}
						>
							<MyInput
								placeholder="Please enter column names (comma separated)"
								className="font-normal text-base"
							/>
						</Form.Item>

						<Form.Item
							className="my-8"
							label="Row Labels"
							name="rowLabels"
							initialValue={
								isModalOpen === 7
									? dataSource?.metaDataConfig.newMetaDataTable.find(
											(el) => el.id === editId
									  )?.rowLabels
									: undefined
							}
						>
							<MyInput
								placeholder="Enter row labels (comma separated)"
								className="font-normal text-base"
							/>
						</Form.Item>

						<div className="mb-4">
							<div className="mb-2 font-bold text-sm text-text mr-2">
								Example Table
							</div>
							<MyUpload
								name={"abc.xlsx"}
								accept=".xlsx, .xls"
								// beforeUpload={() =>
								// 	handleGeneratePresignedUrl(
								// 		isModalOpen === 7
								// 			? dataSource?.metaDataConfig.newMetaDataTable.find(
								// 					(el) => el.id === editId
								// 			  )?.name || ""
								// 			: ""
								// 	)
								// }
								//@ts-ignore
								customRequest={customRequest}
								// action={preSignedUrl}
								withCredentials={true}
								maxCount={1}
							/>
						</div>

						<div className="w-full flex justify-end items-center">
							<div className="mr-2 flex items-center text-sm text-text-zs-orange">
								{/* {dataSource?.metaDataConfig.newMetaDataTable.find(
									(el) => el.id === editId
								)?.example && (
									<div
										className="mr-4 hover:text-text-zs-teal cursor-pointer"
										onClick={handleDownloadExcel}
									>
										<Download size={16} className="mr" />{" "}
										Download example
									</div>
								)} */}
								<div
									className="hover:text-text-zs-teal cursor-pointer"
									onClick={handleDownloadExcel}
								>
									<Download size={16} className="mr" />{" "}
									Download template
								</div>
							</div>
							<MyButton
								style={{ marginRight: "0.5rem" }}
								type="default"
								size="large"
								onClick={(e) => {
									e.preventDefault();
									setEditId("");
									handleCancel();
								}}
							>
								Cancel
							</MyButton>
							<MyButton
								type="primary"
								size="large"
								htmlType="submit"
							>
								{isModalOpen === 2 ? "Add" : "Edit"}
							</MyButton>
						</div>
					</Form>
				)}
				{isModalOpen === 3 && (
					<Table
						loading={tableLoading}
						className="contractTable ml-4 mr-4 mt-8"
						columns={columns}
						dataSource={tableData}
						size="small"
						style={{
							borderRadius: "8px",
							boxShadow: "0 3px 6px 0px rgba(0, 0, 0, 0.2)",
						}}
						scroll={{
							x: 1000,
							y: 400,
						}}
						pagination={{
							defaultPageSize: 5,
							showSizeChanger: false,
						}}
					/>
				)}
				{isModalOpen === 4 && (
					<div className="w-full">
						<div className="text-xl font-semibold my-4">
							Are you sure you want to delete this variable?
						</div>
						<div className="w-full flex items-center justify-end gap-4">
							<MyButton
								type="default"
								onClick={() => {
									handleCancel();
									setDeletedId("");
								}}
							>
								No
							</MyButton>
							<MyButton
								type="primary"
								onClick={() => {
									handleOk();
									handleDeletion(1);
								}}
							>
								Yes
							</MyButton>
						</div>
					</div>
				)}
				{isModalOpen === 5 && (
					<div className="w-full">
						<div className="text-xl font-semibold my-4">
							Are you sure you want to delete this table?
						</div>
						<div className="w-full flex items-center justify-end gap-4">
							<MyButton
								type="default"
								onClick={() => {
									handleCancel();
									setDeletedId("");
								}}
							>
								No
							</MyButton>
							<MyButton
								type="primary"
								onClick={() => {
									handleOk();
									handleDeletion(2);
								}}
							>
								Yes
							</MyButton>
						</div>
					</div>
				)}
			</MyModal>
		</>
	);
};

export default Configuration;
