import React from 'react';
import { Drawer, List, Spin, Typography, Button } from 'antd';
import { transcript } from '../../model/IAudioTranscription';
import './index.css';

const { Title, Text } = Typography;

interface ViewTranscriptSliderProps {
  transcriptData: transcript[];
  highlightedStartTime: string;
  onClose: () => void;
  loading: boolean;
}

const ViewTranscriptSlider: React.FC<ViewTranscriptSliderProps> = ({ transcriptData, highlightedStartTime, onClose, loading }) => {
  const blueDot = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="rgba(102,180,236,1)">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path>
    </svg>
  );

  const pinkDot = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="rgba(224,109,213,1)">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"></path>
    </svg>
  );

  return (
    <Drawer
      title="Transcript View"
      placement="right"
      closable={true}
      onClose={onClose}
      open={true}
      className="audio-transcript-drawer view-transcript-drawer"
      width={600}  // Adjusted to match the screenshot width
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button className='close-button' type="default" onClick={onClose}>
            Close
          </Button>
        </div>
      }
    >
      {loading ? (
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={transcriptData}
          renderItem={(item, index) => (
            <div className='transcript-container'>
              <div className="dot-container">
                {/* Conditionally render the correct dot */}
                {item.start_time === highlightedStartTime ? (
                  <div className="highlighted-dot">
                    {index % 2 === 0 ? pinkDot : blueDot}
                  </div>
                ) : (
                  index % 2 === 0 ? pinkDot : blueDot
                )}
                {index !== transcriptData.length - 1 && <div className="line"></div>}
              </div>
              <List.Item className={`transcript-item ${item.start_time === highlightedStartTime ? 'highlighted' : ''}`}>
                <div className='speaker-time-container'>
                  <p><strong>{item.speaker}</strong></p>
                  <p>{item.start_time}</p>
                </div>
                <Text className="comment">{item.comment}</Text>
              </List.Item>
            </div>
          )}
        />
      )}
    </Drawer>
  );
};

export default ViewTranscriptSlider;
