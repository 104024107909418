import React, { useState, useEffect } from 'react';
import { Drawer, Spin, Button } from 'antd';
import './index.css';

interface ResponseSliderProps {
  content: string | null;
  onClose: () => void;
  loading: boolean;
}

const EmailResponseSlider: React.FC<ResponseSliderProps> = ({ content, onClose, loading }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState("");

  useEffect(() => {
    if (content) {
      // Convert HTML content to plain text (remove HTML tags) for the textarea
      const plainTextContent = content.replace(/<\/?[^>]+(>|$)/g, "");
      setTextAreaContent(plainTextContent);
    }
  }, [content]);

  return (
    <Drawer
      title="Generated Response"
      placement="right"
      closable={true}
      onClose={onClose}
      open={true}
      width={500}
      className="audio-transcript-drawer"
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button
                type="default"
                className="close-action-button"
            >
                Close
            </Button>
            <Button
                type="default"
                className="share-response-action-button"
            >
                Share Response
            </Button>
        </div>
      }
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
            <div className='email-response-content'>
          <h6>Share With</h6>
          <input
            className={`send-email-input ${emailError ? 'error' : ''}`}
            type="email"
            placeholder="Enter email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            required
          />
          {emailError && <p className="error-text">Please enter a valid email address.</p>}

          <h6 >Generated Response</h6>
          <div 
            className='generated-response-textarea'
            contentEditable
            dangerouslySetInnerHTML={{ __html: content || '' }}
          />
          </div>
        </>
      )}
    </Drawer>
  );
};

export default EmailResponseSlider;
