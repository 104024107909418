import React, { useEffect, useRef, useState } from "react";
import ErrorPage from "../../pages/errorPage";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import ContextModal from "../contextModal";
import MyInput from "../input";
import DOMPurify from 'dompurify';
import './index.css';
import axios from 'axios';

interface ChatBotProps {
  isVisible: boolean;
  onClose: () => void;
  questions: string[];
  setInitialQuery: string;
}

interface IChatProps {
	id: number;
	source: string;
	text?: string;
	html?: string; // Optional property for HTML content
	timestamp: string;
  }
  

const ChatBot: React.FC<ChatBotProps> = ({ isVisible, onClose, questions, setInitialQuery }) => {
  const store = useSelector(selectStore);
  const [data, setData] = useState<IChatProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [blockSend, setBlockSend] = useState(false);
  const [error, setError] = useState(false);
  const [contextData, setContextData] = useState<any>(null);
  const streamData = useRef("");
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [chatHeight, setChatHeight] = useState(0);
  const [query, setQuery] = useState(setInitialQuery);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackState, setFeedbackState] = useState<'none' | 'thumbsUp' | 'thumbsDown'>('none');
  // const [showIssueQuestions, setShowIssueQuestions] = useState(false);

  const sanitizeHtml = (html: string) => DOMPurify.sanitize(html);
  
  const initialContent: IChatProps[] = [
    {
      id: -2,
      source: "system",
      html: sanitizeHtml(`<div class="initial-content-container style={{width: '400px'}}">
              <div 
                style={{
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: 'rgba(113, 110, 121, 1)'
                }}
              >
                Smart Assistant
              </div>
              <h3 style="color: #2F2C3C; font-size: 16px; line-height: 24px; font-weight: 700; margin: 0; padding: 0;">
                Hi, I'm your Smart Assistant. I can help you find answers and explore insights about things that matter to you. Ask me anything you’d like help with!
              </h3>
              <br />
              <p style="color: #2F2C3C; font-size: 16px; line-height: 24px; font-weight: 400;">
                I might not be able to always give the best answer, but as I grow I learn to help assist and answer questions more efficiently.
              </p>
              <br />
              <p style="color: #2F2C3C; font-size: 16px; line-height: 24px; font-weight: 400;">
                Here are some recommendations I have based on what you are working with if you aren’t sure what to ask me:
              </p>
            </div>`),
      timestamp: new Date().toString(),
    },
  ];

  const handleClose = () => {
    axios.post(process.env.REACT_APP_API_URL + '/clearalldocuments')
      .then(response => {
        console.log('Cache cleared successfully:', response.data);
      })
      .catch(error => {
        console.error('Error clearing cache:', error);
      })
      .finally(() => {
        onClose();
      });
  };

  const transformChatData = (chatData: any[]) => {
    const transformedData: IChatProps[] = [];
    chatData.forEach((el: any) => {
      transformedData.push({
        id: el.id,
        source: el.user,
        text: el.query,
        timestamp: el.timestamp,
      });
      transformedData.push({
        id: el.id,
        source: "system",
        text: el.response,
        timestamp: el.timestamp,
      });
    });
    return transformedData;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    scrollToBottom();
    const h = chatContainerRef.current?.clientHeight;
    setChatHeight(h || 0);
  }, [data]);

  const fetchData = () => {
    setLoading(true);
    const apiData = {
      chat: [],
    };
    const transformedData = transformChatData(apiData.chat);
    setData([ ...transformedData]);
    setLoading(false);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [data]);

  const handleModalOpen = (context: any) => {
    setContextData(context);
    setIsModalVisible(true);
  };
  
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  

  const handleSend = async (query: string) => {
    setBlockSend(true);
    setData((prevData) => [
      ...prevData,
      {
        id: -1,
        source: store.userDetails.username,
        text: query,
        timestamp: new Date().toString(),
      },
    ]);
  
    const payload = JSON.stringify({
      doc_name: "",
      query: query,
      agent_id: 10,
    });
  
    await fetchEventSource(
      process.env.REACT_APP_API_URL + "/document_qna_stream/" + store.userDetails.username,
      {
        method: "POST",
        body: payload,
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        //@ts-ignore
        onopen(res: any) {
          if (res.ok && res.status === 200) {
            // Handle open event if needed
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            setError(true);
          }
        },
        onmessage(res: any) {
          const data = res.data;
        
          if (data.includes("$$$context$$$")) {
            const [textBeforeContext, contextString] = data.split("$$$context$$$");
            streamData.current += textBeforeContext;
        
            const contextJson = JSON.parse(contextString);
            setContextData(contextJson);
        
            setData((prevChatData) => {
              const lastMessage = prevChatData[prevChatData.length - 1];
              if (lastMessage?.source === "system") {
                return [
                  ...prevChatData.slice(0, -1),
                  {
                    ...lastMessage,
                    text: (lastMessage.text || "") + streamData.current, // Ensure text is a string
                  },
                ];
              } else {
                return [
                  ...prevChatData,
                  {
                    id: new Date().getTime(),
                    source: "system",
                    text: streamData.current,
                    timestamp: new Date().toString(),
                  },
                ];
              }
            });
        
            streamData.current = "";
          } else if (data.includes("***FINAL***")) {
            const [_, finalHtml] = data.split("***FINAL***");
            const sanitizedHtml = sanitizeHtml(finalHtml);

            setData((prevChatData) => {
              const lastMessage = prevChatData[prevChatData.length - 1];
              if (lastMessage?.source === "system") {
                return [
                  ...prevChatData.slice(0, -1),
                  {
                    ...lastMessage,
                    html: sanitizedHtml,
                  },
                ];
              } else {
                return [
                  ...prevChatData,
                  {
                    id: new Date().getTime(),
                    source: "system",
                    html: sanitizedHtml,
                    timestamp: new Date().toString(),
                  },
                ];
              }
            }); 
            streamData.current = "";
          } else {
            streamData.current += data;
            setData((prevChatData) => {
              const lastMessage = prevChatData[prevChatData.length - 1];
              if (lastMessage?.source === "system") {
                return [
                  ...prevChatData.slice(0, -1),
                  {
                    ...lastMessage,
                    text: (lastMessage.text || "") + data, // Ensure text is a string
                  },
                ];
              } else {
                return [
                  ...prevChatData,
                  {
                    id: new Date().getTime(),
                    source: "system",
                    text: data,
                    timestamp: new Date().toString(),
                  },
                ];
              }
            });
          }
        },                        
        onclose() {
          setBlockSend(false);
  
          if (streamData.current) {
            setData((prevChatData) => {
              const lastMessage = prevChatData[prevChatData.length - 1];
              if (lastMessage?.source === "system") {
                return [
                  ...prevChatData.slice(0, -1),
                  {
                    ...lastMessage,
                    text: lastMessage.text + streamData.current,
                  },
                ];
              } else {
                return [
                  ...prevChatData,
                  {
                    id: new Date().getTime(),
                    source: "system",
                    text: streamData.current,
                    timestamp: new Date().toString(),
                  },
                ];
              }
            });
          }
  
          streamData.current = "";
        },
        onerror(err: any) {
          setError(true);
        },
      }
    );
  
    setQuery("");
  };
  

  const handleQuestionClick = (question: string) => {
    setQuery(question);
    // handleSend(question);
  };

  const handleThumbsUpClick = () => {
    if (feedbackState === 'thumbsUp') {
      // If already clicked, revert to original state
      setFeedbackState('none');
    } else {
      // Activate thumbs up and deactivate thumbs down
      setFeedbackState('thumbsUp');
    }
  };

  const handleThumbsDownClick = () => {
    if (feedbackState === 'thumbsDown') {
      // If already clicked, revert to original state
      setFeedbackState('none');
    } else {
      // Activate thumbs down and deactivate thumbs up
      setFeedbackState('thumbsDown');
    }
  };

  // const handleToggleIssueQuestions = () => {
  //   setShowIssueQuestions(!showIssueQuestions);
  //   // setQuery("Raise an issue against a question within this call");
  // };
  
  const renderUserMessage = (text: string) => (
    <div className="chat-message-content">
      <div className="chat-icon chat-username-container">
        <p>{store.userDetails.username.slice(0, 2).toLocaleUpperCase()}</p>
      </div>
      <div className="username-response-container">
        <span className="chat-username">{store.userDetails.username.toLocaleUpperCase()}</span>
        <div className="chat-message-text" dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
      </div>
    </div>
  );

  const renderSystemMessage = (text?: string, html?: string) => (
    <div className="chat-message-content">
      <div  className="chat-icon">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="#F4F3F3"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0456 8.86141C16.3988 8.493 15.6011 8.493 14.9543 8.86141L10.2587 11.536C9.61418 11.9031 9.21735 12.5802 9.21735 13.3129V18.6866C9.21735 19.4193 9.61418 20.0964 10.2587 20.4635L14.9543 23.1381C15.6011 23.5065 16.3988 23.5065 17.0456 23.1381L21.7413 20.4635C22.3857 20.0964 22.7826 19.4193 22.7826 18.6866V13.3129C22.7826 12.5802 22.3857 11.9031 21.7413 11.536L17.0456 8.86141ZM25.913 11.5372C25.913 10.8045 25.5162 10.1274 24.8717 9.76031L17.0456 5.30268C16.3988 4.93426 15.6011 4.93426 14.9543 5.30268L7.12823 9.76031C6.48374 10.1274 6.08691 10.8045 6.08691 11.5372V20.4623C6.08691 21.195 6.48374 21.8721 7.12823 22.2392L14.9543 26.6968C15.6011 27.0653 16.3988 27.0653 17.0456 26.6968L24.8717 22.2392C25.5162 21.8721 25.913 21.195 25.913 20.4623V11.5372Z" fill="url(#paint0_angular_3826_14689)"/>
          <path d="M14.9565 15.3387V7.27361L23.0687 10.9686C23.8416 11.3206 24.3195 12.0993 24.2762 12.936L23.826 21.6461L15.9853 17.1085C15.3479 16.7396 14.9565 16.0663 14.9565 15.3387Z" fill="url(#paint1_angular_3826_14689)"/>
          <defs>
          <radialGradient id="paint0_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
          <stop offset="0.0687497" stop-color="#7232CF"/>
          <stop offset="0.219791" stop-color="#EC7200"/>
          <stop offset="0.688541" stop-color="#EC7200"/>
          <stop offset="0.922916" stop-color="#7232CF"/>
          </radialGradient>
          <radialGradient id="paint1_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
          <stop offset="0.0687497" stop-color="#7232CF"/>
          <stop offset="0.219791" stop-color="#EC7200"/>
          <stop offset="0.688541" stop-color="#EC7200"/>
          <stop offset="0.922916" stop-color="#7232CF"/>
          </radialGradient>
          </defs>
        </svg>
      </div>
      <div className="username-response-container">
        <div className="name-feedback-container">
          <span className="chat-username">Smart Assistant</span>
          <div className="feedback-container">
          <button onClick={() => handleModalOpen(contextData)} className="sources-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"></path></svg>
          </button>
          {/* Thumbs Up Button */}
          <button onClick={handleThumbsUpClick} className="feedback-button">
              {feedbackState === 'thumbsUp' ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M2 8.99997H5V21H2C1.44772 21 1 20.5523 1 20V9.99997C1 9.44769 1.44772 8.99997 2 8.99997ZM7.29289 7.70708L13.6934 1.30661C13.8693 1.13066 14.1479 1.11087 14.3469 1.26016L15.1995 1.8996C15.6842 2.26312 15.9026 2.88253 15.7531 3.46966L14.5998 7.99997H21C22.1046 7.99997 23 8.8954 23 9.99997V12.1043C23 12.3656 22.9488 12.6243 22.8494 12.8658L19.755 20.3807C19.6007 20.7554 19.2355 21 18.8303 21H8C7.44772 21 7 20.5523 7 20V8.41419C7 8.14897 7.10536 7.89462 7.29289 7.70708Z"></path></svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M14.5998 8.00033H21C22.1046 8.00033 23 8.89576 23 10.0003V12.1047C23 12.3659 22.9488 12.6246 22.8494 12.8662L19.755 20.3811C19.6007 20.7558 19.2355 21.0003 18.8303 21.0003H2C1.44772 21.0003 1 20.5526 1 20.0003V10.0003C1 9.44804 1.44772 9.00033 2 9.00033H5.48184C5.80677 9.00033 6.11143 8.84246 6.29881 8.57701L11.7522 0.851355C11.8947 0.649486 12.1633 0.581978 12.3843 0.692483L14.1984 1.59951C15.25 2.12534 15.7931 3.31292 15.5031 4.45235L14.5998 8.00033ZM7 10.5878V19.0003H18.1606L21 12.1047V10.0003H14.5998C13.2951 10.0003 12.3398 8.77128 12.6616 7.50691L13.5649 3.95894C13.6229 3.73105 13.5143 3.49353 13.3039 3.38837L12.6428 3.0578L7.93275 9.73038C7.68285 10.0844 7.36341 10.3746 7 10.5878ZM5 11.0003H3V19.0003H5V11.0003Z"></path></svg>
              )}
            </button>

            {/* Thumbs Down Button */}
            <button onClick={handleThumbsDownClick} className="feedback-button">
              {feedbackState === 'thumbsDown' ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M22 15H19V3H22C22.5523 3 23 3.44772 23 4V14C23 14.5523 22.5523 15 22 15ZM16.7071 16.2929L10.3066 22.6934C10.1307 22.8693 9.85214 22.8891 9.65308 22.7398L8.8005 22.1004C8.3158 21.7369 8.09739 21.1174 8.24686 20.5303L9.40017 16H3C1.89543 16 1 15.1046 1 14V11.8957C1 11.6344 1.05118 11.3757 1.15064 11.1342L4.24501 3.61925C4.3993 3.24455 4.76447 3 5.16969 3H16C16.5523 3 17 3.44772 17 4V15.5858C17 15.851 16.8946 16.1054 16.7071 16.2929Z"></path></svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M9.40017 16H3C1.89543 16 1 15.1046 1 14V11.8957C1 11.6344 1.05118 11.3757 1.15064 11.1342L4.24501 3.61925C4.3993 3.24455 4.76447 3 5.16969 3H22C22.5523 3 23 3.44772 23 4V14C23 14.5523 22.5523 15 22 15H18.5182C18.1932 15 17.8886 15.1579 17.7012 15.4233L12.2478 23.149C12.1053 23.3508 11.8367 23.4184 11.6157 23.3078L9.80163 22.4008C8.74998 21.875 8.20687 20.6874 8.49694 19.548L9.40017 16ZM17 13.4125V5H5.83939L3 11.8957V14H9.40017C10.7049 14 11.6602 15.229 11.3384 16.4934L10.4351 20.0414C10.3771 20.2693 10.4857 20.5068 10.6961 20.612L11.3572 20.9425L16.0673 14.27C16.3172 13.9159 16.6366 13.6257 17 13.4125ZM19 13H21V5H19V13Z"></path></svg>
              )}
            </button>
          </div>
        </div>
          { html ? (
            <div className="chat-messgae-text" dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />
          ) : (
            <div className="chat-messgae-text">{text}</div>
          )}
        {/* {html ? <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} /> : text} */}
      </div>
    </div>
  );

  return (
    <div className="chatbot-container">
      {!isVisible ? (
        <div className="chatbot-toggle" onClick={handleClose}>
          <div className="hexagon-container">
          <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#F4F3F3"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0456 8.86141C16.3988 8.493 15.6011 8.493 14.9543 8.86141L10.2587 11.536C9.61418 11.9031 9.21735 12.5802 9.21735 13.3129V18.6866C9.21735 19.4193 9.61418 20.0964 10.2587 20.4635L14.9543 23.1381C15.6011 23.5065 16.3988 23.5065 17.0456 23.1381L21.7413 20.4635C22.3857 20.0964 22.7826 19.4193 22.7826 18.6866V13.3129C22.7826 12.5802 22.3857 11.9031 21.7413 11.536L17.0456 8.86141ZM25.913 11.5372C25.913 10.8045 25.5162 10.1274 24.8717 9.76031L17.0456 5.30268C16.3988 4.93426 15.6011 4.93426 14.9543 5.30268L7.12823 9.76031C6.48374 10.1274 6.08691 10.8045 6.08691 11.5372V20.4623C6.08691 21.195 6.48374 21.8721 7.12823 22.2392L14.9543 26.6968C15.6011 27.0653 16.3988 27.0653 17.0456 26.6968L24.8717 22.2392C25.5162 21.8721 25.913 21.195 25.913 20.4623V11.5372Z" fill="url(#paint0_angular_3826_14689)"/>
            <path d="M14.9565 15.3387V7.27361L23.0687 10.9686C23.8416 11.3206 24.3195 12.0993 24.2762 12.936L23.826 21.6461L15.9853 17.1085C15.3479 16.7396 14.9565 16.0663 14.9565 15.3387Z" fill="url(#paint1_angular_3826_14689)"/>
            <defs>
            <radialGradient id="paint0_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
            <stop offset="0.0687497" stop-color="#7232CF"/>
            <stop offset="0.219791" stop-color="#EC7200"/>
            <stop offset="0.688541" stop-color="#EC7200"/>
            <stop offset="0.922916" stop-color="#7232CF"/>
            </radialGradient>
            <radialGradient id="paint1_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
            <stop offset="0.0687497" stop-color="#7232CF"/>
            <stop offset="0.219791" stop-color="#EC7200"/>
            <stop offset="0.688541" stop-color="#EC7200"/>
            <stop offset="0.922916" stop-color="#7232CF"/>
            </radialGradient>
            </defs>
            </svg>
          </div>
        </div>
      ) : (
        <div className="chatbot-modal open">
          <div className="chatbot-header">
            <h3>Smart Assistant</h3>
            <span className="close-2" onClick={handleClose}>&times;</span>
          </div>
          <div className="chat-container" ref={chatContainerRef}>
            {!error ? (
              <div className="chat-messages">
                <div className="">
                <div className="chatbot-top-fixed-content-plus-questions">
                  <div className="chatbot-top-svg-fixed-content">
                  <div  className="chat-icon">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="32" height="32" rx="16" fill="#F4F3F3"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0456 8.86141C16.3988 8.493 15.6011 8.493 14.9543 8.86141L10.2587 11.536C9.61418 11.9031 9.21735 12.5802 9.21735 13.3129V18.6866C9.21735 19.4193 9.61418 20.0964 10.2587 20.4635L14.9543 23.1381C15.6011 23.5065 16.3988 23.5065 17.0456 23.1381L21.7413 20.4635C22.3857 20.0964 22.7826 19.4193 22.7826 18.6866V13.3129C22.7826 12.5802 22.3857 11.9031 21.7413 11.536L17.0456 8.86141ZM25.913 11.5372C25.913 10.8045 25.5162 10.1274 24.8717 9.76031L17.0456 5.30268C16.3988 4.93426 15.6011 4.93426 14.9543 5.30268L7.12823 9.76031C6.48374 10.1274 6.08691 10.8045 6.08691 11.5372V20.4623C6.08691 21.195 6.48374 21.8721 7.12823 22.2392L14.9543 26.6968C15.6011 27.0653 16.3988 27.0653 17.0456 26.6968L24.8717 22.2392C25.5162 21.8721 25.913 21.195 25.913 20.4623V11.5372Z" fill="url(#paint0_angular_3826_14689)"/>
                      <path d="M14.9565 15.3387V7.27361L23.0687 10.9686C23.8416 11.3206 24.3195 12.0993 24.2762 12.936L23.826 21.6461L15.9853 17.1085C15.3479 16.7396 14.9565 16.0663 14.9565 15.3387Z" fill="url(#paint1_angular_3826_14689)"/>
                      <defs>
                      <radialGradient id="paint0_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
                      <stop offset="0.0687497" stop-color="#7232CF"/>
                      <stop offset="0.219791" stop-color="#EC7200"/>
                      <stop offset="0.688541" stop-color="#EC7200"/>
                      <stop offset="0.922916" stop-color="#7232CF"/>
                      </radialGradient>
                      <radialGradient id="paint1_angular_3826_14689" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8695 13.4317) rotate(49.9299) scale(2.4315 2.31223)">
                      <stop offset="0.0687497" stop-color="#7232CF"/>
                      <stop offset="0.219791" stop-color="#EC7200"/>
                      <stop offset="0.688541" stop-color="#EC7200"/>
                      <stop offset="0.922916" stop-color="#7232CF"/>
                      </radialGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="initial-content" dangerouslySetInnerHTML={{ __html: initialContent[0]?.html || '' }} />
                  </div>
                  <div className="questions-list">
                    {questions.slice(0,2).map((question, index) => (
                        <div key={index} className="question-item" onClick={() => handleQuestionClick(question)}>
                          {question}
                        </div>
                    ))}
                    {/* <div className="question-item" onClick={handleToggleIssueQuestions}>
                      <p className="raise-a-issue-container">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"></path></svg>
                        <span> Raise an issue against a question within this call</span>
                      </p>
                    </div>
                    { showIssueQuestions && (
                          <div className="raise-a-issue-questions">
                            {questions.map((question, index) => (
                            <div key={index} className="raise-a-issue-question-item" onClick={() => handleQuestionClick(question)}>
                              {question}
                            </div>
                            ))}
                          </div>
                        )} */}
                  </div>
                </div>
                {data.map((chat) => (
                  <div key={chat.id} className={`chat-message ${chat.source}`}>
                    {chat.source === store.userDetails.username
                      ? renderUserMessage(chat.text || '')
                      : renderSystemMessage(chat.text || '', chat.html || '')}
                  </div>
                ))}
                  {blockSend && (
                    <div className="loading-dots">
                      <div className="dot-1"></div>
                      <div className="dot-1"></div>
                      <div className="dot-1"></div>
                    </div>
                  )}
                  </div>
                  <div className="bg-white mx-2 px-2 py-2 flex items-center input-placeholder">
                  <MyInput
                    type="text"
                    className="chat-input-container"
                    placeholder="Send a message"
                    value={query} // Bind the input value to the query state
                    onChange={(e) => setQuery(e.target.value)} // Update the query state on input change
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && query.trim()) {
                        handleSend(query); // Send the query when Enter is pressed
                        setQuery(""); // Clear the input field
                      }
                    }}
                    disabled={blockSend}
                  />
                  </div>
                  <ContextModal
                    isVisible={isModalVisible}
                    onClose={handleModalClose}
                    contextData={contextData}
                  />
              </div>
            ) : (
              <ErrorPage />
            )}
          </div>
          <div className="chatbot-toggle-1" onClick={() => handleClose()}>
            <div className="hexagon-container">
              <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9601 7.6155C19.7474 6.92473 18.2517 6.92473 17.039 7.6155L8.23463 12.6303C7.02622 13.3186 6.28216 14.5883 6.28216 15.962V26.0378C6.28216 27.4115 7.02621 28.6812 8.23463 29.3695L17.039 34.3843C18.2517 35.0751 19.7474 35.0751 20.9601 34.3843L29.7645 29.3695C30.9729 28.6812 31.7169 27.4115 31.7169 26.0378V15.962C31.7169 14.5883 30.9729 13.3186 29.7645 12.6303L20.9601 7.6155ZM37.5865 12.6326C37.5865 11.2589 36.8425 9.98924 35.634 9.30095L20.9601 0.942884C19.7474 0.252113 18.2517 0.252111 17.039 0.942882L2.36506 9.30094C1.15665 9.98924 0.412598 11.2589 0.412598 12.6326V29.3672C0.412598 30.7409 1.15665 32.0106 2.36506 32.6989L17.039 41.0569C18.2517 41.7477 19.7474 41.7477 20.9601 41.057L35.634 32.6989C36.8425 32.0106 37.5865 30.7409 37.5865 29.3672V12.6326Z" fill="white"/>
                <path d="M17.043 19.7605V4.63838L32.2535 11.5664C33.7026 12.2264 34.5986 13.6865 34.5175 15.2554L33.6735 31.5868L18.9721 23.0787C17.7769 22.3871 17.043 21.1247 17.043 19.7605Z" fill="white"/>
              </svg>
            </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
