import { Modal, ModalProps } from "antd";
import React from "react";
import { Close } from "@styled-icons/material/Close";
const MyModal = (props: ModalProps) => {
	const { ...rest } = props;

	// const modalStyles = {
	// 	header: {
	// 		fontSize: "1.5rem",
	// 		fontWeight: 700,
	// 	},
	// };
	return (
		<Modal
			className="modal"
			{...rest}
			closeIcon={
				<span className="">
					<Close size={30} />
				</span>
			}
		>
			{props.children}
		</Modal>
	);
};

export default MyModal;
