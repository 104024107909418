import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Tag } from "antd";
import "./index.css";
import { ITableData } from "../../model/ITableData";
import { useDispatch, useSelector } from "react-redux";
import {
	selectStore,
	setContractDetails,
	setUserDetails,
} from "../../state/slice/appSlice";
import axios from "axios";
import ErrorPage from "../errorPage";
import myMessages from "../../components/messages";

const FormExtractorContractReview = () => {
	const store = useSelector(selectStore);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<ITableData[]>();
	const [columns, setColumns] = useState<any>();
	const [error, setError] = useState<boolean>();

	const reviewContract = (record: any) => {
		dispatch(
			setContractDetails({ name: record.contractName, id: record.id })
		);
		sessionStorage.setItem("contractId", record.id);
		sessionStorage.setItem("contractName", record.contractName);
		navigate("" + record.id);
	};

	const filterData = (data: any) => (formatter: any) => {
		const uniqueValues = new Set();

		return data
			.filter((item: any) => {
				const formattedValue = formatter(item);
				if (!uniqueValues.has(formattedValue)) {
					uniqueValues.add(formattedValue);
					return true;
				}
				return false;
			})
			.map((item: any) => ({
				text: formatter(item),
				value: formatter(item),
			}));
	};

	function getTagColor(record: any) {
		if (record.status === "Open") {
			return (
				<>
					<Tag bordered={false} color={"warning"} key={record.status}>
						{record.status}
					</Tag>
				</>
			);
		} else if (record.status === "Closed") {
			return (
				<>
					<Tag bordered={false} color={"success"} key={record.status}>
						{record.status}
					</Tag>
				</>
			);
		} else if (record.status === "Reviewed") {
			return (
				<>
					<Tag
						bordered={false}
						color={"processing"}
						key={record.status}
					>
						{record.status}
					</Tag>
				</>
			);
		}
	}

	const fetchData = async () => {
		axios
			.get(
				"https://intelligent-automation.api.demo.zsservices.com/contracts/" +
					store.userDetails.username
			)
			.then((res: any) => {
				console.log("FETCHED DATA", res.data);
				const columnsTbl = [
					{
						title: "ID",
						dataIndex: "id",
						key: "id",
						align: "center" as "center",
						width: "5%",
					},
					{
						title: "Contract Name",
						dataIndex: "contractName",
						key: "contractName",
						// align: "center" as "center",
						onFilter: (value: any, record: any) =>
							record.contractName === value,
						filters: filterData(res.data)(
							(i: any) => i.contractName
						),
						filterSearch: true,
						width: "20%",
						// defaultSortOrder: "ascend",
						sorter: (a: any, b: any) =>
							a.contractName.localeCompare(b.contractName),
						render: (_: any, record: any) => {
							return (
								<>
									<div
										style={{
											color: "#27a6a4",
											fontSize: "14px",
											fontWeight: 500,
											cursor: "pointer",
										}}
										onClick={() => reviewContract(record)}
									>
										{record.contractName.split(".pdf")[0]}
									</div>
								</>
							);
						},
					},
					{
						title: "Contract Description",
						dataIndex: "contractDesc",
						key: "contractDesc",
						// align: "center" as "center",
						width: "30%",
					},
					{
						title: "Date Uploaded",
						align: "center" as "center",
						key: "dateUploaded",
						dataIndex: "dateUploaded",
						width: "15%",
						onFilter: (value: any, record: any) =>
							record.dateUploaded === value,
						filters: filterData(res.data)(
							(i: any) => i.dateUploaded
						),
						// defaultSortOrder: "ascend",
						sorter: (a: any, b: any) =>
							new Date(a.dateUploaded).getTime() -
							new Date(b.dateUploaded).getTime(),
					},
					{
						title: "Uploaded By",
						dataIndex: "reviewerName",
						key: "reviewerName",
						width: "15%",
						// align: "center" as "center",
						// defaultSortOrder: "ascend",
						sorter: (a: any, b: any) =>
							a.reviewerName.localeCompare(b.reviewerName),
						render: (_: any, record: any) => {
							return (
								<div className="grid grid-cols-12 items-center">
									<div className="col-span-2"></div>
									<div className="col-span-2">
										<img
											className="border-2 border-border-zs-teal"
											src={
												"https://zs-adfs01.zsassociates.com/photos/" +
												record.reviewerEmpID +
												".jpg"
											}
											style={{
												borderRadius: "50%",
												width: "1.625rem",
												height: "1.625rem",
												display: "inline-block",
												// paddingRight: "0.125rem",
												marginRight: "0.125rem",
											}}
										/>
									</div>

									<div className="col-span-8">
										{record.reviewerName}
									</div>
									<div className="col-span-2"></div>
								</div>
							);
						},
						onFilter: (value: any, record: any) =>
							record.reviewerName === value,
						filters: filterData(res.data)(
							(i: any) => i.reviewerName
						),
						filterSearch: true,
					},
					{
						title: "Status",
						key: "status",
						dataIndex: "status",
						render: (_: any, record: any) => getTagColor(record),
						align: "center" as "center",
						onFilter: (value: any, record: any) =>
							record.status === value,
						filters: filterData(res.data)((i: any) => i.status),
						filterSearch: true,
						width: "15%",
						// defaultSortOrder: "ascend",
						sorter: (a: any, b: any) =>
							a.status.localeCompare(b.status),
					},
				];
				setColumns(columnsTbl);
				setTableData(res.data);
				setLoading(false);
			})
			.catch((e) => setError(true));
	};

	useEffect(() => {
		if (store.userDetails.username) fetchData();
	}, [store.userDetails.username]);
	useEffect(() => {
		if (!store.userDetails.username)
			dispatch(
				setUserDetails({
					username: sessionStorage.getItem("username") || "",
				})
			);
	}, []);
	return (
		<>
			{!error ? (
				<Table
					loading={loading}
					className="contractTable ml-4 mr-4 mt-8"
					columns={columns}
					dataSource={tableData}
					size="small"
					style={{
						borderRadius: "8px",
						boxShadow: "0 3px 6px 0px rgba(0, 0, 0, 0.2)",
					}}
					scroll={{ y: "calc(100vh - 160px)" }}
					pagination={{
						defaultPageSize: 16,
						showSizeChanger: false,
					}}
					rowClassName="rown"
				/>
			) : (
				<ErrorPage />
			)}
		</>
	);
};

export default FormExtractorContractReview;
