import React, { useEffect, useRef, useState } from "react";
import { IChatProps } from "../../model/IChatProps";
import MyInput from "../input";
import { Send } from "@styled-icons/bootstrap/Send";
import { Skeleton, Spin } from "antd";
import { IChatComponentProps } from "../../model/IChatComponentProps";
import { Chatbot } from "@styled-icons/simple-icons/Chatbot";
import Cursor from "../cursor";

const Chat = (props: IChatComponentProps) => {
	const chatRef = useRef<HTMLDivElement>(null);
	const [query, setQuery] = useState("");
	const [chatHeight, setChatHeight] = useState(0);
	// Function to keep chat scrolled to bottom
	const scrollToBottom = () => {
		console.log("HERE");
		if (chatRef.current) {
			console.log("SCROLL HT", chatRef.current.scrollHeight);
			chatRef.current.scrollTop = chatRef.current.scrollHeight;
		}
	};

	// Scroll to bottom on initial render and whenever messages change
	useEffect(() => {
		scrollToBottom();
	}, [props.chats]);
	useEffect(() => {
		const h = document.getElementById("chat-container")?.clientHeight;
		setChatHeight(h || 0);
		console.log("HH", h);
	}, [props.chats]);
	return (
		<div className="bg-chat-bg rounded-md">
			<div
				className="overflow-y-auto flex flex-col"
				style={{
					// height: "80vh",
					maxHeight: `${chatHeight - 55}px`,
					minHeight: `${chatHeight - 55}px`,
				}}
				ref={chatRef}
			>
				{!props.loading &&
					props.chats.map((chat, index) => (
						<div
							className={`p-4 w-full flex ${
								chat.source === "system" ? "" : "justify-end"
							}`}
						>
							{chat.source === "system" && (
								<div className="bg-chat-system-icon rounded-full h-8 w-8 flex justify-center items-center text-sm mr-2">
									A
								</div>
							)}
							<div
								className={`w-5/6 flex ${
									chat.source === "system"
										? ""
										: "justify-end"
								}`}
							>
								<div
									className={`w-fit p-4 ${
										chat.source === "system"
											? "bg-chat-system-bg rounded-r-md rounded-bl-md"
											: "bg-chat-user-bg rounded-l-md rounded-br-md"
									}`}
								>
									{props.blockSend &&
									chat.source === "system" &&
									index === props.chats.length - 1 ? (
										<Cursor text={chat.text} />
									) : (
										<div
											dangerouslySetInnerHTML={{
												__html: chat.text,
											}}
										></div>
									)}
								</div>
							</div>
							{chat.source !== "system" && (
								<div className="bg-chat-user-icon rounded-full h-8 w-8 flex justify-center items-center text-sm ml-2">
									{chat.source[0].toLocaleUpperCase()}
								</div>
							)}
						</div>
					))}
				{props.loading && (
					<div
						className="flex items-center justify-center text-text-zs-orange"
						style={{
							// height: "80vh",
							maxHeight: `${chatHeight - 55}px`,
							minHeight: `${chatHeight - 55}px`,
						}}
						// ref={chatRef}
					>
						<Spin size="large"></Spin>
					</div>
				)}
				{!props.loading && props.chats.length === 0 && (
					<div
						className="flex flex-col items-center justify-center"
						style={{
							// height: "80vh",
							maxHeight: `${chatHeight - 55}px`,
							minHeight: `${chatHeight - 55}px`,
						}}
					>
						<div className="text-gray-500">
							<Chatbot size={42} />
						</div>
						<div className="text-2xl font-normal">
							How can I help you today?
						</div>
					</div>
				)}
			</div>

			<div className="bg-white mx-2 px-2 py-2 flex items-center input-placeholder">
				<MyInput
					value={query}
					placeholder="Type a message"
					className="border-1 border-border-zs-orange rounded-full flex-1"
					onChange={(e) => setQuery(e.target.value)}
					onPressEnter={() => {
						props.handleSend(query);
						setQuery("");
					}}
					disabled={props.loading || props.blockSend}
				/>
				<div className="flex items-center justify-end ml-2">
					{props.blockSend ? (
						<Spin size="small"></Spin>
					) : (
						<Send
							size={15}
							className="cursor-pointer"
							onClick={() => {
								if (!props.loading && query) {
									props.handleSend(query);
									setQuery("");
								}
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Chat;
