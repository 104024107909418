import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import { Dashboard } from "@styled-icons/material-twotone/Dashboard";
import { SupervisedUserCircle } from "@styled-icons/material-twotone/SupervisedUserCircle";
import { ManageAccounts } from "@styled-icons/material-twotone/ManageAccounts";
import { Reviews } from "@styled-icons/material-twotone/Reviews";
import { Apps } from "@styled-icons/fluentui-system-filled/Apps";
import { Chat } from "@styled-icons/material-twotone/Chat";
import { SettingsApplications } from "@styled-icons/material-twotone/SettingsApplications";
import { ReactComponent as AccessManagement } from "../../assets/icon/accessManagement.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setSidebarOptions } from "../../state/slice/appSlice";
import {
	ISidebarOptions,
	ISidebarOptionsChildren,
} from "../../model/ISidebarOptions";
const Sidebar = () => {
	const store = useSelector(selectStore);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const path = location.pathname.endsWith("/")
		? location.pathname.slice(0, -1)
		: location.pathname;
	const [expanded, setExpanded] = useState(path.split("/").length <= 2);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isHomepage, setIsHomepage] = useState(true);
	const [selectedDashboardOptions, setSelectedDashboardOptions] =
		useState<ISidebarOptionsChildren>();

	const isSelected = (route: string) => {
		return path.startsWith(route);
	};
	const dashboardOptions = [
		{
			icon: <Dashboard size={24} />,
			label: "Active Agents",
			route: "/active-agents",
			children: [
				{
					id: 1,
					paths: [
						{
							icon: <Apps size={24} />,
							label: "Contract Review",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/contract-review",
						},
						{
							icon: <Chat size={24} />,
							label: "Ask Contracts",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/ask-contracts",
						},
						{
							icon: <SettingsApplications size={24} />,
							label: "Configuration",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/configuration",
						},
					],
				},
				{
					id: 3,
					paths: [
						{
							icon: <Apps size={24} />,
							label: "Invoice Review",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/invoice-review",
						},
						{
							icon: <Chat size={24} />,
							label: "Ask invoices",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/ask-invoices",
						},
						{
							icon: <SettingsApplications size={24} />,
							label: "Configuration",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/configuration",
						},
					],
				},
				{
					id: 6,
					paths: [
						{
							icon: <Chat size={24} />,
							label: "Chat",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/chat",
						},
					],
				},
				{
					id: 7,
					paths: [
						{
							icon: <Chat size={24} />,
							label: "Ask Virtual Assistant",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/ask-contracts",
						},
					],
				},
				{
					id: 8,
					paths: [
						{
							icon: <Apps size={24} />,
							label: "Contract Review",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/contract-review",
						},
						// {
						// 	icon: <Chat size={24} />,
						// 	label: "Ask Contracts",
						// 	route:
						// 		"/active-agents/" +
						// 		store.selectedActiveAgent.name.replace(
						// 			/\W/g,
						// 			"_"
						// 		) +
						// 		"/ask-contracts",
						// },
						// {
						// 	icon: <SettingsApplications size={24} />,
						// 	label: "Configuration",
						// 	route:
						// 		"/active-agents/" +
						// 		store.selectedActiveAgent.name.replace(
						// 			/\W/g,
						// 			"_"
						// 		) +
						// 		"/configuration",
						// },
					],
				},
				{
					id: 9,
					paths: [
						{
							icon: <Apps size={24} />,
							label: "Audio Collection",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/audio-catalog",
						},
						{
							icon: <Chat size={24} />,
							label: "Document Repository",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/document-repository",
						},
					],
				},
				{
					id: 10,
					paths: [
						{
							icon: <Apps size={24} />,
							label: "Assistant",
							route:
								"/active-agents/" +
								store.selectedActiveAgent.name.replace(
									/\W/g,
									"_"
								) +
								"/assistant",
						},
						// {
						// 	icon: <Chat size={24} />,
						// 	label: "Ask Contracts",
						// 	route:
						// 		"/active-agents/" +
						// 		store.selectedActiveAgent.name.replace(
						// 			/\W/g,
						// 			"_"
						// 		) +
						// 		"/ask-contracts",
						// },
						// {
						// 	icon: <SettingsApplications size={24} />,
						// 	label: "Configuration",
						// 	route:
						// 		"/active-agents/" +
						// 		store.selectedActiveAgent.name.replace(
						// 			/\W/g,
						// 			"_"
						// 		) +
						// 		"/configuration",
						// },
					],
				},
			],
		},
		// {
		// 	icon: <SupervisedUserCircle size={24} />,
		// 	label: "Create New Agent",
		// 	route: "/create-new-agent",
		// 	children: [],
		// },
		{
			icon: <ManageAccounts size={24} />,
			// icon: <AccessManagement />,
			label: "Manage Users",
			route: "/manage-users",
			children: [],
		},
	];
	useEffect(() => {
		const idx = dashboardOptions.findIndex(
			(el) =>
				el.route === path ||
				el.children
					?.find((e) => e.id === store.selectedActiveAgent.id)
					?.paths.find((el) => el.route === path)
		);

		if (idx != -1) setSelectedIndex(idx);
		setExpanded(path.split("/").length <= 2);
		if (path === "") setIsHomepage(true);
		else setIsHomepage(false);
	}, [path]);

	useEffect(() => {
		const selectedAgentId = store.selectedActiveAgent.id;
		setSelectedDashboardOptions(
			dashboardOptions[selectedIndex].children.find(
				(el) => el.id === selectedAgentId
			)
		);
	}, [path, store.selectedActiveAgent.id]);

	useEffect(() => {
		const dashboardOptionsWithoutIcons = dashboardOptions.map((option) => {
			const { icon, ...rest } = option;
			const children = rest.children.map((child) => {
				const { ...childRest } = child;
				const paths = childRest.paths.map((path) => {
					const { icon: pathIcon, ...pathRest } = path;
					return pathRest;
				});
				return { ...childRest, paths };
			});
			return { ...rest, children };
		});

		console.log(dashboardOptionsWithoutIcons);
		sessionStorage.setItem(
			"dashboardOptions",
			JSON.stringify(dashboardOptionsWithoutIcons)
		);
		dispatch(setSidebarOptions(dashboardOptionsWithoutIcons));
	}, []);

	return (
		<>
			{!isHomepage && (
				<div className="h-screen bg-white w-72 relative">
					<div
						className={`absolute top-0 left-0 right-0 z-10 h-screen bg-navbar-bg  ${
							expanded ? "w-full" : "w-16"
						} transition-all ease duration-300 flex flex-col`}
						onMouseOver={() => setExpanded(true)}
						onMouseLeave={() =>
							setExpanded(path.split("/").length <= 2)
						}
					>
						<div className="w-16 p-3">
							<img
								src={logo}
								alt=""
								style={{ width: "100%" }}
								className="cursor-pointer"
								onClick={() => navigate("/")}
							/>
						</div>
						<div className="my-2 min-w-16 text-navbar-text text-base flex-1">
							<div className="flex flex-col justify-between h-full">
								<div className="h-full">
									{dashboardOptions.map((element) => (
										<div
											className={`py-2 overflow-hidden flex cursor-pointer hover:bg-navbar-hover-bg transition-all duration-100 ${
												isSelected(element.route)
													? `text-navbar-text-selected border-l-4 border-navbar-text-selected ${
															!expanded
																? "bg-navbar-selected-hover-bg"
																: ""
													  }`
													: "hover:text-white border-l-4 border-transparent"
											}`}
											onClick={() =>
												navigate(element.route)
											}
										>
											<div className="min-w-14">
												<div className="flex items-center justify-center m-auto">
													{element.icon}
												</div>
											</div>
											{expanded && (
												<div
													className={`flex-grow overflow-hidden text-nowrap`}
												>
													{element.label}
												</div>
											)}
										</div>
									))}
								</div>
								<div
									className={`h-16 flex ${
										expanded ? "w-full" : "w-16"
									} overflow-hidden`}
								>
									<div className={`w-16 flex justify-center`}>
										<div className="min-w-16 flex justify-center">
											<img
												className="border-2 border-border-zs-teal"
												src={
													"https://zs-adfs01.zsassociates.com/photos/" +
													store.userDetails.username.substring(
														2
													) +
													".jpg"
												}
												style={{
													borderRadius: "50%",
													width: "1.8rem",
													height: "1.8rem",
													display: "inline-block",
													// paddingRight: "0.125rem",
													marginRight: "0.125rem",
												}}
											/>
										</div>
									</div>
									<div>{store.userDetails.username}</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`h-full bg-navbar-secondary text-navbar-text ${
							0 ? "w-1/5" : "w-72"
						} transition-all ease duration-300 pl-16`}
					>
						<div className="h-16 mb-1"></div>
						{selectedIndex >= 0 &&
							selectedDashboardOptions?.paths.map((subroute) => (
								<div
									className={`py-2 overflow-hidden flex cursor-pointer transition-all duration-100 ${
										isSelected(subroute.route)
											? `text-navbar-text-selected  `
											: "hover:text-white "
									}`}
									onClick={() => navigate(subroute.route)}
								>
									<div className="min-w-14">
										<div className="flex items-center justify-center m-auto">
											{subroute.icon}
										</div>
									</div>

									<div
										className={`flex-grow overflow-hidden text-nowrap`}
									>
										{subroute.label}
									</div>
								</div>
							))}
					</div>
				</div>
			)}
		</>
	);
};

export default Sidebar;
