import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Card, Spin, Typography, Button, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ITableData } from "../../model/ITableData";
import {
    selectStore,
    setContractDetails,
} from "../../state/slice/appSlice";
import axios from "axios";
import ErrorPage from "../errorPage";
import ChatBot from "../../components/chatbot";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const DocumentRepository = () => {
    const store = useSelector(selectStore);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState<ITableData[]>([]);
    const [filteredData, setFilteredData] = useState<ITableData[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const chatBotRef = useRef<HTMLDivElement>(null);
    const [isChatBotVisible, setIsChatBotVisible] = useState(false);

    const reviewContract = (record: ITableData) => {
        dispatch(setContractDetails({ name: record.contractName, id: record.id }));
        sessionStorage.setItem("contractId", record.id.toString());
        sessionStorage.setItem("contractName", record.contractName);
        navigate("" + record.id);
      };

    const fetchData = async () => {
        try {
            const res = await axios.get(
                `https://intelligent-automation.api.demo.zsservices.com/contracts/${store.userDetails.username}/${store.selectedContract.id}`
            );
            if (Array.isArray(res.data)) {
                setTableData(res.data);
                filterAndSortData(res.data, searchTerm, activeTab, sortOrder);
            }
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [store.selectedContract.id]);

    useEffect(() => {
        filterAndSortData(tableData, searchTerm, activeTab, sortOrder);
    }, [searchTerm, activeTab, sortOrder]);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleTabChange = (key: string) => {
        setActiveTab(parseInt(key));
    };

    const filterAndSortData = (
        data: ITableData[],
        searchTerm: string,
        activeTab: number,
        sortOrder: "asc" | "desc"
    ) => {
        let filtered = data;

        if (activeTab !== 0) {
            filtered = filtered.filter((item) => item.doctypeid === activeTab);
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (item) =>
                    item.contractName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.reviewerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.contractDesc.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (sortOrder) {
            filtered.sort((a, b) =>
                sortOrder === "asc"
                    ? a.contractName.localeCompare(b.contractName)
                    : b.contractName.localeCompare(a.contractName)
            );
        }

        setFilteredData(filtered);
    };

    const renderCards = () => {
        return filteredData.length > 0 ? (
            <div className="card-container">
                {filteredData.map((record) => (
                    <Card
                  key={record.id}
                  className="card"
                  onClick={() => reviewContract(record)}
                >
                  <div className="card-top-container">
                    <div className="card-title">
                      <div>
                        {record.contractName.split(".pdf")[0]}
                      </div>
                      {/* <div className="status-container">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path></svg>                        <p>{record.status}</p>
                      </div> */}
                    
                    <div>
                      <Paragraph className='summary-attributes-container'> 
                        <Text className='summary-attributes'> <p>{record.dateUploaded}</p></Text>
                      </Paragraph>
                    </div>
                    </div>
                    {/* <Text className='summary-attributes'> <p>{record.reviewerName}</p></Text> */}
                  </div>
                  <div className="card-footer">
                    <div className="footer-left">
                      <div className="card-desc">{record.contractDesc}</div>
                    </div>
                  </div>
                </Card>
                ))}
            </div>
        ) : (
            <p>No documents found.</p>
        );
    };

    const questions = [
        "What are the latest trends in cloud computing?",
        "How can I improve cybersecurity in my organization?",
        "What are the key principles of agile development?",
        "Can you explain the concept of microservices architecture?",
        "What are the best practices for data management?"
    ];

    return (
        <>
            {loading ? (
                <div className="spinner-container">
                    <Spin size="large" />
                </div>
            ) : error ? (
                <ErrorPage />
            ) : (
                <div>
                    <div className="header-section">
                        <Title level={3} className="gen-ai-header">GEN-AI SUPPORT AGENT</Title>
                    </div>
                    <div className="mycalls-section-container">
                        <Title level={2} className="">My Documents</Title>

                        <Tabs defaultActiveKey="0" onChange={handleTabChange}>
                            <TabPane tab="All Documents" key="0" />
                            <TabPane tab="Pdfs" key="1" />
                            <TabPane tab="Tickets" key="2" />
                            <TabPane tab="Audio Files" key="3" />
                        </Tabs>

                        <div className="searchbar-sort-filter-container">
                            <div className="search-bar">
                                <Input
                                    placeholder="Search Calls"
                                    suffix={<SearchOutlined />}
                                    style={{ width: 300, marginRight: 10 }}
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                                <Button type="default" className="button-container" onClick={toggleSortOrder}>
                                    {sortOrder === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                                        <path d="M19 3L23 8H20V20H18V8H15L19 3ZM14 18V20H3V18H14ZM14 11V13H3V11H14ZM12 4V6H3V4H12Z"></path>
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                                        <path d="M20 4V16H23L19 21L15 16H18V4H20ZM12 18V20H3V18H12ZM14 11V13H3V11H14ZM14 4V6H3V4H14Z"></path>
                                        </svg>
                                    )} Sort 
                                </Button>
                            </div>
                        </div>

                        <div className="card-container">
                            {renderCards()}
                        </div>
                    </div>
                </div>
            )}
            {!isChatBotVisible && (
            <div className="chatbot-toggle-1" onClick={() => setIsChatBotVisible(!isChatBotVisible)}>
              <span className="hover-text">Smart Bot</span>
              <div className="hexagon-container">
                <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9606 7.6155C19.7479 6.92473 18.2522 6.92473 17.0395 7.6155L8.23512 12.6303C7.0267 13.3186 6.28265 14.5883 6.28265 15.962V26.0378C6.28265 27.4115 7.0267 28.6812 8.23512 29.3695L17.0395 34.3843C18.2522 35.0751 19.7479 35.0751 20.9606 34.3843L29.765 29.3695C30.9734 28.6812 31.7174 27.4115 31.7174 26.0378V15.962C31.7174 14.5883 30.9734 13.3186 29.765 12.6303L20.9606 7.6155ZM37.587 12.6326C37.587 11.2589 36.8429 9.98924 35.6345 9.30095L20.9606 0.942884C19.7479 0.252113 18.2522 0.252111 17.0395 0.942882L2.36555 9.30094C1.15714 9.98924 0.413086 11.2589 0.413086 12.6326V29.3672C0.413086 30.7409 1.15714 32.0106 2.36555 32.6989L17.0395 41.0569C18.2522 41.7477 19.7479 41.7477 20.9606 41.057L35.6345 32.6989C36.8429 32.0106 37.587 30.7409 37.587 29.3672V12.6326Z" fill="white"/>
                  <path d="M17.0435 19.7605V4.63838L32.254 11.5664C33.703 12.2264 34.5991 13.6865 34.518 15.2554L33.674 31.5868L18.9726 23.0787C17.7774 22.3871 17.0435 21.1247 17.0435 19.7605Z" fill="white"/>
                </svg>
              </div>
              </div>
          )}
          {isChatBotVisible && (
            <div ref={chatBotRef} className="chatbot">
              <ChatBot isVisible={isChatBotVisible} onClose={() => setIsChatBotVisible(false)} questions={questions} setInitialQuery="" />
            </div>
          )}
        </>
    );
};

export default DocumentRepository;
