import { Collapse, CollapseProps } from "antd";

const MyAccordion = (props: CollapseProps) => {
	return (
		<Collapse
			items={props.items}
			bordered={false}
			defaultActiveKey={["1"]}
		/>
	);
};

export default MyAccordion;
