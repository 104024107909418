import React, { useEffect, useRef, useState } from "react";
import MyButton from "../../components/buttons";
import { IChatProps } from "../../model/IChatProps";
import { dummyChatData } from "../../dummyData/chats";
import Chat from "../../components/chat";
import { selectStore } from "../../state/slice/appSlice";
import { useSelector } from "react-redux";
import MyModal from "../../components/modal";
import { Form, Space, Spin, Tooltip, Typography, message } from "antd";
import MyInput from "../../components/input";
import MyTextarea from "../../components/textarea";
import { IChatDataApiData } from "../../model/IChatDataApiData";
import axios from "axios";
import ErrorPage from "../errorPage";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import ExtractAIChat from "../../components/extractaiChat";

const AskContractsExtractAI = (props: { chatOnly?: boolean }) => {
	const store = useSelector(selectStore);
	const [chatData, setChatData] = useState<IChatProps[]>([]);
	const [loading, setLoading] = useState(true);
	const [loadingMetaData, setLoadingMetaData] = useState(true);
	const [blockSend, setBlockSend] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [error, setError] = useState(false);
	const [form] = Form.useForm();
	const [metadata, setMetaData] = useState<string[]>();
	const [allVariables, setAllVariables] = useState<string[]>([]);
	const [selectedDocSummary, setSelectedDocSummary] = useState<string>("");
	const [tableData, setTableData] = useState([]);
	const [selectedElement, setSelectedElement] = useState<any>(null);

	// const [streamData, setStreamData] = useState("");
	const streamData = useRef("");

	const transformChatData = (chatData: IChatDataApiData[]) => {
		const transformedData: IChatProps[] = [];
		chatData.map((el: IChatDataApiData) => {
			transformedData.push({
				id: el.id,
				source: el.user,
				text: el.query,
				timestamp: el.timestamp,
			});
			transformedData.push({
				id: el.id,
				source: "system",
				text: el.response,
				timestamp: el.timestamp,
			});
		});
		return transformedData;
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	};
	const onSubmit = async (e: any) => {
		const payload = {
			id: "-1",
			name: e.name,
			prompt: e.prompt,
			user_name: store.userDetails.username,
		};

		const key = "updatable";
		message.loading({ content: "Saving...", key: key });
		axios
			.post(
				process.env.REACT_APP_API_URL + "/Edit/add_variables",
				payload
			)
			.then((res) => {
				if (res.data.data) {
					fetchData();
					message.success({
						content: "Saved successfully...",
						key: key,
					});
					fetchMetaData();
				} else message.error({ content: "Error!", key: key });
			})
			.catch((e) => message.error({ content: "Error!", key: key }));
		setIsModalOpen(false);
		form.resetFields();
	};
	const handleSend2 = async (query: string) => {
		setBlockSend(true);
		setChatData([
			...chatData,
			{
				id: -1,
				source: store.userDetails.username,
				text: query,
				timestamp: new Date().toString(),
			},
		]);
		const payload = {
			doc_name: "",
			query: query,
			agent_id: props.chatOnly ? store.selectedActiveAgent.id : "",
		};
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"/document_qna/" +
					store.userDetails.username,
				payload
			)
			.then((res) => {
				if (res.data) {
					setChatData([
						...chatData,
						{
							id: -1,
							source: store.userDetails.username,
							text: query,
							timestamp: new Date().toString(),
						},
						{
							id: res.data.id,
							source: "system",
							text: res.data.response,
							timestamp: new Date().toString(),
						},
					]);
				} else setError(true);
				setBlockSend(false);
			})
			.catch((e) => setError(true));

		// const res = await axios.post(
		// 	process.env.REACT_APP_API_URL +
		// 		"/document_qna_stream/" +
		// 		store.userDetails.username,
		// 	payload,
		// 	{
		// 		responseType: "stream", // Set responseType to 'stream' to handle streaming data
		// 	}
		// );

		// // Set up event listener to handle data chunks as they arrive
		// res.data.on("data", (chunk: any) => {
		// 	// Update state with the received chunk of data
		// 	{
		// 		if (res.data) {
		// 			let tempStr = streamData;
		// 			tempStr += chunk.toString();
		// 			setStreamData((prevData) => prevData + chunk.toString());
		// 			setChatData([
		// 				...chatData,
		// 				{
		// 					id: -1,
		// 					source: store.userDetails.username,
		// 					text: query,
		// 					timestamp: new Date().toString(),
		// 				},
		// 				{
		// 					id: res.data.id,
		// 					source: "system",
		// 					text: tempStr,
		// 					timestamp: new Date().toString(),
		// 				},
		// 			]);
		// 		} else setError(true);
		// 		setBlockSend(false);
		// 	}
		// });

		// res.data.on("end", (chunk: any) => {
		// 	// Update state with the received chunk of data

		// });

		// // Handle errors
		// res.data.on("error", (error: any) => {
		// 	console.error("Error receiving data:", error);
		// });
	};

	const fetchData = () => {
		setLoading(true);
		const apiData = {
			chat: [],
		};
		const transformedData = transformChatData(apiData.chat);
		setChatData(transformedData);
		setLoading(false);
		// axios
		// 	.get(
		// 		process.env.REACT_APP_API_URL +
		// 			"/document_chat/" +
		// 			store.userDetails.username
		// 	)
		// 	.then((res: any) => {
		// 		if (res.data.chat) {
		// 			const apiData = {
		// 				chat: [
		// 					{
		// 						id: 1,
		// 						documentId: "docId1",
		// 						query: "Query1",
		// 						response: "Response1",
		// 						timestamp: "Timestamp1",
		// 						user: "ms30001",
		// 					},
		// 					{
		// 						id: 2,
		// 						documentId: "docId2",
		// 						query: "Query2",
		// 						response: "Response2",
		// 						timestamp: "Timestamp2",
		// 						user: "ms30001",
		// 					},
		// 				],
		// 			};
		// 			const transformedData = transformChatData(res.data.chat);
		// 			setChatData(transformedData);
		// 			setLoading(false);
		// 		} else setError(true);
		// 	})
		// 	.catch((e) => setError(true));
		// setTimeout(() => {
		// 	setLoading(false);
		// }, 2000);
	};
	const fetchMetaData = () => {
		setLoadingMetaData(true);
		// setChatData(dummyChatData);
		axios
			.get(
				process.env.REACT_APP_API_URL +
					"/contracts/" +
					store.userDetails.username
			)
			.then((res: any) => {
				if (res.data) {
					// setAllVariables(
					// 	res.data.allVariables.map((el: string) =>
					// 		el.toLowerCase()
					// 	)
					// );
					setTableData(res.data);
					setMetaData(res.data.map((el: any) => el.contractName));
					setLoadingMetaData(false);
				} else setError(true);
			})
			.catch((e) => setError(true));
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};
	useEffect(() => {
		fetchData();
		fetchMetaData();
	}, []);

	const setSummary = (event: any) => {
		// console.log(event.target.innerHTML);
		const doc_name = event.target.innerText;
		const filteredDoc = tableData?.filter(
			(x) => x["contractName"] == doc_name
		)[0];
		const summary = filteredDoc["docSummary"];
		setSelectedDocSummary(summary);
		setSelectedElement(doc_name);
		// console.log(filteredDoc);
	};

	const handleSend = async (query: string) => {
		setBlockSend(true);
		setChatData([
			...chatData,
			{
				id: -1,
				source: store.userDetails.username,
				text: query,
				timestamp: new Date().toString(),
			},
		]);
		const payload = JSON.stringify({
			doc_name: "",
			query: query,
			agent_id: props.chatOnly ? store.selectedActiveAgent.id : "",
		});

		await fetchEventSource(
			process.env.REACT_APP_API_URL +
				"/document_qna_stream/" +
				store.userDetails.username,

			{
				method: "POST",
				body: payload,
				headers: {
					Accept: "text/event-stream",
					"Content-Type": "application/json",
				},
				//@ts-ignore
				onopen(res: any) {
					if (res.ok && res.status === 200) {
					} else if (
						res.status >= 400 &&
						res.status < 500 &&
						res.status !== 429
					) {
						setError(true);
					}
				},
				onmessage(res: any) {
					// console.log(res.data);
					streamData.current += res.data;
					if (streamData.current) {
						return setChatData((prevChatData) => {
							if (res.data.includes("***FINAL***")) {
								res.data = res.data.replace("***FINAL***", "");
								console.log(res.data);
								const updatedChatData = [...prevChatData];
								updatedChatData[
									updatedChatData.length - 1
								].text = res.data;
								return updatedChatData;
							} else {
								const updatedChatData = [...prevChatData];
								updatedChatData[
									updatedChatData.length - 1
								].text =
									updatedChatData[updatedChatData.length - 1]
										.text + res.data;
								return updatedChatData;
							}
						});
					} else {
						return setChatData((prev) => {
							if (
								prev[prev.length - 1]?.source ===
								store.userDetails.username
							)
								return [
									...prev,
									{
										id: 1234,
										source: "system",
										text: streamData.current,
										timestamp: new Date().toString(),
									},
								];
							else {
								return prev;
							}
						});
					}
				},
				onclose() {
					setBlockSend(false);
					streamData.current = "";
				},
				onerror(err: any) {
					setError(true);
				},
			}
		);
	};

	return (
		<>
			{!error && (
				<div className="p-4 grid grid-cols-12 h-full overflow-y-auto gap-4">
					<div
						className={`${
							props.chatOnly ? "col-span-12" : "col-span-9"
						} border border-border rounded-sm`}
						id="chat-container"
					>
						<ExtractAIChat
							chats={chatData}
							blockSend={blockSend}
							loading={loading}
							handleSend={handleSend}
						/>
					</div>
					{!props.chatOnly && (
						<div className="col-span-3 border border-border p-4 rounded-sm overflow-y-auto">
							<div className="text-xl font-bold">
								Documents Repository
							</div>
							<div className="text-text-zs-light-gray text-xs">
								Please select a document from the list to view a
								summary of its contents
							</div>
							{!loadingMetaData && (
								<>
									<div className="my-0 overflow-y-auto h-80">
										{metadata?.map((el) => (
											<div
												className={`${
													selectedElement === el
														? "shadow-lg border-1 border-text-zs-orange"
														: ""
												} border border-border px-4 py-2 my-4 rounded-sm`}
												// style={{
												// 	boxShadow:
												// 		"10px 10px 5px lightgray",
												// }}
											>
												{/* {el} */}
												<Typography.Paragraph
													ellipsis={{
														rows: 1,
														expandable: false,
														symbol: "more",
														tooltip: el,
													}}
													style={{
														margin: 0,
														cursor: "pointer",
													}}
													onClick={(e) =>
														setSummary(e)
													}
												>
													{el}
												</Typography.Paragraph>
											</div>
										))}
									</div>
									{selectedDocSummary == "" ? null : (
										<>
											<div className="text-l font-bold mt-4">
												Summary
											</div>
											<div className="my-0 overflow-y-auto h-96">
												<Typography.Paragraph
													style={{
														margin: 0,
													}}
												>
													{selectedDocSummary}
												</Typography.Paragraph>
											</div>
										</>
									)}
								</>
							)}
							{loadingMetaData && (
								<div className="w-full flex items-center justify-center h-60">
									<Spin size="large" />
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{!loading && error && (
				<div className="h-screen">
					<ErrorPage />
				</div>
			)}

			<MyModal
				title={"Create New Key Value"}
				footer={null}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose
			>
				<Form layout={"vertical"} form={form} onFinish={onSubmit}>
					<Form.Item
						className="my-8"
						label="Variable Name"
						name="name"
						rules={[
							{
								required: true,
								message: "Please enter variable name!",
							},
							{
								validator: (_, value) => {
									if (
										allVariables.includes(
											value.toLowerCase()
										)
									) {
										return Promise.reject(
											"Meta data name already exists"
										);
									}
									return Promise.resolve();
								},
								message: "Meta data name already exists.",
							},
						]}
					>
						<MyInput
							placeholder="Enter name"
							className="font-normal text-base"
						/>
					</Form.Item>

					<Form.Item
						className="my-8"
						label="Variable Prompt"
						name="prompt"
						rules={[
							{
								required: true,
								message: "Please enter variable prompt!",
							},
						]}
					>
						<MyTextarea
							placeholder="Enter prompt"
							className="font-normal text-base"
							rows={4}
						/>
					</Form.Item>
					<div className="w-full flex justify-end">
						<MyButton
							style={{ marginRight: "0.5rem" }}
							type="default"
							size="large"
							onClick={(e) => {
								e.preventDefault();
								handleCancel();
							}}
						>
							Cancel
						</MyButton>
						<MyButton type="primary" size="large" htmlType="submit">
							Add
						</MyButton>
					</div>
				</Form>
			</MyModal>
		</>
	);
};

export default AskContractsExtractAI;
