import React, { useEffect, useRef, useState } from "react";
import Chat from "../../components/chat";
import { dummyChatData } from "../../dummyData/chats";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import { IChatProps } from "../../model/IChatProps";
import axios from "axios";
import ErrorPage from "../errorPage";
import { IChatDataApiData } from "../../model/IChatDataApiData";
import { fetchEventSource } from "@microsoft/fetch-event-source";

const FormExtractorContractReviewDetailsChat = () => {
	const store = useSelector(selectStore);
	const [data, setData] = useState<IChatProps[]>([]);
	const [loading, setLoading] = useState(true);
	const [blockSend, setBlockSend] = useState(false);
	const [error, setError] = useState(false);
	const streamData = useRef("");
	const transformChatData = (chatData: IChatDataApiData[]) => {
		const transformedData: IChatProps[] = [];
		chatData.map((el: IChatDataApiData) => {
			transformedData.push({
				id: el.id,
				source: el.user,
				text: el.query,
				timestamp: el.timestamp,
			});
			transformedData.push({
				id: el.id,
				source: "system",
				text: el.response,
				timestamp: el.timestamp,
			});
		});
		return transformedData;
	};
	const fetchData = () => {
		setLoading(true);
		const apiData = {
			chat: [],
		};
		const transformedData = transformChatData(apiData.chat);
		setData(transformedData);
		setLoading(false);

		// axios
		// 	.get(
		// 		process.env.REACT_APP_API_URL +
		// 			"/document_chat/" +
		// 			store.userDetails.username
		// 	)
		// 	.then((res: any) => {
		// 		if (res.data.chat) {
		// 			const apiData = {
		// 				chat: [
		// 					{
		// 						id: 1,
		// 						documentId: "docId1",
		// 						query: "Query1",
		// 						response: "Response1",
		// 						timestamp: "Timestamp1",
		// 						user: "ms30001",
		// 					},
		// 					{
		// 						id: 2,
		// 						documentId: "docId2",
		// 						query: "Query2",
		// 						response: "Response2",
		// 						timestamp: "Timestamp2",
		// 						user: "ms30001",
		// 					},
		// 				],
		// 			};
		// 			const transformedData = transformChatData(res.data.chat);
		// 			setData(transformedData);
		// 			setLoading(false);
		// 		} else setError(true);
		// 	})
		// 	.catch((e) => setError(true));
	};
	useEffect(() => {
		fetchData();
	}, []);
	const handleSend = async (query: string) => {
		setBlockSend(true);
		setData([
			...data,
			{
				id: -1,
				source: store.userDetails.username,
				text: query,
				timestamp: new Date().toString(),
			},
		]);
		const payload = JSON.stringify({
			doc_name: store.selectedContract.name,
			query: query,
		});
		await fetchEventSource(
			process.env.REACT_APP_API_URL +
				"/document_qna_stream/" +
				store.userDetails.username,

			{
				method: "POST",
				body: payload,
				headers: {
					Accept: "text/event-stream",
					"Content-Type": "application/json",
				},
				//@ts-ignore
				onopen(res: any) {
					if (res.ok && res.status === 200) {
					} else if (
						res.status >= 400 &&
						res.status < 500 &&
						res.status !== 429
					) {
						setError(true);
					}
				},
				onmessage(res: any) {
					streamData.current += res.data;

					if (streamData.current) {
						return setData((prevChatData) => {
							const updatedChatData = [...prevChatData];
							updatedChatData[updatedChatData.length - 1].text =
								updatedChatData[updatedChatData.length - 1]
									.text + res.data;
							return updatedChatData;
						});
					} else {
						return setData((prev) => {
							if (
								prev[prev.length - 1]?.source ===
								store.userDetails.username
							)
								return [
									...prev,
									{
										id: 1234,
										source: "system",
										text: streamData.current,
										timestamp: new Date().toString(),
									},
								];
							else {
								return prev;
							}
						});
					}
				},
				onclose() {
					setBlockSend(false);
					streamData.current = "";
				},
				onerror(err: any) {
					setError(true);
				},
			}
		);
	};
	return (
		<div className="h-full" id="chat-container">
			{!error ? (
				<Chat
					chats={data}
					handleSend={handleSend}
					loading={loading}
					blockSend={blockSend}
				/>
			) : (
				<ErrorPage />
			)}
		</div>
	);
};

export default FormExtractorContractReviewDetailsChat;
