import React, { useState, useEffect } from "react";
import { Table, Input, message, Select, Tooltip, Tag, Modal, Form, Input as AntInput, Button, Spin } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import { SaveOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import './index.css'; // Import the CSS file

interface UserAccess {
  full_name: string;
  password: string;
  is_active: boolean;
  user_name: string;
  accss_typ: number;
  ddn_access: boolean;
}

const { Option } = Select;

const AccessManagement: React.FC = () => {
  const store = useSelector(selectStore);
  const [data, setData] = useState<UserAccess[]>([]);
  const [filteredData, setFilteredData] = useState<UserAccess[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [changedAccessTypes, setChangedAccessTypes] = useState<Map<string, number>>(new Map());
  const [hasAdminAccess, setHasAdminAccess] = useState<boolean>(false);
  const [userBatches, setUserBatches] = useState<Map<string, any[]>>(new Map());
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [batchOptions, setBatchOptions] = useState<any[]>([]);
  const [editingUser, setEditingUser] = useState<string | null>(null);
  const [editingBatches, setEditingBatches] = useState<Map<string, number[]>>(new Map());
  const [isSaving, setIsSaving] = useState<boolean>(false); // To handle spinner visibility
  const [pagination, setPagination] = useState<{ current: number; pageSize: number }>({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async (page: number = 1, pageSize: number = 10) => {
      setLoading(true);
      try {
        // Fetch user access data
        const response = await axios.get("https://intelligent-automation.api.demo.zsservices.com/view_user_access");
        const result = response.data;
    
        if (result.status === 200 && Array.isArray(result.data)) {
          // Paginate the data
          const start = (page - 1) * pageSize;
          const end = start + pageSize;
          const paginatedData = result.data.slice(start, end);
          setData(result.data);
          setFilteredData(paginatedData);
          setPagination(prev => ({ ...prev, total: result.data.length }));
          setInitialLoadComplete(true);
    
          // Fetch batches for each user in the background
          const batchesMap = new Map<string, any[]>();
          const batchPromises = result.data.map(async (user: UserAccess) => {
            const batchResponse = await axios.get(`https://intelligent-automation.api.demo.zsservices.com/show_user_batches/${user.user_name}`);
            if (batchResponse.data.status === 200) {
              batchesMap.set(user.user_name, batchResponse.data.data);
            }
          });
    
          // Wait for all batch fetches to complete
          await Promise.all(batchPromises);
          setUserBatches(batchesMap);
    
          // Check if the current user has admin access
          const currentUserName = store.userDetails.username;
          const currentUser = result.data.find((user: UserAccess) => user.user_name === currentUserName);
    
          if (currentUser && currentUser.accss_typ === 2) { // Assuming '2' represents admin
            setHasAdminAccess(true);
          } else {
            setHasAdminAccess(false);
            // message.error("You do not have permission to perform this task.");
          }
        } else {
          console.error("Unexpected response format or status", result);
          message.error("Unexpected response format or status");
        }
      } catch (error) {
        console.error("Failed to load data", error);
        message.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    };    
  
    fetchData(pagination.current, pagination.pageSize);
  }, [store.userDetails.username, pagination.current, pagination.pageSize]);     

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    if (value) {
      const lowercasedValue = value.toLowerCase();
      const filtered = data.filter(item =>
        item.full_name.toLowerCase().includes(lowercasedValue) ||
        item.user_name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };


  const filterData = (data: any) => (formatter: any) => {
    const uniqueValues = new Set();
    return data
      .filter((item: any) => {
        const formattedValue = formatter(item);
        if (!uniqueValues.has(formattedValue)) {
          uniqueValues.add(formattedValue);
          return true;
        }
        return false;
      })
      .map((item: any) => ({
        text: formatter(item),
        value: formatter(item),
      }));
  };

  const handleChangeAccessType = (value: number, record: UserAccess) => {
    setChangedAccessTypes(prev => new Map(prev).set(record.user_name, value));
    const updatedData = data.map(item =>
      item.user_name === record.user_name ? { ...item, accss_typ: value } : item
    );
    setData(updatedData);
    setFilteredData(updatedData);
  };

  const handleSaveChange = async (userName: string) => {
    const accss_typ = changedAccessTypes.get(userName);
    const batch_ids = editingBatches.get(userName) || [];
  
    try {
      if (accss_typ !== undefined) {
        // Update user access
        const accessPayload = {
          user_name: userName,
          access_lvl:accss_typ,
        };
  
        await axios.post("https://intelligent-automation.api.demo.zsservices.com/edit_user_access", accessPayload);
      }
  
      // Update user batches
      if (batch_ids.length > 0) {
        const batchPayload = {
          user_name: userName,
          batch_ids,
        };
  
        await axios.post("https://intelligent-automation.api.demo.zsservices.com/update_user_batches", batchPayload);
      }
  
      // Clear the changed access types and editing batches
      setChangedAccessTypes(prev => {
        const newMap = new Map(prev);
        newMap.delete(userName);
        return newMap;
      });
  
      setEditingBatches(prev => {
        const newMap = new Map(prev);
        newMap.delete(userName);
        return newMap;
      });
  
      message.success("Changes saved successfully");
  
      window.location.reload();
    } catch (error) {
      message.error("Failed to save changes");
    }
  };   

  const handleAddUser = async (values: any) => {
    try {
      const payload = {
        user_name: values.user_name,
        full_name: values.full_name,
        password: values.password,
        access_type: values.access_type,
        batch_ids: values.batch_ids || [], 
      };
  
      await axios.post("https://intelligent-automation.api.demo.zsservices.com/add_new_user", payload);
      message.success("User added successfully");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Failed to add user", error);
      message.error("Failed to add user");
    }
  };  

  useEffect(() => {
    const fetchBatchOptions = async () => {
      try {
        const response = await axios.get("https://intelligent-automation.api.demo.zsservices.com/show_all_batches");
        const result = response.data;
  
        if (result && Array.isArray(result.data)) {
          setBatchOptions(result.data);
        } else {
          message.error("Failed to load batch options");
        }
      } catch (error) {
        console.error("Error fetching batch options", error);
        message.error("Failed to load batch options");
      }
    };
  
    fetchBatchOptions();
  }, []);

  const handleBatchChange = (selectedBatchIds: number[], userName: string) => {
    setEditingBatches(prev => new Map(prev).set(userName, selectedBatchIds));
    // You might want to save changes after updating batches.
    // Uncomment the following line if you want to save changes immediately.
    // handleSaveChange(userName); 
  };

  const handleCancelEdit = (userName: string) => {
    // Revert changes for the user
    setEditingBatches(prev => {
      const newMap = new Map(prev);
      newMap.delete(userName);
      return newMap;
    });
    setChangedAccessTypes(prev => {
      const newMap = new Map(prev);
      newMap.delete(userName);
      return newMap;
    });
    setEditingUser(null); // Exit edit mode
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPagination({ current: page, pageSize });
  };
  
  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      // filters: filterData(data)((item: any) => item.user_name),
      // onFilter: (value: any, record: any) => record.user_name === value,
      sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
      // filterSearch: true,
    },
    {
      title: "Username",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Access Type",
      dataIndex: "accss_typ",
      key: "accss_typ",
      // filters: filterData(data)((item: any) => item.accss_typ),
      // onFilter: (value: any, record: any) => record.accss_typ === value,
      // filterSearch: true,
      render: (text: number, record: UserAccess) => (
        editingUser === record.user_name ? (
          <Select
            value={text}
            onChange={(value) => handleChangeAccessType(value, record)}
            style={{ width: "120px" }}
          >
            <Option value={1}>User</Option>
            <Option value={2}>Admin</Option>
          </Select>
        ) : (
          <span>{text === 1 ? "User" : "Admin"}</span>
        )
      ),
    },
    {
      title: "Batches",
      dataIndex: "user_name",
      key: "batches",
      render: (username: string) => {
        const batches = userBatches.get(username) || [];
        return editingUser === username ? (
          <Select
            mode="multiple"
            placeholder="Select batches"
            onChange={(value) => handleBatchChange(value, username)}
            defaultValue={batches.map(batch => batch.batch_id)}
            style={{ width: "300px" }}
          >
            {batchOptions.map((batch) => (
              <Option key={batch.batch_id} value={batch.batch_id}>
                {batch.batch_name}
              </Option>
            ))}
          </Select>
        ) : (
          <div style={{ width: "300px", display: "flex", gap:"10px", flexWrap: "wrap" }}>
            {batches.map(batch => (
              <Tag style={{backgroundColor: "inherit !important", color: "slategray", border: "1px solid slategray" }} key={batch.batch_id}>{batch.batch_name}</Tag>
            ))}
          </div>
        );
      },
    },        
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: UserAccess) => (
        editingUser === record.user_name ? (
          <div className="save-cancel-icon-container" style={{display:"flex", gap:"10px"}}>
            <Tooltip title="Save Changes">
              <Button
                icon={isSaving ? <Spin /> : <SaveOutlined />}
                onClick={() => handleSaveChange(record.user_name)}
                loading={isSaving}
                disabled={isSaving}
                style={{border: "none !important"}}
              />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button
                icon={<CloseOutlined />}
                onClick={() => handleCancelEdit(record.user_name)}
              />
            </Tooltip>
          </div>
        ) : (
          <Button
            icon={<EditOutlined />}
            onClick={() => setEditingUser(record.user_name)}
          />
        )
      ),
    },   
  ];


  return (
    <div className="access-management-container">
      <div style={{position:"sticky", top: 0, padding: "20px", zIndex: "100", "backgroundColor": "inherit", paddingBottom: "10px"}}>
        <h1>User Access Information</h1>
        <div className="search-container">
          <Input
            placeholder="Search by Full Name or Username"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add new user
          </Button>
        </div>
      </div>
      {loading && !initialLoadComplete ? (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      ) : hasAdminAccess ? (
        <Table
          className="users-table"
          columns={columns}
          dataSource={filteredData}
          loading={loading && initialLoadComplete}
          rowKey="user_name"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
          // style={{fontSize: "14px"}}
          // scroll={{ x: 'max-content' }} 
        />
      ) : !loading ? (
        <p>You do not have permission to perform this task.</p>
      ) : null}
      <Modal
        title="Add New User"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddUser} layout="vertical">
          <Form.Item
            name="user_name"
            label="Username"
            rules={[{ required: true, message: "Please enter the username" }]}
          >
            <AntInput />
          </Form.Item>
          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[{ required: true, message: "Please enter the full name" }]}
          >
            <AntInput />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter the password" }]}
          >
            <AntInput.Password />
          </Form.Item>
          <Form.Item
            name="access_type"
            label="Access Type"
            rules={[{ required: true, message: "Please select the access type" }]}
          >
            <Select>
              <Option value={1}>User</Option>
              <Option value={2}>Admin</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="batch_ids"
            label="Batch IDs"
            rules={[{ required: true, message: 'Please select at least one batch' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select batches"
              options={batchOptions.map(batch => ({ label: batch.batch_name, value: batch.batch_id }))}
            />
          </Form.Item>
          <Form.Item>
            <Button className="add-user-button" type="primary" htmlType="submit">
              Add User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AccessManagement;
