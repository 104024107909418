import React, { useEffect, useRef, useState } from "react";
import MyButton from "../../components/buttons";
import { IChatProps } from "../../model/IChatProps";
import { dummyChatData } from "../../dummyData/chats";
import Chat from "../../components/chat";
import { selectStore } from "../../state/slice/appSlice";
import { useSelector } from "react-redux";
import MyModal from "../../components/modal";
import { Form, Space, Spin, Typography, message } from "antd";
import MyInput from "../../components/input";
import MyTextarea from "../../components/textarea";
import { IChatDataApiData } from "../../model/IChatDataApiData";
import axios from "axios";
import ErrorPage from "../errorPage";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import ExtractAIChat from "../../components/extractaiChatTrainAssist";
import { Console } from "console";
import "./index.css";

interface AdditionalData {
    doc_name: string;
    text: string;
}

interface YourComponentProps {
    showAdditionalContent: boolean;
    additionalDataJson: AdditionalData[];
}

const TrainAssistsAI = (props: { chatOnly?: boolean }) => {
    const store = useSelector(selectStore);
    const [chatData, setChatData] = useState<IChatProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingMetaData, setLoadingMetaData] = useState(true);
    const [blockSend, setBlockSend] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [form] = Form.useForm();
    const [metadata, setMetaData] = useState<string[]>();
    const [allVariables, setAllVariables] = useState<string[]>([]);
    const [selectedDocSummary, setSelectedDocSummary] = useState<string>("");
    const [tableData, setTableData] = useState([]);
    const [selectedElement, setSelectedElement] = useState<any>(null);
    const [additionalContent, setAdditionalContent] = useState<string>("");
    const [additionalDataJson, setAdditionalDataJson] = useState<AdditionalData[] | null>(null);
    const [conversationAdditionalData, setConversationAdditionalData] = useState<string[]>([]);
    const [displayedAdditionalContent, setDisplayedAdditionalContent] = useState<string | null>(null);
    const [showAdditionalContent, setShowAdditionalContent] = useState(false); // State to control when to show additional content

    const [selectedDocIndex, setSelectedDocIndex] = useState<number | null>(null);

    const handleTileClick = (index: number) => {
        setSelectedDocIndex(selectedDocIndex === index ? null : index);
    };

    const streamData = useRef("");

    const transformChatData = (chatData: IChatDataApiData[]) => {
        const transformedData: IChatProps[] = [];
        chatData.map((el: IChatDataApiData) => {
            transformedData.push({
                id: el.id,
                source: el.user,
                text: el.query,
                timestamp: el.timestamp,
            });
            transformedData.push({
                id: el.id,
                source: "system",
                text: el.response,
                timestamp: el.timestamp,
            });
        });
        return transformedData;
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const onSubmit = async (e: any) => {
        const payload = {
            id: "-1",
            name: e.name,
            prompt: e.prompt,
            user_name: store.userDetails.username,
        };

        const key = "updatable";
        message.loading({ content: "Saving...", key: key });
        axios
            .post(
                process.env.REACT_APP_API_URL + "/Edit/add_variables",
                payload
            )
            .then((res) => {
                if (res.data.data) {
                    fetchData();
                    message.success({
                        content: "Saved successfully...",
                        key: key,
                    });
                    fetchMetaData();
                } else message.error({ content: "Error!", key: key });
            })
            .catch((e) => message.error({ content: "Error!", key: key }));
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleSend = async (query: string) => {
        setBlockSend(true);
        setChatData([
            ...chatData,
            {
                id: -1,
                source: store.userDetails.username,
                text: query,
                timestamp: new Date().toString(),
            },
        ]);
    
        const payload = {
            doc_name: "",
            query: query,
            agent_id: store.selectedActiveAgent.id,
        };
    
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/document_qna_stream/${store.userDetails.username}`,
                payload,
                {
                    responseType: 'json', // Ensure response is parsed as JSON
                }
            );
    
            const responseData = res.data;
    
            // Assuming responseData contains a 'response' and 'context' structure
            const { response, context } = responseData;
    
            setChatData([
                ...chatData,
                {
                    id: -1,
                    source: store.userDetails.username,
                    text: query,
                    timestamp: new Date().toString(),
                },
                {
                    id: -1,
                    source: "system",
                    text: response,
                    timestamp: new Date().toString(),
                },
            ]);
    
            if (context) {
                if (Array.isArray(context)) {
                    const additionalData = context.map(item => ({
                        doc_name: item.doc_name,
                        text: item.text.join(' ') // Joining text array into a single string
                    }));
                    setAdditionalDataJson(additionalData);
                    setShowAdditionalContent(true);
                    setDisplayedAdditionalContent(JSON.stringify(context)); // Displaying raw additional data for now
                } else {
                    console.error("Invalid context data format:", context);
                }
            } else {
                setShowAdditionalContent(false);
                setDisplayedAdditionalContent(null);
            }
        } catch (error) {
            console.error("Error sending query:", error);
            setError(true);
        } finally {
            setBlockSend(false);
        }
    };
    

    const fetchData = () => {
        setLoading(true);
        const apiData = {
            chat: [],
        };
        const transformedData = transformChatData(apiData.chat);
        setChatData(transformedData);
        setLoading(false);
    };

    const fetchMetaData = () => {
        setLoadingMetaData(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/contracts/${store.userDetails.username}`)
            .then((res) => {
                if (res.data) {
                    setTableData(res.data);
                    setMetaData(res.data.map((el: any) => el.contractName));
                    setLoadingMetaData(false);
                } else setError(true);
            })
            .catch((e) => setError(true));
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    useEffect(() => {
        fetchData();
        fetchMetaData();
    }, []);

    const setSummary = (event: React.MouseEvent<HTMLDivElement>) => {
        const doc_name = (event.target as HTMLElement).innerText;
        const filteredDoc = tableData?.filter(
            (x: any) => x["contractName"] === doc_name
        )[0];
        const summary = filteredDoc["docSummary"];
        setSelectedDocSummary(summary);
        setSelectedElement(doc_name);
    };

    const handleChatClick = (contentIndex: number) => {
        setDisplayedAdditionalContent(conversationAdditionalData[contentIndex]);
    };

    return (
        <>
            {!error && (
                <div className="p-4 grid grid-cols-12 h-full overflow-y-auto gap-4">
                    <div
                        className={`${
                            props.chatOnly ? "col-span-12" : "col-span-9"
                        } border border-border rounded-sm`}
                        id="chat-container"
                    >
                        <ExtractAIChat
                            chats={chatData}
                            blockSend={blockSend}
                            loading={loading}
                            handleSend={handleSend}
                            handleChatClick={handleChatClick}
                            conversationAdditionalData={conversationAdditionalData}
                        />
                        {loading && (
                            <div className="text-center mt-4">
                                <Spin size="large" />
                            </div>
                        )}
                    </div>
                    {!props.chatOnly && (
                        <div className="col-span-3 border border-border p-4 rounded-sm overflow-y-auto">
                            <div className="header-citation text-xl font-bold">
                                Citations
                            </div>
                            <br />
                            {showAdditionalContent && additionalDataJson && (
                            <div className="additional-content">
                                <div className="additional-content-json" style={{ overflowX: 'auto' }}>
                                    {additionalDataJson.map((item, index) => (
                                        <div className="reference-docs" key={index}>
                                            <div
                                                className="click-paragraph"
                                                onClick={() => handleTileClick(index)}
                                            >
                                                {item.doc_name}
                                            </div>
                                            {selectedDocIndex === index && (
                                                <div className="expanded-content">
                                                    <Typography.Paragraph>
                                                        {item.text}
                                                    </Typography.Paragraph>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {loadingMetaData && (
                                    <div className="text-center mt-4">
                                        <Spin size="large" />
                                    </div>
                                )}
                            </div>
                        )}

                        </div>
                    )}
                </div>
            )}
            {!loading && error && (
                <div className="h-screen">
                    <ErrorPage />
                </div>
            )}
            <MyModal
                title={"Create New Key Value"}
                footer={null}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
            >
                <Form layout={"vertical"} form={form} onFinish={onSubmit}>
                    <Form.Item
                        className="my-8"
                        label="Variable Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter variable name!",
                            },
                            {
                                validator: (_, value) => {
                                    if (allVariables.includes(value.toLowerCase())) {
                                        return Promise.reject("Meta data name already exists");
                                    }
                                    return Promise.resolve();
                                },
                                message: "Meta data name already exists.",
                            },
                        ]}
                    >
                        <MyInput
                            placeholder="Enter name"
                            className="font-normal text-base"
                        />
                    </Form.Item>
                    <Form.Item
                        className="my-8"
                        label="Variable Prompt"
                        name="prompt"
                        rules={[
                            {
                                required: true,
                                message: "Please enter variable prompt!",
                            },
                        ]}
                    >
                        <MyTextarea
                            placeholder="Enter prompt"
                            className="font-normal text-base"
                            rows={4}
                        />
                    </Form.Item>
                    <div className="w-full flex justify-end">
                        <MyButton
                            style={{ marginRight: "0.5rem" }}
                            type="default"
                            size="large"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCancel();
                            }}
                        >
                            Cancel
                        </MyButton>
                        <MyButton
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            Add
                        </MyButton>
                    </div>
                </Form>
            </MyModal>
        </>
    );
}

export default TrainAssistsAI;
