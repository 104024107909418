import { Breadcrumb, Spin, Button, Table, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setContractDetails } from "../../state/slice/appSlice";
import PdfView from "../../components/pdfView";
import axios from "axios";
import * as XLSX from "xlsx";
import { DownloadOutlined } from '@ant-design/icons';

type WorksheetData = { [key: string]: any }[];

const DocumentViewer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const store = useSelector(selectStore);

	const [loading, setLoading] = useState(true);
	const [presignedUrl, setPresignedUrl] = useState<string>("");
	const [data, setData] = useState<WorksheetData | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);

	const fetchData = async () => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/pre_signed_url/${store.selectedContract.name}/${store.userDetails.username}`
			);

			if (res.data) {
				setPresignedUrl(res.data);

				if (store.selectedContract.name.toUpperCase().includes(".XLSX") || 
				    store.selectedContract.name.toUpperCase().includes(".CSV")) {
					const response = await axios.get(res.data, {
						responseType: "arraybuffer",
					});
					const workbook = XLSX.read(new Uint8Array(response.data), {
						type: "array",
					});
					const sheetName = workbook.SheetNames[0];
					const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: "" });
					setData(worksheet as WorksheetData);
				}
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching presigned URL:", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (store.selectedContract.name) fetchData();
		if (store.selectedContract.id === -1)
			dispatch(
				setContractDetails({
					name: sessionStorage.getItem("contractName") || "",
					id: Number(sessionStorage.getItem("contractId")) || -1,
				})
			);
	}, [store.selectedContract.name]);

	const handleDownload = () => {
		if (presignedUrl) {
			const link = document.createElement("a");
			link.href = presignedUrl;
			link.download = store.selectedContract.name;
			link.click();
		}
	};

	const headerKeys = data ? Object.keys(data[0]) : [];
	const currentData = data ? data.slice((currentPage - 1) * pageSize, currentPage * pageSize) : [];

	const columns = headerKeys.map((key) => ({
		title: key,
		dataIndex: key,
		key,
	}));

	const onPageChange = (page: number) => {
		setCurrentPage(page);
	};

	return (
		<Spin spinning={loading}>
			<div className="h-screen overflow-hidden p-4">
				<div 
					className="breadcrumb-button-container"
					style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
					<Breadcrumb
						separator=">"
						items={[
							{
								title: "Document Repository",
								className: "cursor-pointer",
								onClick: () => navigate("./.."),
							},
							{
								title: store.selectedContract.name.split(".")[0],
							},
						]}
					/>
					<Button
							type="primary"
							icon={<DownloadOutlined />}
							onClick={handleDownload}
						>
							Download
					</Button>
				</div>
				<div className="h-full relative">
					<div className="Example__container__document mt-12">
						{store.selectedContract.name.toUpperCase().includes(".PDF") ? (
							<PdfView presignedUrl={presignedUrl} />
						) : store.selectedContract.name.toUpperCase().includes(".XLSX") ||
						  store.selectedContract.name.toUpperCase().includes(".CSV") ? (
							data ? (
								<div className="table-container" style={{ overflowX: 'auto', maxHeight: '80vh' }}>
									<Table
										columns={columns}
										dataSource={currentData}
										pagination={false}
										rowKey={(record, index) => (index !== undefined ? index.toString() : record.id?.toString() || Math.random().toString())}
										style={{ marginBottom: '16px' }}
									/>
									<Pagination
										current={currentPage}
										pageSize={pageSize}
										total={data.length}
										onChange={onPageChange}
										style={{ textAlign: 'center' }}
									/>
								</div>
							) : (
								<p>Loading spreadsheet...</p>
							)
						) : store.selectedContract.name.toUpperCase().includes(".WAV") ? (
							<audio
								controls
								src={presignedUrl}
								style={{ width: "100%" }}
								className="audio-player-1"
							/>
						) : (
							"Document Not Found"
						)}
					</div>
				</div>
			</div>
		</Spin>
	);
};

export default DocumentViewer;
