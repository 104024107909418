import React, { useState, useEffect } from 'react';
import { Drawer, Button, Input, Form, message, Spin } from 'antd';
import { useSelector } from "react-redux";
import './index.css';
import { selectStore } from "../../state/slice/appSlice";

interface EditQuestionSliderProps {
  id: number;
  initialQuestion: string;
  initialAnswer: string;
  visible: boolean;
  onClose: () => void;
  qaDetails: { query_type: string; query: string; start_time: string };
}

const EditQuestionSlider: React.FC<EditQuestionSliderProps> = ({
  id,
  initialQuestion,
  initialAnswer,
  visible,
  onClose,
  qaDetails,
}) => {
  const store = useSelector(selectStore);
  const [question, setQuestion] = useState<string>(initialQuestion);
  const [answer, setAnswer] = useState<string>(initialAnswer);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  useEffect(() => {
    if (visible) {
      setQuestion(initialQuestion);
      setAnswer(initialAnswer);
    }
  }, [visible, initialQuestion, initialAnswer]);

  const payload = JSON.stringify({
    doc_name: "",
    query: question,
    agent_id: 10,
  });

  const handleUpdateQuestion = async () => {
    setLoadingQuestion(true);
    try {
      // Step 1: Make the API call to /qna
      const qnaResponse = await fetch("https://intelligent-automation.api.demo.zsservices.com/document_qna_stream/" + store.userDetails.username, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
      });

      if (!qnaResponse.ok) {
        throw new Error('Failed to fetch QnA response');
      }

      // const qnaData = await qnaResponse.json();
      // console.log('QnA Response:', qnaData);

      const stripHtmlTags = (html: string): string => {
        return html.replace(/<\/?[^>]+>/gi, ''); // Matches HTML tags and removes them
      };


      const qnaData = await qnaResponse.text(); // Assuming the response is in text format
      const finalText = qnaData.split('***FINAL***')[1]?.split('data:')[0]?.trim() || '';
      const plainText = stripHtmlTags(finalText);

      console.log("PLain Text",plainText);
      console.log("Final Text",finalText);
      
      const contextText = qnaData.split('$$$context$$$')[1]?.trim();

      const contextJson = JSON.parse(contextText);
      const { context, context_excel } = contextJson;

      const processedContext = context && context.length > 0 ? context : "NA";
      const processedContextExcel = context_excel && context_excel.length > 0 ? context_excel : null;
      
      const wrappedContextExcel = processedContextExcel ? JSON.stringify([processedContextExcel]) : null;
      // const wrapped_context_excel = JSON.stringify([context_excel])

      // Step 2: Use the response from /qna in the payload for /edit_query_ques
      const editQueryResponse = await fetch('https://intelligent-automation.api.demo.zsservices.com/edit_query_ques', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query_id: id,
          original_query: qaDetails.query,
          updated_query: question,
          query_type: qaDetails.query_type,
          start_time: qaDetails.start_time,
          updated_response: plainText,
          new_doc_names:processedContext,
          new_context_tickets:wrappedContextExcel,
        }),
      });

      if (!editQueryResponse.ok) {
        throw new Error('Failed to update question');
      }

      message.success('Question updated successfully!');
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`);
    } finally {
      setLoadingQuestion(false);
    }
  };

  const handleUpdateAnswer = async () => {
    setLoadingAnswer(true);
    try {
      const response = await fetch('https://intelligent-automation.api.demo.zsservices.com/edit_query_response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, value:answer }),
      });

      if (!response.ok) {
        throw new Error('Failed to update answer');
      }

      const data = await response.json();
      message.success('Answer updated successfully!');
    } catch (error) {
      message.error(`Error: ${(error as Error).message}`);
    } finally {
      setLoadingAnswer(false);
    }
  };

  return (
    <Drawer
      title="Edit Question & Response"
      placement="right"
      onClose={onClose}
      open={visible}
      className="audio-transcript-drawer"
      width={500}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button
            type="default"
            onClick={onClose}
            className="close-action-button"
          >
            Close
          </Button>
        </div>
      }
    >
      <Spin spinning={loadingQuestion || loadingAnswer} tip="Updating...">
      <Form layout="vertical">
        <label className="form-header">
          <p>Question</p>
        </label>
        <Input.TextArea
          className="question-textarea"
          autoSize={{ minRows: 1 }}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <button className="edit-question-answer-button" onClick={handleUpdateQuestion}>
          Update Question
        </button>

        <label className="form-header">
          <p>Response</p>
        </label>
        <Input.TextArea
          className="question-textarea"
          autoSize={{ minRows: 1 }}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <button className="edit-question-answer-button" onClick={handleUpdateAnswer}>
          Update Response
        </button>

        <button className='view-change-log-button'>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 0.00100708C3.13437 0.00100708 0 3.13538 0 7.00101C0 10.8666 3.13437 14.001 7 14.001C10.8656 14.001 14 10.8666 14 7.00101C14 3.13538 10.8656 0.00100708 7 0.00100708ZM7 12.8135C3.79063 12.8135 1.1875 10.2104 1.1875 7.00101C1.1875 3.79163 3.79063 1.18851 7 1.18851C10.2094 1.18851 12.8125 3.79163 12.8125 7.00101C12.8125 10.2104 10.2094 12.8135 7 12.8135Z" fill="#27A6A4"/>
          <path d="M9.72981 8.97767L7.50168 7.36673V3.49954C7.50168 3.43079 7.44543 3.37454 7.37668 3.37454H6.62512C6.55637 3.37454 6.50012 3.43079 6.50012 3.49954V7.80267C6.50012 7.84329 6.51887 7.88079 6.55168 7.90423L9.13606 9.7886C9.19231 9.82923 9.27043 9.81673 9.31106 9.76204L9.75793 9.15267C9.79856 9.09486 9.78606 9.01673 9.72981 8.97767Z" fill="#27A6A4"/>
          </svg>
          <p>View Change Log</p>
        </button>
      </Form>
      </Spin>
    </Drawer>
  );
};

export default EditQuestionSlider;
