import { CollapseProps, Spin, message, theme } from "antd";
import MyAccordion from "../../components/accordion";
// import { formExtractionData as dummyData } from "../../dummyData/formExtraction";
import { Children, useEffect, useState } from "react";
import MyInput from "../../components/input";
import MyDropdown from "../../components/dropdown";
import MyButton from "../../components/buttons";
import { Undo } from "@styled-icons/material-rounded/Undo";
import { Save } from "@styled-icons/bootstrap/Save";
import { SaveArrowRight } from "@styled-icons/fluentui-system-regular/SaveArrowRight";
import { IFormExtractionApiData } from "../../model/IFormExtractionApiData";
import axios from "axios";
import myMessages from "../../components/messages";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
const FormExtractContractReviewDetailsExtract = () => {
	const state = useSelector(selectStore);
	const navigate = useNavigate();
	const [accordionData, setAccordionData] = useState<any>();
	const [dataSource, setDataSource] = useState<IFormExtractionApiData>();
	const [dataSourceCopy, setDataSourceCopy] =
		useState<IFormExtractionApiData>();
	const [loading, setLoading] = useState(true);
	const [changed, setChanged] = useState(false);
	const [saveData, setSaveData] =
		useState<IFormExtractionApiData["extractedDetails"][0]["details"]>();
	const panelStyle: React.CSSProperties = {
		background: "white",
		borderRadius: 0,
		border: "none",
	};
	const createAccordionData = () => {
		const items: CollapseProps["items"] = [];
		dataSource?.extractedDetails.map((section, index) => {
			const tempSection = {
				key: section.id,
				label: section.section,
				style: panelStyle,
				children: (
					<div className="">
						<div
							className="px-4 py-2 grid grid-cols-2 gap-4"
							style={{ background: "#fcf2eb" }}
						>
							{section.details
								.sort((a, b) =>
									a.order && b.order && a.order < b.order
										? a.order - b.order
										: 0
								)
								.map((el: any) => (
									<div>
										{el.fieldType === "variable" && (
											<div>
												<span className="font-medium">
													{el.label}
												</span>
												<MyInput
													value={el.value || ""}
													onChange={(e1) => {
														handleChange(
															index,
															el.id,
															e1.target.value,
															"variable"
														);
													}}
												/>
											</div>
										)}
										{el.fieldType === "checkbox" && (
											<div>
												<div className="font-medium">
													{el.label}
												</div>
												<div className="w-full">
													<MyDropdown
														mode={
															el.options.filter(
																(el: any) =>
																	el.isSelection ===
																	true
															).length > 1
																? "multiple"
																: undefined
														}
														style={{
															width: "100%",
														}}
														placeholder="Select"
														options={el.options.map(
															(op: any) => ({
																label: op.value,
																value: op.value,
															})
														)}
														value={el.options.filter(
															(el: any) =>
																el.isSelection ===
																true
														)}
														onChange={(value) => {
															handleChange(
																index,
																el.id,
																el.options.filter(
																	(el: any) =>
																		el.isSelection ===
																		true
																).length > 1
																	? value
																	: [value],
																"checkbox"
															);
														}}
													/>
												</div>
											</div>
										)}
									</div>
								))}
						</div>
					</div>
				),
			};
			items.push(tempSection);
		});
		setAccordionData(items);
	};

	const handleSubmit = () => {
		const payload = JSON.parse(JSON.stringify(saveData));
		payload.isSubmit = true;
		console.log("SUBMIT DATA", payload);
		const key = "updatable";
		// axios
		// 	.post(
		// 		process.env.REACT_APP_API_URL +
		// 			"/save_document_extract/" +
		// 			state.selectedContract.id,
		// 		payload
		// 	)
		// 	.then((res) => {
		// 		if (res.data.data) {
		// 			myMessages({
		// 				text: "Submitted successfully!",
		// 				key: "ContractReviewDetailsExtract",
		// 				type: "success",
		// 			});
		// 			navigate("./..");
		// 		} else
		// 			message.error({
		// 				content: "Error submitting extract",
		// 				key: key,
		// 			});
		// 	})
		// 	.catch((e) =>
		// 		message.error({
		// 			content: "Error submitting extract",
		// 			key: key,
		// 		})
		// 	);
	};
	const handleSave = () => {
		const payload = JSON.parse(JSON.stringify(saveData));
		console.log("SAVE DATA", payload);
		const key = "updatable";
		message.loading({ content: "Saving...", key: key });
		// axios
		// 	.post(
		// 		process.env.REACT_APP_API_URL +
		// 			"/save_document_extract/" +
		// 			state.selectedContract.id,
		// 		payload
		// 	)
		// 	.then((res) => {
		// 		if (res.data.data) {
		// 			message.success({
		// 				content: "Saved successfully!",
		// 				key: key,
		// 			});
		// 			setChanged(false);
		// 		} else
		// 			message.error({
		// 				content: "Error saving extract",
		// 				key: key,
		// 			});
		// 	})
		// 	.catch((e) =>
		// 		message.error({
		// 			content: "Error saving extract",
		// 			key: key,
		// 		})
		// 	);
	};
	const handleDiscardChanges = () => {
		// setLoading(true);
		setDataSource(dataSourceCopy);
		setChanged(false);
		// setSaveData();
	};

	const handleChange = (
		sectionNumber: number,
		id: number | string,
		value: string | number | string[] | number[],
		type: "checkbox" | "variable"
	) => {
		setChanged(true);
		const temp: IFormExtractionApiData = JSON.parse(
			JSON.stringify(dataSource)
		);
		const idx = temp.extractedDetails[sectionNumber].details.findIndex(
			(el) => el.id === id
		);
		if (type === "checkbox" && Array.isArray(value)) {
			const tempOptions =
				temp.extractedDetails[sectionNumber].details[idx].options;
			tempOptions?.map((el) => {
				el.isSelection = (value as (string | number)[]).includes(
					el.value as string | number
				);
				return el;
			});
			temp.extractedDetails[sectionNumber].details[idx].options =
				tempOptions;
			console.log("SSSS", tempOptions);
		} else {
			temp.extractedDetails[sectionNumber].details[idx].value =
				value.toString();
		}
		setDataSource(temp);
	};
	const fetchData = async () => {
		setLoading(true);
		await axios
			.get(
				process.env.REACT_APP_API_URL +
					`/form_extract/${state.selectedContract.id}/${state.userDetails.username}`
			)
			.then((res) => {
				setDataSource(res.data);
				setDataSourceCopy(res.data);
				setLoading(false);
			})
			.catch((e) => {
				myMessages({
					text: "Error extracing details!",
					key: "ContractReviewDetailsExtract",
					type: "error",
				});
			});
	};
	useEffect(() => {
		createAccordionData();
	}, [dataSource]);
	useEffect(() => {
		if (state.userDetails.username) fetchData();
	}, [state.userDetails.username]);
	return (
		<>
			{loading ? (
				<div className="h-full w-full flex justify-center items-center">
					<Spin />
				</div>
			) : (
				<div
					className="p-4"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						// height: "100%",
						height: document.getElementById("extract-outlet")
							?.scrollHeight,
					}}
				>
					<div className="overflow-y-auto flex-1">
						<MyAccordion
							items={accordionData}
							defaultActiveKey={
								dataSource?.extractedDetails[0].id
							}
						/>
					</div>
					<div className="flex justify-end gap-2 mt-4">
						{changed && (
							<>
								<MyButton
									type="default"
									onClick={handleDiscardChanges}
								>
									<Undo className="mb-1 mr-1" size={16} />
									Discard Changes
								</MyButton>
								<MyButton type="primary" onClick={handleSave}>
									<Save className="mb-1 mr-1" size={16} />
									Save
								</MyButton>
							</>
						)}
						<MyButton type="primary" onClick={handleSubmit}>
							<SaveArrowRight className="mb-1 mr-1" size={20} />
							{changed ? "Save & Submit" : "Submit"}
						</MyButton>
					</div>
				</div>
			)}
		</>
	);
};

export default FormExtractContractReviewDetailsExtract;
