import React, { useEffect, useRef, useState } from "react";
import { IActiveAgent, IFeatures } from "../../model/IActiveAgentsApiData";
import { icons } from "../../assets/icon";
// import { AnglesUp } from "@styled-icons/fa-solid/AnglesUp";
// import { AnglesDown } from "@styled-icons/fa-solid/AnglesDown";
import { AnglesRight } from "@styled-icons/fa-solid/AnglesRight";
import axios from "axios";
import { Modal, Spin } from "antd"; 
import { ArrowRightOutlined } from "@ant-design/icons";

const ActiveAgentsCard = (props: {
    agent: IActiveAgent;
    handleNavigation: (agent: IActiveAgent, feature?: IFeatures) => void;
    fetchBatches: () => void;
    handleBatchClick: (batchId: string, agentId: number) => Promise<void>;
    agentId: number;
}) => {
    const MAX_HEIGHT = 192;
    const { agent, handleNavigation, fetchBatches: fetchBatchesProp, handleBatchClick, agentId } = props;
    const [scrollPosition, setScrollPosition] = useState(0);
    const featureListRef = useRef<HTMLDivElement>(null);
    const [featureContainerHeight, setFeatureContainerHeight] = useState(0);
    const [batches, setBatches] = useState<any[]>([]);
    const [showBatches, setShowBatches] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (featureListRef.current) {
            setFeatureContainerHeight(featureListRef.current.scrollHeight);
        }
    }, [agent.features]);

    const scrollTo = (scrollOffset: number) => {
        if (featureListRef.current) {
            let newPosition = scrollPosition + scrollOffset;
            newPosition = Math.max(
                0,
                Math.min(newPosition, featureContainerHeight - MAX_HEIGHT)
            );
            setScrollPosition(newPosition);
            featureListRef.current.scrollTo({
                top: newPosition,
                behavior: "smooth",
            });
        }
    };

    const fetchAgentBatches = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/agent_batches/${agent.id}`
            );
            setBatches(response.data.batches);
            setShowBatches(true);
        } catch (error) {
            console.error("Error fetching batches:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleBatchClickWrapper = async (batchId: string) => {
        await handleBatchClick(batchId, agentId); 
    };

    const handleModalOpen = () => {
        fetchAgentBatches();
        setIsModalVisible(true); // Open modal
    };

    const handleModalClose = () => {
        setIsModalVisible(false); // Close modal
    };

    return (
        <div
            className={`w-full mb-4 p-4 grid grid-cols-12 items-center transition-all duration-100 ${
                agent.isActive ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            style={{ boxShadow: "0px 0px 8px 1px #9095a05c" }}
            onMouseOver={(event) => {
                if (agent.isActive)
                    event.currentTarget.style.transform = "scale(1.02)";
            }}
            onMouseOut={(event) => {
                if (agent.isActive)
                    event.currentTarget.style.transform = "scale(1)";
            }}
			onClick={(e) => {
				e.stopPropagation();
				handleModalOpen(); // Open modal on arrow click
			}}
        >
            <div className="col-span-3 flex items-center">
                <div className="">
                    <div>
                        {icons.find((el) => el.name === agent.iconName)?.icon ??
                            icons.find((el) => el.name === "contracts-ai")
                                ?.icon}
                    </div>
                    <div className="text-base font-semibold my-1">
                        {agent.name}
                    </div>
                    <div className="text-text-zs-light-gray text-sm font-normal">
                        {agent.details}
                    </div>
                    <div className="text-xs font-normal my-1 w-full text-wrap">
                        {agent.description}
                    </div>
                </div>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-8">
                {/* {featureContainerHeight > MAX_HEIGHT && (
                    <div
                        className={`flex justify-center text-text-zs-light-gray ${
                            scrollPosition === 0 ? "opacity-0" : "bg-opacity-100"
                        } transition-all duration-500 overflow-hidden mb-2`}
                    >
                        <AnglesUp
                            size={16}
                            onClick={(e) => {
                                e.stopPropagation();
                                scrollTo(-120);
                            }}
                            className="cursor-pointer"
                        />
                    </div>
                )} */}
                <div
                    ref={featureListRef}
                    className={
                        agent.features.length > 1
                            ? "grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 gap-4 max-h-48 overflow-y-hidden w-full"
                            : "grid grid-cols-1 gap-4 max-h-48 overflow-y-hidden w-full m-auto"
                    }
                    style={{ maxHeight: MAX_HEIGHT + "px" }}
                >
                    {agent.features.length > 0 &&
                        agent.isActive &&
                        agent.features.map((feature, index) => (
                            <div className="flex gap-4" key={index}>
                                <div
                                    className="bg-misc-light-blue rounded-md text-white flex items-center justify-center"
                                    style={{ height: "40px", width: "40px" }}
                                >
                                    {icons.find((el) => el.name === feature.iconName)?.icon ??
                                        icons.find((el) => el.name === "pie-chart")?.icon}
                                </div>
                                <div className="flex-1">
                                    <div className="font-semibold mb-2">
                                        <span>{feature.name}</span>
                                    </div>
                                    <div className="text-xs font-normal">
                                        {feature.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {agent.features.length === 0 && !agent.isActive && (
                        <div className="w-full flex justify-center text-sm italic text-text-zs-light-gray font-normal">
                            Coming Soon...
                        </div>
                    )}
                </div>
                {/* <div className="col-span-1"></div> */}
                {/* {featureContainerHeight > MAX_HEIGHT && (
                    <div
                        className={`flex justify-center text-text-zs-light-gray ${
                            scrollPosition < featureContainerHeight - MAX_HEIGHT
                                ? "opacity-100"
                                : "opacity-0"
                        } transition-all duration-500 overflow-hidden mt-2`}
                    >
                        <AnglesDown
                            size={16}
                            onClick={(e) => {
                                e.stopPropagation();
                                scrollTo(120);
                            }}
                            className="cursor-pointer"
                        />
                    </div>
                )} */}
            </div>
            
            {/* <div 
            className="col-span-12"
            style={{position:"absolute", bottom: '10px', right: '10px'}}
            >
                    <ArrowRightOutlined
                        size={40}
                        style={{color:"rgba(67, 190, 190, 1)", height: "32px", width:"32px",  fontWeight: "bold"}}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleModalOpen(); // Open modal on arrow click
                        }}
                        className="cursor-pointer text-blue-500"
                    />
            </div> */}

            {/* Modal for showing all batches */}
            <Modal
				title={<div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 10, fontSize:"24px", fontWeight:"700", color:"rgb(69, 66, 80);",letterSpacing:"-0.336px", lineHeight:"31.92px" }}>{`Batches for ${agent.name}`}</div>}
				open={isModalVisible}
				onCancel={(e) => {
					e.stopPropagation(); // Ensure the event doesn't bubble up
					handleModalClose();
				}}
				footer={null}
				centered
			>
				<div className="batch-list" style={{ marginTop: '30px', maxHeight: '400px', overflowY: 'auto', paddingRight: "10px" }}>  {/* Ensure content is scrollable */}
                {loading ? (
						<div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
							<Spin size="large" />
						</div>
					) : (
						// If not loading, display batches
						batches.length > 0 ? (
							batches.map((batch) => (
								<div
									key={batch.id}
									className="batch-item bg-gray-100 p-2 rounded-md mb-2 border border-gray-300 hover:bg-gray-200 cursor-pointer"
									onClick={(e) => {
										e.stopPropagation();
										handleBatchClickWrapper(batch.id);
										handleModalClose(); // Close modal after selection
									}}
									style={{marginBottom: "10px"}}
								>
									{batch.label}
								</div>
							))
						) : (
							<p>No batches available.</p>
						)
					)}
				</div>
			</Modal>
        </div>
    );
};

export default ActiveAgentsCard;
