import {
	Tag,
	Table,
	Pagination,
	Button,
	Input,
	Spin,
	message,
	Typography,
	Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import MyInput from "../../components/input";
import MyButton from "../../components/buttons";
import { Download } from "@styled-icons/bootstrap/Download";
import { Save } from "@styled-icons/bootstrap/Save";
import { SaveArrowRight } from "@styled-icons/fluentui-system-regular/SaveArrowRight";
import DownloadExtract from "../../components/downloadExtract";
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import { Undo } from "@styled-icons/material-rounded/Undo";
import axios from "axios";
import ErrorPage from "../errorPage";
import { useNavigate } from "react-router-dom";
import { Expand } from "@styled-icons/boxicons-regular/Expand";
import MyDropdown from "../../components/dropdown";
import MyModal from "../../components/modal";
import myMessages from "../../components/messages";
enum TableDataType {
	html = 1,
	json = 2,
}
const ContractReviewDetailsExtract = () => {
	const store = useSelector(selectStore);
	const navigate = useNavigate();
	const state = useSelector(selectStore);
	const [dataSource, setDataSource] = useState<any>();
	const [dataSourceCopy, setDataSourceCopy] = useState<any>();
	const [currentPage, setCurrentPage] = useState<any>(1);
	const [loading, setLoading] = useState(true);
	const [tableContainerHeight, setTableContainerHeight] = useState(0);
	const [changed, setChanged] = useState(false);
	const [saveData, setSaveData] = useState<any>({
		keyValues: [],
		tableData: [],
		isSubmit: false,
	});
	const [parsingType, setParsingType] = useState<number>();
	const [error, setError] = useState<boolean>(false);
	const [options, setOptions] =
		useState<{ label: string; value: string }[]>();
	const [isModalOpen, setIsModalOpen] = useState<number>(0);
	const showModal = (tableType: number) => {
		setIsModalOpen(tableType);
	};
	const handleOk = () => {
		setIsModalOpen(0);
	};
	const handleCancel = () => {
		setIsModalOpen(0);
	};
	const replaceTDsWithTHs = (htmlString: string) => {
		// Create a temporary div element to parse the HTML string
		var tempDiv = document.createElement("div");
		tempDiv.innerHTML = htmlString.trim();

		// Get all the table rows
		var rows = tempDiv.querySelectorAll("tr");

		// Find the index of the last row containing th
		var lastIndex = -1;
		for (var i = rows.length - 1; i >= 0; i--) {
			if (rows[i].querySelectorAll("th").length > 0) {
				lastIndex = i;
				break;
			}
		}

		// Loop through each row up to the last row containing th
		for (var i = 0; i < lastIndex; i++) {
			var cells = rows[i].querySelectorAll("td");

			// Loop through each cell in the row
			for (var j = 0; j < cells.length; j++) {
				// Create a new th element
				var thElement = document.createElement("th");
				// Copy the content of td to th
				thElement.innerHTML = cells[j].innerHTML;
				thElement.colSpan = cells[j].colSpan;
				// Replace td with th
				cells[j].parentNode?.replaceChild(thElement, cells[j]);
			}
		}

		// Return the modified HTML
		return tempDiv.innerHTML;
	};
	function addColspan(htmlString: string) {
		// Create a temporary div element to parse the HTML string
		var tempDiv = document.createElement("div");
		tempDiv.innerHTML = htmlString;

		// Find all <th> elements
		var tableHeaders = tempDiv.querySelectorAll("table th");
		const cellIndex: number[] = [];
		for (var i = 0; i < tableHeaders.length; i++)
			//@ts-ignore
			cellIndex.push(tableHeaders[i].cellIndex);
		// Iterate through each <th> element to find the colspan for each pair
		for (var i = 0; i < tableHeaders.length; i++) {
			var currentHeader = tableHeaders[i];

			// If the current <th> is empty, find the preceding non-empty <th> to calculate colspan

			if (currentHeader.textContent?.trim() === "") {
				var { precedingHeader, idx } = findPrecedingNonEmptyHeader(
					tableHeaders,
					i
				);
				if (precedingHeader) {
					//@ts-ignore
					var colspan =
						//@ts-ignore
						cellIndex[i] - cellIndex[idx] + 1;
					precedingHeader.setAttribute("colspan", colspan);
					//@ts-ignore
					currentHeader.parentElement.removeChild(currentHeader);
				}
			}
		}

		// Return the modified HTML string
		return tempDiv.innerHTML;
	}

	// Function to find the preceding non-empty <th> element
	function findPrecedingNonEmptyHeader(headers: any, index: number) {
		for (var i = index - 1; i >= 0; i--) {
			if (headers[i].textContent.trim() !== "") {
				return { precedingHeader: headers[i], idx: i };
			}
		}
		return { precedingHeader: null, idx: -1 };
	}
	// const htmlToJson = (htmlTable: string) => {
	// 	htmlTable = addColspan(htmlTable);
	// 	if (htmlTable.length > 0) {
	// 		// // Convert HTML table to JSON
	// 		// const tableElement = document.createElement("div");
	// 		// tableElement.innerHTML = htmlTable;
	// 		// const rows = tableElement.getElementsByTagName("tr");
	// 		// const dataSource = [];
	// 		// const columns = [];

	// 		// let headerCells = rows[1].getElementsByTagName("th");
	// 		// for (let j = 0; j < headerCells.length; j++) {
	// 		// 	columns.push({
	// 		// 		title: headerCells[j].innerText,
	// 		// 		dataIndex: headerCells[j].innerText,
	// 		// 		key: headerCells[j].innerText,
	// 		// 	});
	// 		// }

	// 		// for (let i = 2; i < rows.length; i++) {
	// 		// 	const row = rows[i];
	// 		// 	const rowData = {};
	// 		// 	const cells = row.getElementsByTagName("td");
	// 		// 	for (let j = 0; j < cells.length; j++) {
	// 		// 		//@ts-ignore
	// 		// 		rowData[columns[j].dataIndex] = cells[j].innerText;
	// 		// 	}
	// 		// 	if (Object.keys(rowData).length > 0) {
	// 		// 		dataSource.push(rowData);
	// 		// 	}
	// 		// }

	// 		const table = document.createElement("table");

	// 		table.innerHTML = htmlTable;

	// 		const headerRows = table.querySelectorAll("tr");
	// 		const columns: any = [];
	// 		const columns2: any = [];

	// 		const dataSource: any = [];
	// 		let currentGroup: any = null;

	// 		headerRows.forEach((row, rowIndex) => {
	// 			const cells = row.querySelectorAll("th, td");
	// 			cells.forEach((cell, cellIndex) => {
	// 				//@ts-ignore
	// 				if (rowIndex === 0 && cellIndex === 0 && cell.colSpan > 1) {
	// 					currentGroup = {
	// 						title: cell.textContent?.trim(),
	// 						dataIndex: cell.textContent?.trim(), // Assigning dataIndex to the group title
	// 						key: cell.textContent?.trim(), // Assigning key to the group title
	// 						children: [],
	// 					};
	// 					columns.push(currentGroup);
	// 				} else if (
	// 					currentGroup &&
	// 					cellIndex < currentGroup.children.length
	// 				) {
	// 					currentGroup.children[cellIndex].title += "";
	// 				} else {
	// 					if (!currentGroup) {
	// 						currentGroup = {
	// 							title: "Undefined Group",
	// 							dataIndex: `group${columns.length}`, // Assigning dataIndex to the group
	// 							key: `group${columns.length}`, // Assigning key to the group
	// 							children: [],
	// 						};
	// 						columns.push(currentGroup);
	// 					}
	// 					const columnData = {
	// 						title: cell.textContent?.trim(),
	// 						dataIndex: cell.textContent?.trim(), // Assigning dataIndex to the column title
	// 						key: cell.textContent?.trim(), // Assigning key to the column title
	// 					};
	// 					currentGroup.children.push(columnData);
	// 				}
	// 			});
	// 		});
	// 		const rows = table.getElementsByTagName("tr");
	// 		let headerCells = rows[1].getElementsByTagName("th");
	// 		for (let j = 0; j < headerCells.length; j++) {
	// 			columns2.push({
	// 				title: headerCells[j].innerText,
	// 				dataIndex: headerCells[j].innerText,
	// 				key: headerCells[j].innerText,
	// 			});
	// 		}
	// 		for (let i = 2; i < rows.length; i++) {
	// 			const row = rows[i];
	// 			const rowData = {};
	// 			const cells = row.getElementsByTagName("td");
	// 			for (let j = 0; j < cells.length; j++) {
	// 				//@ts-ignore
	// 				rowData[columns2[j].dataIndex] = cells[j].innerText;
	// 			}
	// 			if (Object.keys(rowData).length > 0) {
	// 				dataSource.push(rowData);
	// 			}
	// 		}
	// 		console.log("COLUMN", columns);
	// 		return { columns, dataSource };
	// 	} else
	// 		return {
	// 			columns: [],
	// 			dataSource: [],
	// 		};
	// };

	// const findColumns = (
	// 	low: number,
	// 	high: number,
	// 	row: number,
	// 	max: number,
	// 	headerRows: any
	// ) => {
	// 	const col: any = [];
	// 	if (low >= high || row >= max) return [];
	// 	if (row < max) {
	// 		while (low < high && low < headerRows[row].children.length) {
	// 			let span = 0;
	// 			var previousSibling =
	// 				headerRows[row].children[low].previousSibling;

	// 			while (previousSibling) {
	// 				if (previousSibling.nodeType === 1) {
	// 					// Check if node is an element node
	// 					span += previousSibling.colSpan;
	// 				}
	// 				previousSibling = previousSibling.previousSibling;
	// 			}
	// 			const t = headerRows[row].children[low].textContent?.trim();
	// 			col.push({
	// 				title: headerRows[row].children[low].textContent?.trim(),
	// 				dataIndex:
	// 					headerRows[row].children[low].textContent?.trim(),
	// 				key: headerRows[row].children[low].textContent?.trim(),
	// 				index: headerRows[row].children[low].textContent?.trim(),
	// 				ellipsis: true,
	// 				render: (text: any, e: any, index: number) => (
	// 					<MyInput
	// 						value={text}
	// 						onChange={(e1) =>
	// 							handleTableChange(
	// 								e,
	// 								JSON.stringify(t),
	// 								e1.target.value,
	// 								currentPage,
	// 								index
	// 							)
	// 						}
	// 					/>
	// 				),
	// 				children: findColumns(
	// 					// low,
	// 					span > 0 ? span : low,
	// 					span > 0
	// 						? span + headerRows[row].children[low].colSpan
	// 						: low + headerRows[row].children[low].colSpan,
	// 					row + 1,
	// 					max,
	// 					headerRows
	// 				),
	// 			});
	// 			if (0 && headerRows[row].children[low].colSpan)
	// 				low = low + headerRows[row].children[low].colSpan;
	// 			else low++;
	// 		}
	// 		return col;
	// 	}
	// };

	const findColumns = (
		low: number,
		high: number,
		row: number,
		max: number,
		headerRows: any
	) => {
		let unnamedIndex = 1;
		const col: any = [];
		if (low >= high || row >= max) return [];
		if (row < max) {
			while (low < high && low < headerRows[row].children.length) {
				let span = 0;
				let rowSpan = 1; // Initialize rowSpan to 1
				const currentCell = headerRows[row].children[low];

				// Handle row span
				if (currentCell.rowSpan > 1) {
					rowSpan = currentCell.rowSpan;
				}

				var previousSibling = currentCell.previousSibling;

				while (previousSibling) {
					if (previousSibling.nodeType === 1) {
						// Check if node is an element node
						span += previousSibling.colSpan;
					}
					previousSibling = previousSibling.previousSibling;
				}
				const t = currentCell.textContent?.trim();
				col.push({
					title: t,
					dataIndex: t || unnamedIndex,
					key: t || unnamedIndex,
					index: t || unnamedIndex++,
					ellipsis: true,
					render: (text: any, e: any, index: number) => (
						<MyInput
							value={text}
							onChange={(e1) =>
								handleTableChange(
									e,
									JSON.stringify(t),
									e1.target.value,
									currentPage,
									index
								)
							}
						/>
					),
					children: findColumns(
						span > 0 ? span : low,
						span > 0
							? span + currentCell.colSpan
							: low + currentCell.colSpan,
						row + rowSpan, // Increment row by rowSpan
						max,
						headerRows
					),
				});
				if (0 && currentCell.colSpan) low = low + currentCell.colSpan;
				else low++;
			}
			return col;
		}
	};

	const htmlToJson = (htmlTable: string) => {
		try {
			htmlTable = replaceTDsWithTHs(htmlTable);
			htmlTable = addColspan(htmlTable);
			if (htmlTable.length > 0) {
				const table = document.createElement("table");

				table.innerHTML = htmlTable;

				let headerRows: any;
				headerRows = table.querySelectorAll("tr:has(th)");
				console.log("headerRows", headerRows);
				if (headerRows.length === 0)
					headerRows = [table.querySelectorAll("tr:has(td)")[0]];
				console.log("headerRows", headerRows);
				const columns: any = findColumns(
					0,
					headerRows[headerRows.length - 1].children.length,
					0,
					headerRows.length,
					headerRows
				);
				const columns2: any = [];

				const dataSource: any = [];
				let currentGroup: any = null;

				const rows = table.getElementsByTagName("tr");

				let unnamedIndex = 1;
				for (let i = 0; i < headerRows.length; i++) {
					let headerCells: any = rows[i].querySelectorAll("th,td");
					for (let j = 0; j < headerCells.length; j++) {
						if (i + headerCells[j].rowSpan === headerRows.length)
							columns2.push({
								title: headerCells[j].innerText,
								dataIndex:
									headerCells[j].innerText || unnamedIndex,
								key: headerCells[j].innerText || unnamedIndex++,
							});
					}
				}

				for (let i = headerRows.length; i < rows.length; i++) {
					const row = rows[i];
					const rowData = {};
					const cells = row.getElementsByTagName("td");
					for (let j = 0; j < cells.length; j++) {
						//@ts-ignore
						rowData[columns2[j].dataIndex] = cells[j].innerText;
					}
					if (Object.keys(rowData).length > 0) {
						dataSource.push(rowData);
					}
				}

				console.log("COLUMN", columns, columns2);
				return { columns, dataSource };
			} else
				return {
					columns: [],
					dataSource: [],
				};
		} catch (e) {
			console.log("Exception while making table", e);
			return { columns: [], dataSource: [] };
		}
	};

	const jsonParse = (jsonData: string, htmlTable: string) => {
		try {
			htmlTable = replaceTDsWithTHs(htmlTable);
			htmlTable = addColspan(htmlTable);
			if (htmlTable.length > 0) {
				const table = document.createElement("table");

				table.innerHTML = htmlTable;

				let headerRows: any;
				headerRows = table.querySelectorAll("tr:has(th)");
				console.log("headerRows", headerRows);
				if (headerRows.length === 0)
					headerRows = table.querySelectorAll("tr:has(td)")[0];

				const columns: any = findColumns(
					0,
					headerRows[headerRows.length - 1].children.length,
					0,
					headerRows.length,
					headerRows
				);
				const dataSource = JSON.parse(jsonData);
				return { columns, dataSource };
			} else
				return {
					columns: [],
					dataSource: [],
				};
		} catch (e) {
			console.log("Exception while making table", e);
			return { columns: [], dataSource: [] };
		}
	};
	const fetchData = async () => {
		setLoading(true);

		// const apiData = {
		// 	keyValues: [
		// 		{
		// 			label: "Rebate Percentage",
		// 			Id: "1",
		// 			value: "15%",
		// 			score: 0.9,
		// 			isHigh: true,
		// 		},
		// 		{
		// 			label: "SAP Number",
		// 			Id: "2",
		// 			value: "1232421",
		// 			score: 0.9,
		// 			isHigh: true,
		// 		},
		// 		{
		// 			label: "Party Name",
		// 			Id: "3",
		// 			value: "SyncIQ AI Integrations",
		// 			score: 0.4,
		// 			isHigh: false,
		// 		},
		// 	],
		// 	tableData: [
		// 		{
		// 			tableName: "T1",
		// 			Id: "1",
		// 			// data: [
		// 			// 	// { Id: 1, "S.No.": 1, "": "abc", Rebate: "5%" },
		// 			// 	// { Id: 2, "S.No.": 2, "": "def", Rebate: "5%" },
		// 			// 	// // { Id: 3, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 4, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 5, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 6, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 7, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 8, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 9, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 10, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 11, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 12, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 13, "S.No.": 2, Drug: "def", Rebate: "5%" },
		// 			// 	// // { Id: 14, "S.No.": 2, Drug: "def", Rebate: "5%" },

		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Group Contact: ",
		// 			// 		"parent Name": "Deborah Bowers ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Title: ",
		// 			// 		"": "North American Meetings & Events Planner ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Company Name: ",
		// 			// 		"": "SAKO Materials Limited ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Address: ",
		// 			// 		"": "80 Stafford Drive ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "City, State, Zip: ",
		// 			// 		"": "Brampton, ON, L6W 1L4 ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Phone: ",
		// 			// 		"": "416-938-9887 ",
		// 			// 	},
		// 			// 	{
		// 			// 		"Especially Prepared for: ": "Email: ",
		// 			// 		"": "Deborah.bowers@iko.com ",
		// 			// 	},
		// 			// ],
		// 			data: `<table><tr><th>Parent Col</th><th></th></tr>
		// 			<tr><th>child row 1</th><th>child row 2</th></tr>
		// 			<tr><td>10% - < 20%</td><td>2.00%</td></tr>
		// 			<tr><td>20% - < 25%</td><td>5.00%</td></tr>
		// 			<tr><td>25% - < 30%</td><td>22.00%</td></tr>
		// 			</table>`,
		// 			jsonData:
		// 				'[{"child row 1":"10% - < 20%","child row 2":"2.00%wffwq"},{"child row 1":"20% - < 25%","child row 2":"5.00%"},{"child row 1":"25% - < 30%","child row 2":"22.00%wfwqfq"}]',
		// 		},
		// 		{
		// 			tableName: "T2",
		// 			Id: "2",
		// 			data: `<table>
		// 			<tr>
		// 			   <th colspan="2">GENDRGQ Quarterly Rebate Table1</th>
		//                <th rowspan="3">GENDRGQ Quarterly Rebate Rate3</th>
		// 			</tr>
		// 			<tr>
		// 			   <th colspan="2">GENDRGQ Product Share in the applicable Quarter2</th>
		// 			</tr>
		// 			<tr>
		// 			   <th>GENDRGQ Product Share in the applicable Quarter4</th>
		// 			   <th>abc5</th>
		// 			</tr>
		// 			<tr>
		// 			   <td>5% - < 10%</td>
		// 			   <td>123</td>
		// 			   <td>6.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>10% - < 15%</td>
		// 			   <td>456</td>
		// 			   <td>8.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>15% - < 20%</td>
		// 			   <td>789</td>
		// 			   <td>12.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>> 20%</td>
		// 			   <td>1a1</td>
		// 			   <td>13.33%</td>
		// 			</tr>
		// 		 </table>`,
		// 			jsonData: null,
		// 		},
		// 		{
		// 			tableName: "T3",
		// 			Id: "3",
		// 			data: `<table>
		// 			<tr>
		// 			   <th colspan="3">GENDRGQ Quarterly Rebate Table1</th>
		// 			</tr>
		// 			<tr>
		// 			   <th colspan="2">GENDRGQ Product Share in the applicable Quarter2</th>
		// 			   <th>GENDRGQ Quarterly Rebate Rate3</th>
		// 			</tr>
		// 			<tr>
		// 			   <th>GENDRGQ Product Share in the applicable Quarter4</th>
		// 			   <th>abc5</th>
		// 			   <th>GENDRGQ Quarterly Rebate Rate6</th>
		// 			</tr>
		// 			<tr>
		// 			   <td>5% - < 10%</td>
		// 			   <td>123</td>
		// 			   <td>6.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>10% - < 15%</td>
		// 			   <td>456</td>
		// 			   <td>8.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>15% - < 20%</td>
		// 			   <td>789</td>
		// 			   <td>12.00%</td>
		// 			</tr>
		// 			<tr>
		// 			   <td>> 20%</td>
		// 			   <td>1a1</td>
		// 			   <td>13.33%</td>
		// 			</tr>
		// 		 </table>`,
		// 			jsonData: null,
		// 		},
		// 		{
		// 			tableName: "T4",
		// 			Id: "4",
		// 			data: `<table><tr><td>Invoice Discount Table</td><td></td></tr>
		// 			<tr><th>GENDRGX Product Share <50%</th><th>GENDRGX Product Share > 50%</th></tr>
		// 			<tr><td>0.54%</td><td>76%</td></tr>
		// 			</table>`,
		// 			jsonData: null,
		// 		},
		// 	],
		// };
		// const convertedTableData: any = [];
		// apiData.tableData.map((table) => {
		// 	convertedTableData.push({
		// 		...table,
		// 		jsonData: table.jsonData
		// 			? table.jsonData
		// 			: JSON.stringify(htmlToJson(table.data).dataSource),
		// 	});
		// });
		// apiData.tableData = convertedTableData;

		axios
			.get(
				"https://intelligent-automation.api.demo.zsservices.com/document_extract/" +
					state.selectedContract.id +
					"/" +
					state.userDetails.username
			)
			.then((res) => {
				const convertedTableData: any = [];
				res.data.tableData.map((table: any) => {
					convertedTableData.push({
						...table,
						jsonData: table.jsonData
							? table.jsonData
							: table.parsedHtml
							? JSON.stringify(
									htmlToJson(table.parsedHtml).dataSource
							  )
							: "[]",
					});
				});
				res.data.tableData = convertedTableData;
				setDataSource(res.data);
				setDataSourceCopy(res.data);
				// setDataSource(apiData);
				// setDataSourceCopy(apiData);
				setLoading(false);
				const opt = res.data.tableTypes;
				setOptions(opt);
			})
			.catch((e) => setError(true));
	};

	const handleParse = (tableType: number, tableId: number) => {
		const payload = {
			tableId: tableType,
			entity_id: tableId,
			doc_id: store.selectedContract.id,
			doc_name: store.selectedContract.name,
		};

		myMessages({
			text: "Parsing...",
			key: "ContractReviewDetailsExtract",
			type: "loading",
			duration: 0,
		});
		axios
			.post(process.env.REACT_APP_API_URL + "/get_parsed_html", payload)
			.then((res) => {
				if (res.data) {
					myMessages({
						text: "Parsed successfully!",
						key: "ContractReviewDetailsExtract",
						type: "success",
						duration: 2,
					});
					const temp = JSON.parse(JSON.stringify(dataSource));
					const tempCopy = JSON.parse(JSON.stringify(dataSourceCopy));

					const idx = temp?.tableData.findIndex(
						(el: any) => el.Id === tableId
					);

					if (idx != -1) {
						temp.tableData[idx].parsedHtml = res.data;
						temp.tableData[idx].tableType = tableType;
						temp.tableData[idx].jsonData = JSON.stringify(
							htmlToJson(res.data).dataSource
						);
						tempCopy.tableData[idx].parsedHtml = res.data;
						tempCopy.tableData[idx].tableType = tableType;
						tempCopy.tableData[idx].jsonData = JSON.stringify(
							htmlToJson(res.data).dataSource
						);
					}

					setDataSource(temp);
					setDataSourceCopy(tempCopy);
				} else
					myMessages({
						text: "Error while parsing!",
						key: "ContractReviewDetailsExtract",
						type: "error",
						duration: 2,
					});
			})
			.catch((e) => {
				myMessages({
					text: "Error while parsing!",
					key: "ContractReviewDetailsExtract",
					type: "error",
					duration: 2,
				});
			});
	};
	const handleSubmit = () => {
		const payload = JSON.parse(JSON.stringify(saveData));
		payload.isSubmit = true;
		console.log("SUBMIT DATA", payload);
		const key = "updatable";
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"/save_document_extract/" +
					state.selectedContract.id,
				payload
			)
			.then((res) => {
				if (res.data.data) {
					message.success({
						content: "Submitted successfully!",
						key: key,
					});
					navigate("./..");
				} else
					message.error({
						content: "Error submitting extract",
						key: key,
					});
			})
			.catch((e) =>
				message.error({
					content: "Error submitting extract",
					key: key,
				})
			);
	};
	const handleSave = () => {
		const payload = JSON.parse(JSON.stringify(saveData));
		console.log("SAVE DATA", payload);
		const key = "updatable";
		message.loading({ content: "Saving...", key: key });
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"/save_document_extract/" +
					state.selectedContract.id,
				payload
			)
			.then((res) => {
				if (res.data.data) {
					message.success({
						content: "Saved successfully!",
						key: key,
					});
					setChanged(false);
				} else
					message.error({
						content: "Error saving extract",
						key: key,
					});
			})
			.catch((e) =>
				message.error({
					content: "Error saving extract",
					key: key,
				})
			);
	};
	const handleDiscardChanges = () => {
		// setLoading(true);
		setDataSource(dataSourceCopy);
		setChanged(false);
		setSaveData({ keyValues: [], tableData: [], isSubmit: false });
	};
	const handleTableChange = (
		e: any,
		key: string,
		text: string,
		currentPage: number,
		index: number
	) => {
		key = JSON.parse(key);
		console.log("EEE", document.getElementsByTagName("table")[1]);
		setChanged(true);

		const tempDataSource = JSON.parse(JSON.stringify(dataSource));
		// const idx = tempDataSource.tableData[currentPage - 1]["data"].findIndex(
		// 	(el: any) => el.Id === e.Id
		// );
		if (1 || index != -1) {
			const temp = JSON.parse(
				tempDataSource.tableData[currentPage - 1]["jsonData"]
			);
			temp[index][key] = text;
			tempDataSource.tableData[currentPage - 1]["jsonData"] =
				JSON.stringify(temp);
			setDataSource(tempDataSource);
		}

		const tempSaveData = JSON.parse(JSON.stringify(saveData));
		const idx2 = tempSaveData.tableData.findIndex(
			(el: any) => el.Id === tempDataSource.tableData[currentPage - 1].Id
		);
		if (idx2 === -1)
			tempSaveData.tableData.push(
				tempDataSource.tableData[currentPage - 1]
			);
		else
			tempSaveData.tableData[idx2] =
				tempDataSource.tableData[currentPage - 1];
		setSaveData(tempSaveData);
	};
	const handleKeyValueChange = (
		Id: number | string,
		label: string,
		value: string
	) => {
		setChanged(true);
		const tempDataSource = JSON.parse(JSON.stringify(dataSource));

		const idx = tempDataSource.keyValues.findIndex(
			(el: any) => el.Id === Id
		);
		if (idx != -1) {
			tempDataSource.keyValues[idx].value = value;
			setDataSource(tempDataSource);
		}
		const tempSaveData = JSON.parse(JSON.stringify(saveData));
		const idx2 = tempSaveData.keyValues.findIndex(
			(el: any) => el.Id === Id
		);
		if (idx2 === -1)
			tempSaveData.keyValues.push(tempDataSource.keyValues[idx]);
		else tempSaveData.keyValues[idx2] = tempDataSource.keyValues[idx2];
		setSaveData(tempSaveData);
	};
	const convertDataForDownload = (data: any) => {
		const output: { sheetName: string; data: any }[] = [];
		if (data.keyValues && data.keyValues.length > 0) {
			const keyValueData: any = [];
			data.keyValues.map((row: any) => {
				keyValueData.push({
					Label: row.label,
					Value: row.value,
				});
			});
			output.push({ sheetName: "Key Values", data: keyValueData });
		}
		if (data.tableData && data.tableData.length > 0) {
			data.tableData.map((table: any, index: number) => {
				if (table.jsonData !== "[]")
					output.push({
						sheetName: index + table.tableName,
						data: JSON.parse(table.jsonData),
					});
			});
		}
		return output;
	};
	const createColumns = () => {
		const cols: any[] = [];
		if (dataSource?.tableData[currentPage - 1]?.data?.length > 0)
			Object.keys(
				dataSource?.tableData[currentPage - 1]?.data[0] || {}
			).map((key, index) => {
				if (key !== "Id")
					cols.push({
						title: key.startsWith("unnamed") ? "" : key,
						dataIndex: key,
						render: (text: any, e: any, index: number) => (
							<MyInput
								value={text}
								onChange={(e1) =>
									handleTableChange(
										e,
										key,
										e1.target.value,
										currentPage,
										index
									)
								}
							/>
						),
						ellipsis: true,
					});
			});
		console.log("COLS", cols);
		return cols;
	};
	const onPageChange = (page: any) => {
		setCurrentPage(page);
		setParsingType(undefined);
	};
	useEffect(() => {
		if (state.selectedContract.id !== -1) fetchData();
	}, [state.selectedContract.id]);
	useEffect(() => {
		const tableHeight =
			document.getElementById("table-container")?.scrollHeight || 0;
		const headerHeight =
			document
				.getElementById("table-container")
				?.getElementsByTagName("thead")[0]?.scrollHeight || 0;
		setTableContainerHeight(tableHeight - headerHeight);
	}, [dataSource, currentPage]);
	useEffect(() => {
		return () => {
			myMessages({
				key: "ContractReviewDetailsExtract",
				destroy: true,
			});
		};
	}, []);
	return (
		<>
			{!error ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
					}}
				>
					{/** Uncomment for Confidence Score */}
					{/* <div
						className="mr-8 mt-4"
						style={{ display: "flex", justifyContent: "end" }}
					>
						<Tag
							bordered={false}
							color={"success"}
							className="mr-1"
						>
							High CS
						</Tag>
						<Tag bordered={false} color={"error"}>
							Low CS
						</Tag>
					</div> */}

					<div className="h-48 my-4">
						{!loading && dataSource?.keyValues.length > 0 && (
							<p
								className="ml-8 mt-4"
								style={{ fontSize: "14px", fontWeight: 700 }}
							>
								Key Values
							</p>
						)}
						<div className="h-40 overflow-y-auto p-4">
							{!loading &&
								dataSource?.keyValues.length > 0 &&
								dataSource?.keyValues.map((el: any) => (
									<div className="my-2 mx-4 grid grid-cols-8 items-center">
										<div
											className="col-span-2 text-ellipsis overflow-hidden whitespace-nowrap"
											style={{ fontSize: "14px" }}
										>
											{/* {el.label + "djoaodjojdaodj"} */}
											<Typography.Paragraph
												ellipsis={{
													rows: 1,
													expandable: false,
													symbol: "more",
													tooltip: el.label,
												}}
												style={{ margin: "0" }}
											>
												{el.label}
											</Typography.Paragraph>
										</div>
										<div className="col-span-6 mr-1">
											{el.isDefault ? (
												<Typography.Paragraph
													ellipsis={{
														rows: 1,
														expandable: false,
														symbol: "more",
														tooltip: el.value,
													}}
													style={{ margin: "0" }}
												>
													{el.value}
												</Typography.Paragraph>
											) : (
												<MyInput
													disabled={el.isDefault}
													value={el.value}
													onChange={(e) =>
														handleKeyValueChange(
															el.Id,
															el.label,
															e.target.value
														)
													}
												></MyInput>
											)}
										</div>
										{/*Uncomment for Confidence Score */}
										{/* <div className="col-span-1">
											<Tag
												bordered={false}
												color={
													el.isHigh === true
														? "success"
														: "error"
												}
											>
												{el.score}
											</Tag>
										</div> */}
										<div className="col-span-3"></div>
									</div>
								))}
							{!loading && dataSource?.keyValues.length === 0 && (
								<div className="h-full w-full flex items-center justify-center">
									No Key Values have been extracted
								</div>
							)}
							{loading && (
								<div className="h-full w-full flex justify-center items-center">
									<Spin />
								</div>
							)}
						</div>
					</div>

					{!dataSource?.tableData[currentPage - 1]?.parsedHtml && (
						<>
							{!loading ? (
								<div className="flex-1 overflow-y-hidden mx-8">
									{dataSource?.tableData.length > 0 ? (
										<>
											<div className="grid grid-cols-12 mb-2">
												<div className="col-span-7">
													<Typography.Paragraph
														ellipsis={{
															rows: 1,
															expandable: false,
															symbol: "more",
															tooltip:
																dataSource
																	?.tableData[
																	currentPage -
																		1
																]?.tableName,
														}}
														style={{
															fontSize: "0.9rem",
															fontWeight: 700,
															marginBottom:
																"0rem",
														}}
													>
														{
															dataSource
																?.tableData[
																currentPage - 1
															]?.tableName
														}
													</Typography.Paragraph>
												</div>
												<div className="col-span-4">
													<Pagination
														simple
														current={currentPage}
														total={
															dataSource
																?.tableData
																.length
														}
														pageSize={1}
														onChange={onPageChange}
														style={{
															float: "right",
														}}
														showSizeChanger={false}
													/>
												</div>
												<div className="flex justify-end mt-2 cursor-pointer">
													<Expand
														size={14}
														onClick={() =>
															showModal(1)
														}
													/>
												</div>
												<div className="col-span-4 text-sm font-semibold">
													Raw Table
												</div>
											</div>

											<div
												className="raw-table max-h-52 overflow-auto mb-2"
												dangerouslySetInnerHTML={{
													__html: dataSource
														?.tableData[
														currentPage - 1
													]?.data,
												}}
											></div>
										</>
									) : (
										<div className="w-full h-full flex items-center justify-center">
											No tables have been extracted
										</div>
									)}
								</div>
							) : (
								<Spin size="default" />
							)}
						</>
					)}

					{dataSource?.tableData[currentPage - 1]?.parsedHtml && (
						<div
							className="flex-1 overflow-y-hidden"
							id="table-container"
						>
							{(loading || dataSource?.tableData.length > 0) && (
								<div className="mx-8">
									<div className="grid grid-cols-12 mb-2">
										<div className="col-span-7">
											<Typography.Paragraph
												ellipsis={{
													rows: 1,
													expandable: false,
													symbol: "more",
													tooltip:
														dataSource?.tableData[
															currentPage - 1
														]?.tableName,
												}}
												style={{
													fontSize: "0.9rem",
													fontWeight: 700,
													marginBottom: "0rem",
												}}
											>
												{
													dataSource?.tableData[
														currentPage - 1
													]?.tableName
												}
											</Typography.Paragraph>
										</div>
										<div className="col-span-4">
											<Pagination
												simple
												current={currentPage}
												total={
													dataSource?.tableData.length
												}
												pageSize={1}
												onChange={onPageChange}
												style={{ float: "right" }}
												showSizeChanger={false}
											/>
										</div>
										<div className="flex justify-end mt-2 cursor-pointer">
											<Expand
												size={14}
												onClick={() => showModal(2)}
											/>
										</div>
										<div className="col-span-12 flex items-center justify-between">
											<div className="text-sm font-semibold">
												Parsed Table
											</div>
											<div
												className="text-sm text-text-zs-orange ml-4 cursor-pointer font-semibold float-right"
												onClick={() => showModal(1)}
											>
												Show raw table
											</div>
										</div>
									</div>
									<div>
										{tableContainerHeight > 0 && (
											<>
												<Table
													columns={
														htmlToJson(
															dataSource
																?.tableData[
																currentPage - 1
															]?.parsedHtml || []
														).columns
													}
													dataSource={JSON.parse(
														dataSource?.tableData[
															currentPage - 1
														]?.jsonData || "[]"
													)}
													pagination={false}
													size="small"
													style={{
														borderRadius: "8px",
														boxShadow:
															"0 3px 6px 0px rgba(0, 0, 0, 0.2)",
													}}
													scroll={{
														x:
															dataSource
																?.tableData[
																currentPage - 1
															]?.data &&
															Object.keys(
																dataSource
																	?.tableData[
																	currentPage -
																		1
																]?.data[0] || []
															).length > 4
																? "100vw"
																: undefined,
														y:
															tableContainerHeight -
															90,
													}}
													loading={loading}
												/>
												{/* <div
													className="raw-table max-h-44 overflow-auto mb-2"
													dangerouslySetInnerHTML={{
														__html: dataSource
															?.tableData[
															currentPage - 1
														]?.parsedHtml,
													}}
												></div> */}
											</>
										)}
									</div>
								</div>
							)}
						</div>
					)}

					{dataSource?.tableData.length > 0 && (
						<div className="grid grid-cols-8 items-center justify-between mx-8">
							<div className="flex items-center col-span-5">
								<div>Parse Table</div>&nbsp;
								<MyDropdown
									placeholder={"Table config"}
									options={options}
									value={
										(
											parsingType
												? parsingType
												: dataSource?.tableData[
														currentPage - 1
												  ]["parsedHtml"]
										)
											? parsingType
												? parsingType
												: dataSource?.tableData[
														currentPage - 1
												  ]?.tableType
											: undefined
									}
									onChange={setParsingType}
								/>
							</div>
							<div className="col-span-3 flex justify-end">
								<MyButton
									type="primary"
									onClick={() =>
										handleParse(
											parsingType
												? parsingType
												: parsingType
												? parsingType
												: dataSource?.tableData[
														currentPage - 1
												  ]?.tableType
												? parsingType
													? parsingType
													: dataSource?.tableData[
															currentPage - 1
													  ]?.tableType
												: -1,
											Number(
												dataSource?.tableData[
													currentPage - 1
												]?.Id || -1
											)
										)
									}
									disabled={
										!(parsingType
											? parsingType
											: dataSource?.tableData[
													currentPage - 1
											  ]?.parsedHtml
											? dataSource?.tableData[
													currentPage - 1
											  ]?.tableType
											: false)
									}
								>
									Parse
								</MyButton>
							</div>
						</div>
					)}
					<div
						className="mr-8 my-4"
						style={{
							display: "flex",
							justifyContent: "end",
							gap: "0.5rem",
						}}
					>
						{changed && (
							<>
								<MyButton
									type="default"
									onClick={handleDiscardChanges}
								>
									<Undo className="mb-1 mr-1" size={16} />
									Discard Changes
								</MyButton>
								<MyButton type="primary" onClick={handleSave}>
									<Save className="mb-1 mr-1" size={16} />
									Save
								</MyButton>
							</>
						)}
						<MyButton type="primary" onClick={handleSubmit}>
							<SaveArrowRight className="mb-1 mr-1" size={20} />
							{changed ? "Save & Submit" : "Submit"}
						</MyButton>

						{dataSource && (
							<DownloadExtract
								excelData={convertDataForDownload(dataSource)}
								fileName={`Extract_${
									state.selectedContract.id
								}_${state.selectedContract.name || "Contract"}`}
							>
								<Tooltip title="Only the parsed tables and key-value pairs will be downloaded">
									<MyButton type="primary">
										<Download
											className="mb-1 mr-1"
											size={16}
										/>
										Download
									</MyButton>
								</Tooltip>
							</DownloadExtract>
						)}
					</div>
				</div>
			) : (
				<ErrorPage />
			)}
			<MyModal
				title={isModalOpen === 1 ? "Raw Table" : "Parsed Table"}
				centered
				footer={null}
				open={isModalOpen !== 0}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose
				width={"80%"}
			>
				{isModalOpen === 1 && (
					<div
						className="raw-table max-h-96 overflow-auto mb-2"
						dangerouslySetInnerHTML={{
							__html: dataSource?.tableData[currentPage - 1]
								?.data,
						}}
					></div>
				)}
				{isModalOpen === 2 && (
					<>
						<Table
							columns={
								htmlToJson(
									dataSource?.tableData[currentPage - 1]
										?.parsedHtml || []
								).columns
							}
							dataSource={JSON.parse(
								dataSource?.tableData[currentPage - 1]
									?.jsonData || "[]"
							)}
							pagination={false}
							size="small"
							style={{
								borderRadius: "8px",
								boxShadow: "0 3px 6px 0px rgba(0, 0, 0, 0.2)",
							}}
							scroll={{
								x:
									dataSource?.tableData[currentPage - 1]
										?.data &&
									Object.keys(
										dataSource?.tableData[currentPage - 1]
											?.data[0] || []
									).length > 4
										? "100vw"
										: undefined,
								y: tableContainerHeight - 60,
							}}
							loading={loading}
						/>
						{/* <div
							className="raw-table max-h-96 overflow-auto mb-2"
							dangerouslySetInnerHTML={{
								__html: dataSource?.tableData[currentPage - 1]
									?.parsedHtml,
							}}
						></div> */}
					</>
				)}
			</MyModal>
		</>
	);
};

export default ContractReviewDetailsExtract;

/**HERE GOES THE OTHER CODE */

// import {
// 	Tag,
// 	Table,
// 	Pagination,
// 	Button,
// 	Input,
// 	Spin,
// 	message,
// 	Typography,
// } from "antd";
// import React, { useEffect, useState } from "react";
// import MyInput from "../../components/input";
// import MyButton from "../../components/buttons";
// import { Download } from "@styled-icons/bootstrap/Download";
// import { Save } from "@styled-icons/bootstrap/Save";
// import { SaveArrowRight } from "@styled-icons/fluentui-system-regular/SaveArrowRight";
// import DownloadExtract from "../../components/downloadExtract";
// import { useSelector } from "react-redux";
// import { selectStore } from "../../state/slice/appSlice";
// import { Undo } from "@styled-icons/material-rounded/Undo";
// import axios from "axios";
// import ErrorPage from "../errorPage";
// import { useNavigate } from "react-router-dom";
// import { Expand } from "@styled-icons/boxicons-regular/Expand";
// import MyDropdown from "../../components/dropdown";
// import MyModal from "../../components/modal";
// enum TableDataType {
// 	html = 1,
// 	json = 2,
// }
// const ContractReviewDetailsExtract = () => {
// 	const store = useSelector(selectStore);
// 	const navigate = useNavigate();
// 	const state = useSelector(selectStore);
// 	const [dataSource, setDataSource] = useState<any>();
// 	const [dataSourceCopy, setDataSourceCopy] = useState<any>();
// 	const [currentPage, setCurrentPage] = useState<any>(1);
// 	const [loading, setLoading] = useState(true);
// 	const [tableContainerHeight, setTableContainerHeight] = useState(0);
// 	const [changed, setChanged] = useState(false);
// 	const [saveData, setSaveData] = useState<any>({
// 		keyValues: [],
// 		tableData: [],
// 		isSubmit: false,
// 	});
// 	const [parsingType, setParsingType] = useState<number>();
// 	const [error, setError] = useState<boolean>(false);
// 	const [options, setOptions] =
// 		useState<{ label: string; value: string }[]>();
// 	const [isModalOpen, setIsModalOpen] = useState<number>(0);
// 	const showModal = (tableType: number) => {
// 		setIsModalOpen(tableType);
// 	};
// 	const handleOk = () => {
// 		setIsModalOpen(0);
// 	};
// 	const handleCancel = () => {
// 		setIsModalOpen(0);
// 	};
// 	const replaceTDsWithTHs = (htmlString: string) => {
// 		// Create a temporary div element to parse the HTML string
// 		var tempDiv = document.createElement("div");
// 		tempDiv.innerHTML = htmlString.trim();

// 		// Get all the table rows
// 		var rows = tempDiv.querySelectorAll("tr");

// 		// Find the index of the last row containing th
// 		var lastIndex = -1;
// 		for (var i = rows.length - 1; i >= 0; i--) {
// 			if (rows[i].querySelectorAll("th").length > 0) {
// 				lastIndex = i;
// 				break;
// 			}
// 		}

// 		// Loop through each row up to the last row containing th
// 		for (var i = 0; i < lastIndex; i++) {
// 			var cells = rows[i].querySelectorAll("td");

// 			// Loop through each cell in the row
// 			for (var j = 0; j < cells.length; j++) {
// 				// Create a new th element
// 				var thElement = document.createElement("th");
// 				// Copy the content of td to th
// 				thElement.innerHTML = cells[j].innerHTML;
// 				// Replace td with th
// 				cells[j].parentNode?.replaceChild(thElement, cells[j]);
// 			}
// 		}

// 		// Return the modified HTML
// 		return tempDiv.innerHTML;
// 	};
// 	function addColspan(htmlString: string) {
// 		// Create a temporary div element to parse the HTML string
// 		var tempDiv = document.createElement("div");
// 		tempDiv.innerHTML = htmlString;

// 		// Find all <th> elements
// 		var tableHeaders = tempDiv.querySelectorAll("table th");
// 		const cellIndex: number[] = [];
// 		for (var i = 0; i < tableHeaders.length; i++)
// 			//@ts-ignore
// 			cellIndex.push(tableHeaders[i].cellIndex);
// 		// Iterate through each <th> element to find the colspan for each pair
// 		for (var i = 0; i < tableHeaders.length; i++) {
// 			var currentHeader = tableHeaders[i];

// 			// If the current <th> is empty, find the preceding non-empty <th> to calculate colspan

// 			if (currentHeader.textContent?.trim() === "") {
// 				var { precedingHeader, idx } = findPrecedingNonEmptyHeader(
// 					tableHeaders,
// 					i
// 				);
// 				if (precedingHeader) {
// 					//@ts-ignore
// 					var colspan =
// 						//@ts-ignore
// 						cellIndex[i] - cellIndex[idx] + 1;
// 					precedingHeader.setAttribute("colspan", colspan);
// 					//@ts-ignore
// 					currentHeader.parentElement.removeChild(currentHeader);
// 				}
// 			}
// 		}

// 		// Return the modified HTML string
// 		return tempDiv.innerHTML;
// 	}

// 	// Function to find the preceding non-empty <th> element
// 	function findPrecedingNonEmptyHeader(headers: any, index: number) {
// 		for (var i = index - 1; i >= 0; i--) {
// 			if (headers[i].textContent.trim() !== "") {
// 				return { precedingHeader: headers[i], idx: i };
// 			}
// 		}
// 		return { precedingHeader: null, idx: -1 };
// 	}
// 	// const htmlToJson = (htmlTable: string) => {
// 	// 	htmlTable = addColspan(htmlTable);
// 	// 	if (htmlTable.length > 0) {
// 	// 		// // Convert HTML table to JSON
// 	// 		// const tableElement = document.createElement("div");
// 	// 		// tableElement.innerHTML = htmlTable;
// 	// 		// const rows = tableElement.getElementsByTagName("tr");
// 	// 		// const dataSource = [];
// 	// 		// const columns = [];

// 	// 		// let headerCells = rows[1].getElementsByTagName("th");
// 	// 		// for (let j = 0; j < headerCells.length; j++) {
// 	// 		// 	columns.push({
// 	// 		// 		title: headerCells[j].innerText,
// 	// 		// 		dataIndex: headerCells[j].innerText,
// 	// 		// 		key: headerCells[j].innerText,
// 	// 		// 	});
// 	// 		// }

// 	// 		// for (let i = 2; i < rows.length; i++) {
// 	// 		// 	const row = rows[i];
// 	// 		// 	const rowData = {};
// 	// 		// 	const cells = row.getElementsByTagName("td");
// 	// 		// 	for (let j = 0; j < cells.length; j++) {
// 	// 		// 		//@ts-ignore
// 	// 		// 		rowData[columns[j].dataIndex] = cells[j].innerText;
// 	// 		// 	}
// 	// 		// 	if (Object.keys(rowData).length > 0) {
// 	// 		// 		dataSource.push(rowData);
// 	// 		// 	}
// 	// 		// }

// 	// 		const table = document.createElement("table");

// 	// 		table.innerHTML = htmlTable;

// 	// 		const headerRows = table.querySelectorAll("tr");
// 	// 		const columns: any = [];
// 	// 		const columns2: any = [];

// 	// 		const dataSource: any = [];
// 	// 		let currentGroup: any = null;

// 	// 		headerRows.forEach((row, rowIndex) => {
// 	// 			const cells = row.querySelectorAll("th, td");
// 	// 			cells.forEach((cell, cellIndex) => {
// 	// 				//@ts-ignore
// 	// 				if (rowIndex === 0 && cellIndex === 0 && cell.colSpan > 1) {
// 	// 					currentGroup = {
// 	// 						title: cell.textContent?.trim(),
// 	// 						dataIndex: cell.textContent?.trim(), // Assigning dataIndex to the group title
// 	// 						key: cell.textContent?.trim(), // Assigning key to the group title
// 	// 						children: [],
// 	// 					};
// 	// 					columns.push(currentGroup);
// 	// 				} else if (
// 	// 					currentGroup &&
// 	// 					cellIndex < currentGroup.children.length
// 	// 				) {
// 	// 					currentGroup.children[cellIndex].title += "";
// 	// 				} else {
// 	// 					if (!currentGroup) {
// 	// 						currentGroup = {
// 	// 							title: "Undefined Group",
// 	// 							dataIndex: `group${columns.length}`, // Assigning dataIndex to the group
// 	// 							key: `group${columns.length}`, // Assigning key to the group
// 	// 							children: [],
// 	// 						};
// 	// 						columns.push(currentGroup);
// 	// 					}
// 	// 					const columnData = {
// 	// 						title: cell.textContent?.trim(),
// 	// 						dataIndex: cell.textContent?.trim(), // Assigning dataIndex to the column title
// 	// 						key: cell.textContent?.trim(), // Assigning key to the column title
// 	// 					};
// 	// 					currentGroup.children.push(columnData);
// 	// 				}
// 	// 			});
// 	// 		});
// 	// 		const rows = table.getElementsByTagName("tr");
// 	// 		let headerCells = rows[1].getElementsByTagName("th");
// 	// 		for (let j = 0; j < headerCells.length; j++) {
// 	// 			columns2.push({
// 	// 				title: headerCells[j].innerText,
// 	// 				dataIndex: headerCells[j].innerText,
// 	// 				key: headerCells[j].innerText,
// 	// 			});
// 	// 		}
// 	// 		for (let i = 2; i < rows.length; i++) {
// 	// 			const row = rows[i];
// 	// 			const rowData = {};
// 	// 			const cells = row.getElementsByTagName("td");
// 	// 			for (let j = 0; j < cells.length; j++) {
// 	// 				//@ts-ignore
// 	// 				rowData[columns2[j].dataIndex] = cells[j].innerText;
// 	// 			}
// 	// 			if (Object.keys(rowData).length > 0) {
// 	// 				dataSource.push(rowData);
// 	// 			}
// 	// 		}
// 	// 		console.log("COLUMN", columns);
// 	// 		return { columns, dataSource };
// 	// 	} else
// 	// 		return {
// 	// 			columns: [],
// 	// 			dataSource: [],
// 	// 		};
// 	// };

// 	// const findColumns = (
// 	// 	low: number,
// 	// 	high: number,
// 	// 	row: number,
// 	// 	max: number,
// 	// 	headerRows: any
// 	// ) => {
// 	// 	const col: any = [];
// 	// 	if (low >= high || row >= max) return [];
// 	// 	if (row < max) {
// 	// 		while (low < high && low < headerRows[row].children.length) {
// 	// 			let span = 0;
// 	// 			var previousSibling =
// 	// 				headerRows[row].children[low].previousSibling;

// 	// 			while (previousSibling) {
// 	// 				if (previousSibling.nodeType === 1) {
// 	// 					// Check if node is an element node
// 	// 					span += previousSibling.colSpan;
// 	// 				}
// 	// 				previousSibling = previousSibling.previousSibling;
// 	// 			}
// 	// 			const t = headerRows[row].children[low].textContent?.trim();
// 	// 			col.push({
// 	// 				title: headerRows[row].children[low].textContent?.trim(),
// 	// 				dataIndex:
// 	// 					headerRows[row].children[low].textContent?.trim(),
// 	// 				key: headerRows[row].children[low].textContent?.trim(),
// 	// 				index: headerRows[row].children[low].textContent?.trim(),
// 	// 				ellipsis: true,
// 	// 				render: (text: any, e: any, index: number) => (
// 	// 					<MyInput
// 	// 						value={text}
// 	// 						onChange={(e1) =>
// 	// 							handleTableChange(
// 	// 								e,
// 	// 								JSON.stringify(t),
// 	// 								e1.target.value,
// 	// 								currentPage,
// 	// 								index
// 	// 							)
// 	// 						}
// 	// 					/>
// 	// 				),
// 	// 				children: findColumns(
// 	// 					// low,
// 	// 					span > 0 ? span : low,
// 	// 					span > 0
// 	// 						? span + headerRows[row].children[low].colSpan
// 	// 						: low + headerRows[row].children[low].colSpan,
// 	// 					row + 1,
// 	// 					max,
// 	// 					headerRows
// 	// 				),
// 	// 			});
// 	// 			if (0 && headerRows[row].children[low].colSpan)
// 	// 				low = low + headerRows[row].children[low].colSpan;
// 	// 			else low++;
// 	// 		}
// 	// 		return col;
// 	// 	}
// 	// };

// 	const findColumns = (
// 		low: number,
// 		high: number,
// 		row: number,
// 		max: number,
// 		headerRows: any
// 	) => {
// 		let unnamedIndex = 1;
// 		const col: any = [];
// 		if (low >= high || row >= max) return [];
// 		if (row < max) {
// 			while (low < high && low < headerRows[row].children.length) {
// 				let span = 0;
// 				let rowSpan = 1; // Initialize rowSpan to 1
// 				const currentCell = headerRows[row].children[low];

// 				// Handle row span
// 				if (currentCell.rowSpan > 1) {
// 					rowSpan = currentCell.rowSpan;
// 				}

// 				var previousSibling = currentCell.previousSibling;

// 				while (previousSibling) {
// 					if (previousSibling.nodeType === 1) {
// 						// Check if node is an element node
// 						span += previousSibling.colSpan;
// 					}
// 					previousSibling = previousSibling.previousSibling;
// 				}
// 				const t = currentCell.textContent?.trim();
// 				col.push({
// 					title: t,
// 					dataIndex: t || unnamedIndex,
// 					key: t || unnamedIndex,
// 					index: t || unnamedIndex++,
// 					ellipsis: true,
// 					render: (text: any, e: any, index: number) => (
// 						<MyInput
// 							value={text}
// 							onChange={(e1) =>
// 								handleTableChange(
// 									e,
// 									JSON.stringify(t),
// 									e1.target.value,
// 									currentPage,
// 									index
// 								)
// 							}
// 						/>
// 					),
// 					children: findColumns(
// 						span > 0 ? span : low,
// 						span > 0
// 							? span + currentCell.colSpan
// 							: low + currentCell.colSpan,
// 						row + rowSpan, // Increment row by rowSpan
// 						max,
// 						headerRows
// 					),
// 				});
// 				if (0 && currentCell.colSpan) low = low + currentCell.colSpan;
// 				else low++;
// 			}
// 			return col;
// 		}
// 	};

// 	const htmlToJson = (htmlTable: string) => {
// 		try {
// 			htmlTable = replaceTDsWithTHs(htmlTable);
// 			htmlTable = addColspan(htmlTable);
// 			if (htmlTable.length > 0) {
// 				const table = document.createElement("table");

// 				table.innerHTML = htmlTable;

// 				let headerRows: any;
// 				headerRows = table.querySelectorAll("tr:has(th)");
// 				console.log("headerRows", headerRows);
// 				if (headerRows.length === 0)
// 					headerRows = [table.querySelectorAll("tr:has(td)")[0]];
// 				console.log("headerRows", headerRows);
// 				const columns: any = findColumns(
// 					0,
// 					headerRows[headerRows.length - 1].children.length,
// 					0,
// 					headerRows.length,
// 					headerRows
// 				);
// 				const columns2: any = [];

// 				const dataSource: any = [];
// 				let currentGroup: any = null;

// 				const rows = table.getElementsByTagName("tr");

// 				let unnamedIndex = 1;
// 				for (let i = 0; i < headerRows.length; i++) {
// 					let headerCells: any = rows[i].querySelectorAll("th,td");
// 					for (let j = 0; j < headerCells.length; j++) {
// 						if (i + headerCells[j].rowSpan === headerRows.length)
// 							columns2.push({
// 								title: headerCells[j].innerText,
// 								dataIndex:
// 									headerCells[j].innerText || unnamedIndex,
// 								key: headerCells[j].innerText || unnamedIndex++,
// 							});
// 					}
// 				}

// 				for (let i = headerRows.length; i < rows.length; i++) {
// 					const row = rows[i];
// 					const rowData = {};
// 					const cells = row.getElementsByTagName("td");
// 					for (let j = 0; j < cells.length; j++) {
// 						//@ts-ignore
// 						rowData[columns2[j].dataIndex] = cells[j].innerText;
// 					}
// 					if (Object.keys(rowData).length > 0) {
// 						dataSource.push(rowData);
// 					}
// 				}

// 				console.log("COLUMN", columns, columns2);
// 				return { columns, dataSource };
// 			} else
// 				return {
// 					columns: [],
// 					dataSource: [],
// 				};
// 		} catch (e) {
// 			console.log("Exception while making table", e);
// 			return { columns: [], dataSource: [] };
// 		}
// 	};

// 	const jsonParse = (jsonData: string, htmlTable: string) => {
// 		try {
// 			htmlTable = replaceTDsWithTHs(htmlTable);
// 			htmlTable = addColspan(htmlTable);
// 			if (htmlTable.length > 0) {
// 				const table = document.createElement("table");

// 				table.innerHTML = htmlTable;

// 				let headerRows: any;
// 				headerRows = table.querySelectorAll("tr:has(th)");
// 				console.log("headerRows", headerRows);
// 				if (headerRows.length === 0)
// 					headerRows = table.querySelectorAll("tr:has(td)")[0];

// 				const columns: any = findColumns(
// 					0,
// 					headerRows[headerRows.length - 1].children.length,
// 					0,
// 					headerRows.length,
// 					headerRows
// 				);
// 				const dataSource = JSON.parse(jsonData);
// 				return { columns, dataSource };
// 			} else
// 				return {
// 					columns: [],
// 					dataSource: [],
// 				};
// 		} catch (e) {
// 			console.log("Exception while making table", e);
// 			return { columns: [], dataSource: [] };
// 		}
// 	};
// 	const fetchData = async () => {
// 		setLoading(true);

// 		// const apiData = {
// 		// 	keyValues: [
// 		// 		{
// 		// 			label: "Rebate Percentage",
// 		// 			Id: "1",
// 		// 			value: "15%",
// 		// 			score: 0.9,
// 		// 			isHigh: true,
// 		// 		},
// 		// 		{
// 		// 			label: "SAP Number",
// 		// 			Id: "2",
// 		// 			value: "1232421",
// 		// 			score: 0.9,
// 		// 			isHigh: true,
// 		// 		},
// 		// 		{
// 		// 			label: "Party Name",
// 		// 			Id: "3",
// 		// 			value: "SyncIQ AI Integrations",
// 		// 			score: 0.4,
// 		// 			isHigh: false,
// 		// 		},
// 		// 	],
// 		// 	tableData: [
// 		// 		{
// 		// 			tableName: "T1",
// 		// 			Id: "1",
// 		// 			// data: [
// 		// 			// 	// { Id: 1, "S.No.": 1, "": "abc", Rebate: "5%" },
// 		// 			// 	// { Id: 2, "S.No.": 2, "": "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 3, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 4, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 5, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 6, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 7, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 8, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 9, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 10, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 11, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 12, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 13, "S.No.": 2, Drug: "def", Rebate: "5%" },
// 		// 			// 	// // { Id: 14, "S.No.": 2, Drug: "def", Rebate: "5%" },

// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Group Contact: ",
// 		// 			// 		"parent Name": "Deborah Bowers ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Title: ",
// 		// 			// 		"": "North American Meetings & Events Planner ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Company Name: ",
// 		// 			// 		"": "SAKO Materials Limited ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Address: ",
// 		// 			// 		"": "80 Stafford Drive ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "City, State, Zip: ",
// 		// 			// 		"": "Brampton, ON, L6W 1L4 ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Phone: ",
// 		// 			// 		"": "416-938-9887 ",
// 		// 			// 	},
// 		// 			// 	{
// 		// 			// 		"Especially Prepared for: ": "Email: ",
// 		// 			// 		"": "Deborah.bowers@iko.com ",
// 		// 			// 	},
// 		// 			// ],
// 		// 			data: `<table><tr><th>Parent Col</th><th></th></tr>
// 		// 			<tr><th>child row 1</th><th>child row 2</th></tr>
// 		// 			<tr><td>10% - < 20%</td><td>2.00%</td></tr>
// 		// 			<tr><td>20% - < 25%</td><td>5.00%</td></tr>
// 		// 			<tr><td>25% - < 30%</td><td>22.00%</td></tr>
// 		// 			</table>`,
// 		// 			jsonData:
// 		// 				'[{"child row 1":"10% - < 20%","child row 2":"2.00%wffwq"},{"child row 1":"20% - < 25%","child row 2":"5.00%"},{"child row 1":"25% - < 30%","child row 2":"22.00%wfwqfq"}]',
// 		// 		},
// 		// 		{
// 		// 			tableName: "T2",
// 		// 			Id: "2",
// 		// 			data: `<table>
// 		// 			<tr>
// 		// 			   <th colspan="2">GENDRGQ Quarterly Rebate Table1</th>
// 		//                <th rowspan="3">GENDRGQ Quarterly Rebate Rate3</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <th colspan="2">GENDRGQ Product Share in the applicable Quarter2</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <th>GENDRGQ Product Share in the applicable Quarter4</th>
// 		// 			   <th>abc5</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>5% - < 10%</td>
// 		// 			   <td>123</td>
// 		// 			   <td>6.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>10% - < 15%</td>
// 		// 			   <td>456</td>
// 		// 			   <td>8.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>15% - < 20%</td>
// 		// 			   <td>789</td>
// 		// 			   <td>12.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>> 20%</td>
// 		// 			   <td>1a1</td>
// 		// 			   <td>13.33%</td>
// 		// 			</tr>
// 		// 		 </table>`,
// 		// 			jsonData: null,
// 		// 		},
// 		// 		{
// 		// 			tableName: "T3",
// 		// 			Id: "3",
// 		// 			data: `<table>
// 		// 			<tr>
// 		// 			   <th colspan="3">GENDRGQ Quarterly Rebate Table1</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <th colspan="2">GENDRGQ Product Share in the applicable Quarter2</th>
// 		// 			   <th>GENDRGQ Quarterly Rebate Rate3</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <th>GENDRGQ Product Share in the applicable Quarter4</th>
// 		// 			   <th>abc5</th>
// 		// 			   <th>GENDRGQ Quarterly Rebate Rate6</th>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>5% - < 10%</td>
// 		// 			   <td>123</td>
// 		// 			   <td>6.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>10% - < 15%</td>
// 		// 			   <td>456</td>
// 		// 			   <td>8.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>15% - < 20%</td>
// 		// 			   <td>789</td>
// 		// 			   <td>12.00%</td>
// 		// 			</tr>
// 		// 			<tr>
// 		// 			   <td>> 20%</td>
// 		// 			   <td>1a1</td>
// 		// 			   <td>13.33%</td>
// 		// 			</tr>
// 		// 		 </table>`,
// 		// 			jsonData: null,
// 		// 		},
// 		// 		{
// 		// 			tableName: "T4",
// 		// 			Id: "4",
// 		// 			data: `<table><tr><td>Invoice Discount Table</td><td></td></tr>
// 		// 			<tr><th>GENDRGX Product Share <50%</th><th>GENDRGX Product Share > 50%</th></tr>
// 		// 			<tr><td>0.54%</td><td>76%</td></tr>
// 		// 			</table>`,
// 		// 			jsonData: null,
// 		// 		},
// 		// 	],
// 		// };
// 		// const convertedTableData: any = [];
// 		// apiData.tableData.map((table) => {
// 		// 	convertedTableData.push({
// 		// 		...table,
// 		// 		jsonData: table.jsonData
// 		// 			? table.jsonData
// 		// 			: JSON.stringify(htmlToJson(table.data).dataSource),
// 		// 	});
// 		// });
// 		// apiData.tableData = convertedTableData;

// 		axios
// 			.get(
// 				"https://intelligent-automation.api.demo.zsservices.com/document_extract/" +
// 					state.selectedContract.id
// 			)
// 			.then((res) => {
// 				const convertedTableData: any = [];
// 				res.data.tableData.map((table: any) => {
// 					convertedTableData.push({
// 						...table,
// 						jsonData: table.jsonData
// 							? table.jsonData
// 							: table.parsedHtml
// 							? JSON.stringify(
// 									htmlToJson(table.parsedHtml).dataSource
// 							  )
// 							: "[]",
// 					});
// 				});
// 				res.data.tableData = convertedTableData;
// 				setDataSource(res.data);
// 				setDataSourceCopy(res.data);
// 				// setDataSource(apiData);
// 				// setDataSourceCopy(apiData);
// 				setLoading(false);
// 				const opt = res.data.tableTypes;
// 				setOptions(opt);
// 			})
// 			.catch((e) => setError(true));
// 	};

// 	const handleParse = (tableType: number, tableId: number) => {
// 		const payload = {
// 			tableId: tableType,
// 			entity_id: tableId,
// 			doc_id: store.selectedContract.id,
// 			doc_name: store.selectedContract.name,
// 		};
// 		const key = "updatable";
// 		message.loading({
// 			content: "Parsing...",
// 			key: key,
// 		});
// 		axios
// 			.post(process.env.REACT_APP_API_URL + "/get_parsed_html", payload)
// 			.then((res) => {
// 				if (res.data) {
// 					message.success({
// 						content: "Parsed successfully!",
// 						key: key,
// 					});
// 					const temp = JSON.parse(JSON.stringify(dataSource));
// 					const tempCopy = JSON.parse(JSON.stringify(dataSourceCopy));

// 					const idx = temp?.tableData.findIndex(
// 						(el: any) => el.Id === tableId
// 					);

// 					if (idx != -1) {
// 						temp.tableData[idx].parsedHtml = res.data;
// 						temp.tableData[idx].tableType = tableType;
// 						tempCopy.tableData[idx].parsedHtml = res.data;
// 						tempCopy.tableData[idx].tableType = tableType;
// 					}

// 					setDataSource(temp);
// 					setDataSourceCopy(tempCopy);
// 				} else
// 					message.error({
// 						content: "Error parsing table",
// 						key: key,
// 					});
// 			})
// 			.catch((e) =>
// 				message.error({
// 					content: "Error parsing table",
// 					key: key,
// 				})
// 			);
// 	};
// 	const handleSubmit = () => {
// 		const payload = JSON.parse(JSON.stringify(saveData));
// 		payload.isSubmit = true;
// 		console.log("SUBMIT DATA", payload);
// 		const key = "updatable";
// 		axios
// 			.post(
// 				process.env.REACT_APP_API_URL +
// 					"/save_document_extract/" +
// 					state.selectedContract.id,
// 				payload
// 			)
// 			.then((res) => {
// 				if (res.data.data) {
// 					message.success({
// 						content: "Submitted successfully!",
// 						key: key,
// 					});
// 					navigate("./..");
// 				} else
// 					message.error({
// 						content: "Error submitting extract",
// 						key: key,
// 					});
// 			})
// 			.catch((e) =>
// 				message.error({
// 					content: "Error submitting extract",
// 					key: key,
// 				})
// 			);
// 	};
// 	const handleSave = () => {
// 		const payload = JSON.parse(JSON.stringify(saveData));
// 		console.log("SAVE DATA", payload);
// 		const key = "updatable";
// 		message.loading({ content: "Saving...", key: key });
// 		axios
// 			.post(
// 				process.env.REACT_APP_API_URL +
// 					"/save_document_extract/" +
// 					state.selectedContract.id,
// 				payload
// 			)
// 			.then((res) => {
// 				if (res.data.data) {
// 					message.success({
// 						content: "Saved successfully!",
// 						key: key,
// 					});
// 					setChanged(false);
// 				} else
// 					message.error({
// 						content: "Error saving extract",
// 						key: key,
// 					});
// 			})
// 			.catch((e) =>
// 				message.error({
// 					content: "Error saving extract",
// 					key: key,
// 				})
// 			);
// 	};
// 	const handleDiscardChanges = () => {
// 		// setLoading(true);
// 		setDataSource(dataSourceCopy);
// 		setChanged(false);
// 		setSaveData({ keyValues: [], tableData: [], isSubmit: false });
// 	};
// 	const handleTableChange = (
// 		e: any,
// 		key: string,
// 		text: string,
// 		currentPage: number,
// 		index: number
// 	) => {
// 		key = JSON.parse(key);
// 		console.log("EEE", document.getElementsByTagName("table")[1]);
// 		setChanged(true);

// 		const tempDataSource = JSON.parse(JSON.stringify(dataSource));
// 		// const idx = tempDataSource.tableData[currentPage - 1]["data"].findIndex(
// 		// 	(el: any) => el.Id === e.Id
// 		// );
// 		if (1 || index != -1) {
// 			const temp = JSON.parse(
// 				tempDataSource.tableData[currentPage - 1]["jsonData"]
// 			);
// 			temp[index][key] = text;
// 			tempDataSource.tableData[currentPage - 1]["jsonData"] =
// 				JSON.stringify(temp);
// 			setDataSource(tempDataSource);
// 		}

// 		const tempSaveData = JSON.parse(JSON.stringify(saveData));
// 		const idx2 = tempSaveData.tableData.findIndex(
// 			(el: any) => el.Id === tempDataSource.tableData[currentPage - 1].Id
// 		);
// 		if (idx2 === -1)
// 			tempSaveData.tableData.push(
// 				tempDataSource.tableData[currentPage - 1]
// 			);
// 		else
// 			tempSaveData.tableData[idx2] =
// 				tempDataSource.tableData[currentPage - 1];
// 		setSaveData(tempSaveData);
// 	};
// 	const handleKeyValueChange = (
// 		Id: number | string,
// 		label: string,
// 		value: string
// 	) => {
// 		setChanged(true);
// 		const tempDataSource = JSON.parse(JSON.stringify(dataSource));

// 		const idx = tempDataSource.keyValues.findIndex(
// 			(el: any) => el.Id === Id
// 		);
// 		if (idx != -1) {
// 			tempDataSource.keyValues[idx].value = value;
// 			setDataSource(tempDataSource);
// 		}
// 		const tempSaveData = JSON.parse(JSON.stringify(saveData));
// 		const idx2 = tempSaveData.keyValues.findIndex(
// 			(el: any) => el.Id === Id
// 		);
// 		if (idx2 === -1)
// 			tempSaveData.keyValues.push(tempDataSource.keyValues[idx]);
// 		else tempSaveData.keyValues[idx2] = tempDataSource.keyValues[idx2];
// 		setSaveData(tempSaveData);
// 	};
// 	const convertDataForDownload = (data: any) => {
// 		const output: { sheetName: string; data: any }[] = [];
// 		if (data.keyValues && data.keyValues.length > 0) {
// 			const keyValueData: any = [];
// 			data.keyValues.map((row: any) => {
// 				keyValueData.push({
// 					Label: row.label,
// 					Value: row.value,
// 				});
// 			});
// 			output.push({ sheetName: "Key Values", data: keyValueData });
// 		}
// 		if (data.tableData && data.tableData.length > 0) {
// 			data.tableData.map((table: any, index: number) => {
// 				if (table.jsonData !== "[]")
// 					output.push({
// 						sheetName: index + table.tableName,
// 						data: JSON.parse(table.jsonData),
// 					});
// 			});
// 		}
// 		return output;
// 	};
// 	const createColumns = () => {
// 		const cols: any[] = [];
// 		if (dataSource?.tableData[currentPage - 1]?.data?.length > 0)
// 			Object.keys(
// 				dataSource?.tableData[currentPage - 1]?.data[0] || {}
// 			).map((key, index) => {
// 				if (key !== "Id")
// 					cols.push({
// 						title: key.startsWith("unnamed") ? "" : key,
// 						dataIndex: key,
// 						render: (text: any, e: any, index: number) => (
// 							<MyInput
// 								value={text}
// 								onChange={(e1) =>
// 									handleTableChange(
// 										e,
// 										key,
// 										e1.target.value,
// 										currentPage,
// 										index
// 									)
// 								}
// 							/>
// 						),
// 						ellipsis: true,
// 					});
// 			});
// 		console.log("COLS", cols);
// 		return cols;
// 	};
// 	const onPageChange = (page: any) => {
// 		setCurrentPage(page);
// 	};
// 	useEffect(() => {
// 		if (state.selectedContract.id !== -1) fetchData();
// 	}, [state.selectedContract.id]);
// 	useEffect(() => {
// 		const tableHeight =
// 			document.getElementById("table-container")?.scrollHeight || 0;
// 		const headerHeight =
// 			document
// 				.getElementById("table-container")
// 				?.getElementsByTagName("thead")[0]?.scrollHeight || 0;
// 		setTableContainerHeight(tableHeight - headerHeight);
// 	}, [dataSource, currentPage]);
// 	return (
// 		<>
// 			{!error ? (
// 				<div
// 					style={{
// 						display: "flex",
// 						flexDirection: "column",
// 						justifyContent: "space-between",
// 						height: "100%",
// 					}}
// 				>
// 					{/** Uncomment for Confidence Score */}
// 					{/* <div
// 						className="mr-8 mt-4"
// 						style={{ display: "flex", justifyContent: "end" }}
// 					>
// 						<Tag
// 							bordered={false}
// 							color={"success"}
// 							className="mr-1"
// 						>
// 							High CS
// 						</Tag>
// 						<Tag bordered={false} color={"error"}>
// 							Low CS
// 						</Tag>
// 					</div> */}

// 					<div className="h-48 my-4">
// 						{!loading && dataSource?.keyValues.length > 0 && (
// 							<p
// 								className="ml-8 mt-4"
// 								style={{ fontSize: "14px", fontWeight: 700 }}
// 							>
// 								Key Values
// 							</p>
// 						)}
// 						<div className="h-40 overflow-y-auto p-4">
// 							{!loading &&
// 								dataSource?.keyValues.map((el: any) => (
// 									<div className="my-2 mx-4 grid grid-cols-8 items-center">
// 										<div
// 											className="col-span-2 text-ellipsis overflow-hidden whitespace-nowrap"
// 											style={{ fontSize: "14px" }}
// 										>
// 											{/* {el.label + "djoaodjojdaodj"} */}
// 											<Typography.Paragraph
// 												ellipsis={{
// 													rows: 1,
// 													expandable: false,
// 													symbol: "more",
// 													tooltip: el.label,
// 												}}
// 												style={{ margin: "0" }}
// 											>
// 												{el.label}
// 											</Typography.Paragraph>
// 										</div>
// 										<div className="col-span-6 mr-1">
// 											{el.isDefault ? (
// 												<Typography.Paragraph
// 													ellipsis={{
// 														rows: 1,
// 														expandable: false,
// 														symbol: "more",
// 														tooltip: el.value,
// 													}}
// 													style={{ margin: "0" }}
// 												>
// 													{el.value}
// 												</Typography.Paragraph>
// 											) : (
// 												<MyInput
// 													disabled={el.isDefault}
// 													value={el.value}
// 													onChange={(e) =>
// 														handleKeyValueChange(
// 															el.Id,
// 															el.label,
// 															e.target.value
// 														)
// 													}
// 												></MyInput>
// 											)}
// 										</div>
// 										{/*Uncomment for Confidence Score */}
// 										{/* <div className="col-span-1">
// 											<Tag
// 												bordered={false}
// 												color={
// 													el.isHigh === true
// 														? "success"
// 														: "error"
// 												}
// 											>
// 												{el.score}
// 											</Tag>
// 										</div> */}
// 										<div className="col-span-3"></div>
// 									</div>
// 								))}
// 							{loading && (
// 								<div className="h-full w-full flex justify-center items-center">
// 									<Spin />
// 								</div>
// 							)}
// 						</div>
// 					</div>

// 					{!dataSource?.tableData[currentPage - 1]?.parsedHtml && (
// 						<>
// 							{!loading ? (
// 								<div className="flex-1 overflow-y-hidden mx-8">
// 									<div className="grid grid-cols-12 mb-2">
// 										<div className="col-span-7">
// 											<Typography.Paragraph
// 												ellipsis={{
// 													rows: 1,
// 													expandable: false,
// 													symbol: "more",
// 													tooltip:
// 														dataSource?.tableData[
// 															currentPage - 1
// 														]?.tableName,
// 												}}
// 												style={{
// 													fontSize: "0.9rem",
// 													fontWeight: 700,
// 													marginBottom: "0rem",
// 												}}
// 											>
// 												{
// 													dataSource?.tableData[
// 														currentPage - 1
// 													]?.tableName
// 												}
// 											</Typography.Paragraph>
// 										</div>
// 										<div className="col-span-4">
// 											<Pagination
// 												simple
// 												current={currentPage}
// 												total={
// 													dataSource?.tableData.length
// 												}
// 												pageSize={1}
// 												onChange={onPageChange}
// 												style={{ float: "right" }}
// 												showSizeChanger={false}
// 											/>
// 										</div>
// 										<div className="flex justify-end mt-2 cursor-pointer">
// 											<Expand
// 												size={14}
// 												onClick={() => showModal(1)}
// 											/>
// 										</div>
// 										<div className="col-span-4 text-sm font-semibold">
// 											Raw Table
// 										</div>
// 									</div>

// 									<div
// 										className="raw-table max-h-52 overflow-auto mb-2"
// 										dangerouslySetInnerHTML={{
// 											__html: dataSource?.tableData[
// 												currentPage - 1
// 											]?.data,
// 										}}
// 									></div>
// 								</div>
// 							) : (
// 								<Spin size="default" />
// 							)}
// 						</>
// 					)}

// 					{dataSource?.tableData[currentPage - 1]?.parsedHtml && (
// 						<div
// 							className="flex-1 overflow-y-hidden"
// 							id="table-container"
// 						>
// 							{(loading || dataSource?.tableData.length > 0) && (
// 								<div className="mx-8">
// 									<div className="grid grid-cols-12 mb-2">
// 										<div className="col-span-7">
// 											<Typography.Paragraph
// 												ellipsis={{
// 													rows: 1,
// 													expandable: false,
// 													symbol: "more",
// 													tooltip:
// 														dataSource?.tableData[
// 															currentPage - 1
// 														]?.tableName,
// 												}}
// 												style={{
// 													fontSize: "0.9rem",
// 													fontWeight: 700,
// 													marginBottom: "0rem",
// 												}}
// 											>
// 												{
// 													dataSource?.tableData[
// 														currentPage - 1
// 													]?.tableName
// 												}
// 											</Typography.Paragraph>
// 										</div>
// 										<div className="col-span-4">
// 											<Pagination
// 												simple
// 												current={currentPage}
// 												total={
// 													dataSource?.tableData.length
// 												}
// 												pageSize={1}
// 												onChange={onPageChange}
// 												style={{ float: "right" }}
// 												showSizeChanger={false}
// 											/>
// 										</div>
// 										<div className="flex justify-end mt-2 cursor-pointer">
// 											<Expand
// 												size={14}
// 												onClick={() => showModal(2)}
// 											/>
// 										</div>
// 										<div className="col-span-12 flex items-center justify-between">
// 											<div className="text-sm font-semibold">
// 												Parsed Table
// 											</div>
// 										</div>
// 									</div>
// 									<div>
// 										{tableContainerHeight > 0 && (
// 											<>
// 												{/* <Table
// 												columns={
// 													htmlToJson(
// 														dataSource?.tableData[
// 															currentPage - 1
// 														]?.parsedHtml || []
// 													).columns
// 												}
// 												dataSource={JSON.parse(
// 													dataSource?.tableData[
// 														currentPage - 1
// 													]?.jsonData || "[]"
// 												)}
// 												pagination={false}
// 												size="small"
// 												style={{
// 													borderRadius: "8px",
// 													boxShadow:
// 														"0 3px 6px 0px rgba(0, 0, 0, 0.2)",
// 												}}
// 												scroll={{
// 													x:
// 														dataSource?.tableData[
// 															currentPage - 1
// 														]?.data &&
// 														Object.keys(
// 															dataSource
// 																?.tableData[
// 																currentPage - 1
// 															]?.data[0] || []
// 														).length > 4
// 															? "100vw"
// 															: undefined,
// 													y:
// 														tableContainerHeight -
// 														60,
// 												}}
// 												loading={loading}
// 												/> */}
// 												<div
// 													className="raw-table max-h-44 overflow-auto mb-2"
// 													dangerouslySetInnerHTML={{
// 														__html: dataSource
// 															?.tableData[
// 															currentPage - 1
// 														]?.parsedHtml,
// 													}}
// 												></div>
// 											</>
// 										)}
// 									</div>
// 									<div
// 										className="text-sm text-text-zs-orange ml-4 cursor-pointer font-semibold float-right mt-1"
// 										onClick={() => showModal(1)}
// 									>
// 										Show raw table
// 									</div>
// 								</div>
// 							)}
// 						</div>
// 					)}

// 					<div className="grid grid-cols-8 items-center justify-between mx-8">
// 						<div className="flex items-center col-span-5">
// 							<div>Parse Table</div>&nbsp;
// 							<MyDropdown
// 								placeholder={"Table config"}
// 								options={options}
// 								defaultValue={
// 									parsingType
// 										? parsingType
// 										: dataSource?.tableData[currentPage - 1]
// 												?.tableType
// 								}
// 								onChange={setParsingType}
// 							/>
// 						</div>
// 						<div className="col-span-3 flex justify-end">
// 							<MyButton
// 								type="primary"
// 								onClick={() =>
// 									handleParse(
// 										parsingType || -1,
// 										Number(
// 											dataSource?.tableData[
// 												currentPage - 1
// 											]?.Id || -1
// 										)
// 									)
// 								}
// 								disabled={!parsingType}
// 							>
// 								Parse
// 							</MyButton>
// 						</div>
// 					</div>
// 					<div
// 						className="mr-8 my-4"
// 						style={{
// 							display: "flex",
// 							justifyContent: "end",
// 							gap: "0.5rem",
// 						}}
// 					>
// 						{changed && (
// 							<>
// 								<MyButton
// 									type="default"
// 									onClick={handleDiscardChanges}
// 								>
// 									<Undo className="mb-1 mr-1" size={16} />
// 									Discard Changes
// 								</MyButton>
// 								<MyButton type="primary" onClick={handleSave}>
// 									<Save className="mb-1 mr-1" size={16} />
// 									Save
// 								</MyButton>
// 							</>
// 						)}
// 						<MyButton type="primary" onClick={handleSubmit}>
// 							<SaveArrowRight className="mb-1 mr-1" size={20} />
// 							{changed ? "Save & Submit" : "Submit"}
// 						</MyButton>

// 						{dataSource && (
// 							<DownloadExtract
// 								excelData={convertDataForDownload(dataSource)}
// 								fileName={`Extract_${
// 									state.selectedContract.id
// 								}_${state.selectedContract.name || "Contract"}`}
// 							>
// 								<MyButton type="primary">
// 									<Download className="mb-1 mr-1" size={16} />
// 									Download
// 								</MyButton>
// 							</DownloadExtract>
// 						)}
// 					</div>
// 				</div>
// 			) : (
// 				<ErrorPage />
// 			)}
// 			<MyModal
// 				title={isModalOpen === 1 ? "Raw Table" : "Parsed Table"}
// 				centered
// 				footer={null}
// 				open={isModalOpen !== 0}
// 				onOk={handleOk}
// 				onCancel={handleCancel}
// 				destroyOnClose
// 				width={"80%"}
// 			>
// 				{isModalOpen === 1 && (
// 					<div
// 						className="raw-table max-h-96 overflow-auto mb-2"
// 						dangerouslySetInnerHTML={{
// 							__html: dataSource?.tableData[currentPage - 1]
// 								?.data,
// 						}}
// 					></div>
// 				)}
// 				{isModalOpen === 2 && (
// 					<>
// 						{/* <Table
// 					columns={
// 						htmlToJson(
// 							dataSource?.tableData[
// 								currentPage - 1
// 							]?.parsedHtml || []
// 						).columns
// 					}
// 					dataSource={JSON.parse(
// 						dataSource?.tableData[
// 							currentPage - 1
// 						]?.jsonData || "[]"
// 					)}
// 					pagination={false}
// 					size="small"
// 					style={{
// 						borderRadius: "8px",
// 						boxShadow:
// 							"0 3px 6px 0px rgba(0, 0, 0, 0.2)",
// 					}}
// 					scroll={{
// 						x:
// 							dataSource?.tableData[
// 								currentPage - 1
// 							]?.data &&
// 							Object.keys(
// 								dataSource
// 									?.tableData[
// 									currentPage - 1
// 								]?.data[0] || []
// 							).length > 4
// 								? "100vw"
// 								: undefined,
// 						y:
// 							tableContainerHeight -
// 							60,
// 					}}
// 					loading={loading}
// 					/> */}
// 						<div
// 							className="raw-table max-h-96 overflow-auto mb-2"
// 							dangerouslySetInnerHTML={{
// 								__html: dataSource?.tableData[currentPage - 1]
// 									?.parsedHtml,
// 							}}
// 						></div>
// 					</>
// 				)}
// 			</MyModal>
// 		</>
// 	);
// };

// export default ContractReviewDetailsExtract;
