import React, { useEffect, useRef, useState } from "react";

export const Cursor = (props: { text: string }) => {
	const [showCursor, setShowCursor] = useState(true);
	const lastLineRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Toggle cursor visibility every 500 milliseconds
		const interval = setInterval(() => {
			setShowCursor((prevShowCursor) => !prevShowCursor);
		}, 500);

		return () => clearInterval(interval); // Cleanup interval on component unmount
	}, []);

	useEffect(() => {
		const handleResize = () => {
			// Force re-render to recalculate cursor position
			setShowCursor((prevShowCursor) => !prevShowCursor);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const calculateCursorLeft = () => {
		if (lastLineRef.current) {
			const lastLineWidth = lastLineRef.current.offsetWidth;
			return lastLineWidth;
		}
		return 0;
	};

	// Split text into lines
	const lines = props.text.split("\n");
	// Get the last line
	const lastLine = lines[lines.length - 1];

	return (
		<div className="flex justify-center items-center h-full">
			<div className="relative h-full">
				{/* Map through each line */}
				{lines.map((line, index) => (
					<div key={index}>
						{/* Check if it's the last line */}
						{index === lines.length - 1 ? (
							<div ref={lastLineRef}>
								{/* Map through each word in the last line */}
								{line
									.split(" ")
									.map((word, wordIndex, array) => (
										<React.Fragment key={wordIndex}>
											{word}
											{wordIndex !== array.length - 1 &&
												" "}{" "}
											{/* Add space except for the last word */}
										</React.Fragment>
									))}
							</div>
						) : (
							line // Render other lines normally
						)}
						{/* Add line break except for the last line */}
						{index !== lines.length - 1 && <br />}
					</div>
				))}
				{showCursor && (
					<span
						className="absolute bottom-0 mt-px w-2 h-2 rounded-full bg-text-zs-orange opacity-90 animate-blink"
						style={{ left: `${calculateCursorLeft()}px` }}
					></span>
				)}
			</div>
		</div>
	);
};

export default Cursor;
