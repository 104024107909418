import { Button, ButtonProps } from "antd";
import React from "react";
import styles from "./style.module.css";
const MyButton = (props: ButtonProps) => {
	const { type, ...rest } = props;
	return (
		<Button
			{...rest}
			className={
				type === "primary"
					? styles.primary
					: type === "default"
					? styles.default
					: ""
			}
		>
			{props.children}
		</Button>
	);
};

export default MyButton;
