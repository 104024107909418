import { Breadcrumb, Spin, Segmented, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MyButton from "../../components/buttons";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { selectStore, setContractDetails } from "../../state/slice/appSlice";
import "./index.css";
import PdfView from "../../components/pdfView";
import axios from "axios";

const ContractReviewDetails = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const store = useSelector(selectStore);

	const [loading, setLoading] = useState(true);
	const [presignedUrl, setPresignedUrl] = useState("");

	const [selectedTab, setSelectedTab] = useState("");

	const fetchData = async () => {
		axios
			.get(
				process.env.REACT_APP_API_URL +
					"/pre_signed_url/" +
					store.selectedContract.name +
					"/" +
					store.userDetails.username
				// +
				// ".pdf"
				// "dbschema.pdf"
			)
			.then((res: any) => {
				if (res.data) {
					console.log("PRESIGNED URL", res.data);
					setPresignedUrl(res.data);
				}
				setLoading(false);
			});
	};
	useEffect(() => {
		setLoading(false);
		if (store.selectedContract.name) fetchData();
		if (store.selectedContract.id === -1)
			dispatch(
				setContractDetails({
					name: sessionStorage.getItem("contractName") || "",
					id: Number(sessionStorage.getItem("contractId")) || -1,
				})
			);
	}, [store.selectedContract.name]);

	const contractOption = (val: any) => {
		if (val === "Extract") {
			navigate("");
		} else if (val === "Chat") {
			navigate("chat");
		}
	};

	useEffect(() => {
		const endPath = location.pathname.substring(
			location.pathname.lastIndexOf("/") + 1
		);
		setSelectedTab(endPath);
		console.log("END PATH", endPath);
	}, [location.pathname]);

	return (
		<Spin spinning={loading}>
			<div className="h-screen overflow-hidden p-4">
				<Breadcrumb
					separator=">"
					items={[
						{
							title: "Contract Review",
							className: "cursor-pointer",
							onClick: () => navigate("./.."),
						},
						{
							title: store.selectedContract.name.split(".pdf")[0],
						},
					]}
				/>
				<div className="p-4 grid grid-cols-2 gap-4 h-full">
					{/* <div className="bg-gray-200"></div> */}
					<div className="Example__container__document">
						<PdfView presignedUrl={presignedUrl} />
					</div>
					<div className="">
						<div style={{ height: "3%", fontWeight: 600 }}>
							{/* {store.selectedContract.name} */}
							<Typography.Paragraph
								ellipsis={{
									rows: 1,
									expandable: false,
									symbol: "more",
									tooltip:
										store.selectedContract.name.split(
											".pdf"
										)[0],
								}}
								style={{
									fontWeight: 600,
									margin: 0,
									fontSize: "1rem",
								}}
							>
								{store.selectedContract.name.split(".pdf")[0]}
							</Typography.Paragraph>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "10%",
							}}
						>
							<Segmented
								value={
									selectedTab === "chat" ? "Chat" : "Extract"
								}
								options={["Extract", "Chat"]}
								onChange={(value) => contractOption(value)}
							/>
						</div>

						<div
							style={{ height: "84%", background: "#F8F9FAFF" }}
							id="extract-outlet"
						>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</Spin>
	);
};

export default ContractReviewDetails;
