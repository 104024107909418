import "./App.css";
import { Provider } from "react-redux";
import { store } from "./state/store";
import Home from "./routes/Home";
import axios from "axios";

export const request = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});
function App() {
	request.interceptors.request.use((config) => reqConfig(config));
	request.interceptors.response.use(
		(response) => responseHandler(response),
		(error) => errorHandler(error)
	);
	const reqConfig = (config: any) => {
		config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
		return config;
	};
	const responseHandler = (response: any) => {
		return response;
	};
	const errorHandler = (error: any) => {
		console.error("ERR...", error.response.status);
		// if (error.response.status === 401)
		// 	window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
		return Promise.reject(error);
	};
	return (
		<Provider store={store}>
			<Home />
		</Provider>
	);
}

export default App;
