import React, { useState, useEffect, useRef } from 'react';
import { QA, Pdf, Ticket, Table, Sop, transcript } from '../../model/IAudioTranscription';
import './index.css';
import { Tag, Button, Card, List, Typography, Divider, Table as AntTable } from 'antd';
import { UnorderedListOutlined, FileTextOutlined, ArrowRightOutlined } from '@ant-design/icons';
import TicketSlider from '../ticketSlider';
import QuestionEmailResponseSlider from '../questionEmailSlider';
import EditQuestionSlider from '../editQuestionSlider';
import ViewTranscriptSlider from '../viewTranscriptSlider';
import { useSelector } from "react-redux";
import { selectStore } from "../../state/slice/appSlice";
import ChatBot from '../chatbot';
import { Modal, Form, Input } from 'antd';
import { store } from '../../state/store';

const { Title } = Typography;

interface QASectionProps {
  qa: QA[];
  transcript: transcript[];
  onQuestionClick: (id: number, index: number, startTime: string, resources: {
    pdfs: Pdf[];
    tables: Table[];
    tickets: Ticket[];
    sops: Sop[];
  }) => void;
}

interface FormValues {
  question: string;
}

const QASection: React.FC<QASectionProps> = ({ qa, transcript, onQuestionClick }) => {
  const store = useSelector(selectStore);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [transcriptReferences, setTranscriptReferences] = useState<transcript[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [isTicketSliderVisible, setIsTicketSliderVisible] = useState(false);
  const [isQuestionEmailSliderVisible, setIsQuestionEmailSliderVisible] = useState(false);
  const [isEmailSliderVisible, setIsEmailSliderVisible] = useState<boolean>(false);
  const [isEditQuestionSliderVisible, setIsEditQuestionSliderVisible] = useState(false);
  const [isTranscriptSliderVisible, setIsTranscriptSliderVisible] = useState(false);
  const [highlightedStartTime, setHighlightedStartTime] = useState<string>('');
  const [filter, setFilter] = useState<'all' | 'Query' | 'Issue'>('all');
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const chatBotRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [form] = Form.useForm<FormValues>();

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current && selectedIndex !== null) {
      const selectedItem = listRef.current.querySelector(`.qa-item:nth-child(${selectedIndex + 1})`);
      if (selectedItem) {
        listRef.current.scrollTop = selectedItem.getBoundingClientRect().top - listRef.current.getBoundingClientRect().top + listRef.current.scrollTop;
      }
    }
  }, [selectedIndex]);

  // Update form field value when selectedIndex changes
  useEffect(() => {
    form.setFieldsValue({ question: qa[selectedIndex]?.question.query || '' });
  }, [selectedIndex, qa, form]);

  const onFinish = (values: FormValues) => {
    console.log('Form values:', values);
  };

  const generateTicketId = () => {
    const today = new Date();
    const date = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const randomInt = Math.floor(Math.random() * 1000); // Random integer
    return `RT${date}-${randomInt}`;
  };

  useEffect(() => {
    // Generate ticket ID on component mount
    setTicketId(generateTicketId());
  }, [])

  const handleEditQuestionClick = () => {
    setIsEditQuestionSliderVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleFormSubmit = (values: any) => {
    console.log('Form values:', values);  // You can make an API call here
    setIsModalVisible(false);  // Close the modal after form submission
  };
    

  const handleQuestionClick = (id: number, index: number, startTime: string, resources: {
    pdfs: Pdf[];
    tables: Table[];
    tickets: Ticket[];
    sops: Sop[];
  }) => {
    setSelectedIndex(index);
    onQuestionClick(id, index, startTime, resources);
    matchTranscriptReferences(startTime);
  };

  const matchTranscriptReferences = (startTime: string) => {
    setHighlightedStartTime(startTime);
    const matchedTranscripts = transcript.filter(item => item.start_time === startTime);
    setTranscriptReferences(matchedTranscripts);
  };

  const handleTicketClick = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsTicketSliderVisible(true);
  };

  const handleCloseTicketSlider = () => {
    setIsTicketSliderVisible(false);
  };

  const handleViewTranscriptClick = (startTime: string) => {
    setHighlightedStartTime(startTime);
    setIsTranscriptSliderVisible(true);
  };

  const handleCloseTranscriptSlider = () => {
    setIsTranscriptSliderVisible(false);
  };

  const handleEmailIconClick = () => {
    setIsQuestionEmailSliderVisible(true); // Opens the QuestionEmailResponseSlider
    setIsEmailSliderVisible(true);         // Ensures the slider is visible
  };

  const handleRaiseAQueryClick = () => {
    setIsChatBotVisible(true);
  }

  const handleEmailResponseClick = (id: number) => {
    setIsEmailSliderVisible(true);
  };

  const handleCloseEmailSlider = () => {
    setIsEmailSliderVisible(false);
  };

  const handleCloseEditQuestionSlider = () => {
    setIsEditQuestionSliderVisible(false);
  };

  // const handleSaveQuestion = (id: number) => {
  //   // Update the question and answer in the QA array
  //   // You can update your state or make an API call to save the changes
  // };

  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'ID',
      key: 'id',
      render: (text: string, record: Ticket) => (
        <a onClick={() => handleTicketClick(record)}>{text}</a>
      ),
    },
    {
      title: 'Summary',
      dataIndex: 'Summary',
      key: 'summary',
    },
    {
      title: '',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <Button
          type="link"
          icon={<ArrowRightOutlined />}
          onClick={() => handleTicketClick(record)}
        />
      ),
    },
  ];

  const filteredQA = qa.filter(item => 
    filter === 'all' || item.question.query_type === filter
  );

  const questions = [
    "What are the latest trends in cloud computing?",
    "How can I improve cybersecurity in my organization?",
    "What are the key principles of agile development?",
    "Can you explain the concept of microservices architecture?",
    "What are the best practices for data management?"
];
  
  return (
    <div>
      <div className='question-assistant-header-container'>
        <div className='question-assistant-header'>
          <h3>Questions and Assistant's Responses</h3>
          <Button
            className={filter === 'all' ? 'active-button' : 'inactive-button'} 
            type={filter === 'all' ? 'primary' : 'default'} 
            onClick={() => setFilter('all')}
          >
            All Themes
          </Button>
          <Button 
            className={filter === 'Query' ? 'active-button' : 'inactive-button'} 
            type={filter === 'Query' ? 'primary' : 'default'} 
            onClick={() => setFilter('Query')}
          >
            Query
          </Button>
          <Button 
            className={filter === 'Issue' ? 'active-button' : 'inactive-button'} 
            type={filter === 'Issue' ? 'primary' : 'default'} 
            onClick={() => setFilter('Issue')}
          >
            Issue
          </Button>
        </div>

        {/* <div className="filter-buttons">
          
        </div> */}
        <Divider />
      </div>

      <div className="qa-container">
        <div className="qa-list">
          {filteredQA.map((item, index) => (
            <div
              key={item.id}
              className={`qa-item ${index === selectedIndex ? 'qa-item-selected' : ''}`}
              onClick={() => handleQuestionClick(item.id, index, item.question.start_time, {
                pdfs: item.pdfs,
                tables: item.tables,
                tickets: item.tickets,
                sops: item.sops,
              })}
            >
              <div className="qa-item-content">
                <div className="qa-question">
                  <span>{item.question.query}</span>
                  <Button className='service-now-button' onClick={showModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M5.32943 3.27152C6.56252 2.83314 7.9923 3.10743 8.97927 4.0944C10.1002 5.21531 10.3019 6.90735 9.5843 8.23378L20.293 18.9436L18.8788 20.3579L8.16982 9.64869C6.84325 10.3668 5.15069 10.1653 4.02952 9.04415C3.04227 8.0569 2.7681 6.62659 3.20701 5.39326L5.44373 7.62994C6.02952 8.21572 6.97927 8.21572 7.56505 7.62994C8.15084 7.04415 8.15084 6.0944 7.56505 5.50862L5.32943 3.27152ZM15.6968 5.15506L18.8788 3.38729L20.293 4.80151L18.5252 7.98349L16.7574 8.33704L14.6361 10.4584L13.2219 9.04415L15.3432 6.92283L15.6968 5.15506ZM8.97927 13.2868L10.3935 14.701L5.09018 20.0043C4.69966 20.3948 4.06649 20.3948 3.67597 20.0043C3.31334 19.6417 3.28744 19.0698 3.59826 18.6773L3.67597 18.5901L8.97927 13.2868Z"></path>
                    </svg>                  
                    <span className="service-now-text">Create Service Request</span>
                  </Button>
                </div>
                <Tag className="qa-tag">{item.question.query_type}</Tag>
              </div>
            </div>
          ))}
        </div>

        <div className="qa-details">
          {selectedIndex !== null && (
            <div className="qa-detail-card">
              <div className='qa-header-question'>
                <h2>{qa[selectedIndex].question.query}</h2>
                <div className='qa-header-icons'>
                  <Button className='question-header-button' onClick={handleEditQuestionClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
                    </svg>
                    <span className="question-header-hover-text">Edit Question & Response</span>
                  </Button>
                  <Button className='question-header-button' onClick={handleEmailIconClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829Z"/>
                    </svg>
                    <span className="question-header-hover-text">Generate Response</span>
                  </Button>              
                  <Button className='question-header-button' onClick={handleRaiseAQueryClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"></path></svg>
                    <span className="question-header-hover-text">Raise Issue</span>
                  </Button>
                </div>
              </div>

              <div className="qa-answer">
                <p className='qa-answer-text' ><span className='qa-answer-response'>Response:</span> {qa[selectedIndex].answer}</p>
              </div>

              <Divider />

            {/* Transcript References Section */}
            <div className="transcript-section">
              <h5>Transcript References</h5>
              {transcriptReferences.length > 0 ? (
                transcriptReferences.map((ref, i) => (
                  <div key={i} className="transcript-reference">
                    <div className='speaker-comment-container'>
                      <p className="speaker">{ref.speaker} <span className="time">{ref.start_time}</span></p>
                      <p className="comment"><strong><i>"{ref.comment}"</i></strong></p>
                    </div>
                    <Button 
                      type="link" 
                      className="view-on-transcript" 
                      onClick={() => handleViewTranscriptClick(ref.start_time)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                      <path d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM8 5V8H4V5H8ZM4 14V10H8V14H4ZM4 16H8V19H4V16ZM10 16H20V19H10V16ZM20 14H10V10H20V14ZM20 5V8H10V5H20Z"/>
                    </svg>
                    View Transcript
                    </Button>
                  </div>
                ))
              ) : (
                <p>No matching transcript references found.</p>
              )}
            </div>

            {/* Training Documents Section */}
            <Divider />
            <div className="training-documents-section">
              <div className='training-documents-header'>
                <h5>Training Documents </h5>
                <Tag className='number-of-documents'>
                  {qa[selectedIndex].pdfs.some(pdf => pdf.name !== "NA") ? qa[selectedIndex].pdfs.length : 0}
                </Tag>
              </div>
              <div className="training-documents-list">
                {qa[selectedIndex].pdfs.some(pdf => pdf.name !== "NA") ? (
                  qa[selectedIndex].pdfs
                    .filter(pdf => pdf.name !== "NA")  // Filter out PDFs with name "NA"
                    .map((pdf, index) => (
                      <Button
                        key={index}
                        type="link"
                        href={pdf.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        icon={<FileTextOutlined />}
                        className="training-document-link"
                      >
                        {pdf.name}
                      </Button>
                    ))
                ) : (
                  <p>No relevant documents found.</p>
                )}
              </div>
            </div>

            {/* Related Tickets Section */}
            <Divider />
            <div className="related-tickets-section">
              <div className='related-tickets-header'>
                  <h5>Related Tickets </h5>
                  <Tag className='number-of-documents'>{qa[selectedIndex].tickets.length}</Tag>
              </div>
              <AntTable 
                dataSource={qa[selectedIndex].tickets} 
                columns={columns} 
                pagination={false} 
                rowKey="ID"
              />
            </div>
          </div>
        )}
      </div>

      {/* TicketSlider component */}
      {selectedTicket && (
        <TicketSlider
          ticket={selectedTicket}
          visible={isTicketSliderVisible}
          onClose={handleCloseTicketSlider}
        />
      )}

      {/* QuestionEmailResponseSlider component */}
      <QuestionEmailResponseSlider
        id={qa[selectedIndex].id}
        visible={isEmailSliderVisible}
        onClose={handleCloseEmailSlider}
      />

      {/* EditQuestionSlider component */}
      <EditQuestionSlider
        id={qa[selectedIndex].id}
        initialQuestion={qa[selectedIndex].question.query}
        initialAnswer={qa[selectedIndex].answer}
        visible={isEditQuestionSliderVisible}
        onClose={handleCloseEditQuestionSlider}
        qaDetails={qa[selectedIndex].question}
        // onSave={handleSaveQuestion}
      />

      {/* TranscriptSlider component */}
      {isTranscriptSliderVisible && (
        <ViewTranscriptSlider
          transcriptData={transcript}
          onClose={handleCloseTranscriptSlider}
          loading={false} // Set appropriate loading state
          highlightedStartTime={highlightedStartTime}
        />
      )}

      {!isChatBotVisible && (
            <div className="chatbot-toggle-1" onClick={() => setIsChatBotVisible(!isChatBotVisible)}>
              <span className="hover-text">Smart Bot</span>
              <div className="hexagon-container">
                <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9606 7.6155C19.7479 6.92473 18.2522 6.92473 17.0395 7.6155L8.23512 12.6303C7.0267 13.3186 6.28265 14.5883 6.28265 15.962V26.0378C6.28265 27.4115 7.0267 28.6812 8.23512 29.3695L17.0395 34.3843C18.2522 35.0751 19.7479 35.0751 20.9606 34.3843L29.765 29.3695C30.9734 28.6812 31.7174 27.4115 31.7174 26.0378V15.962C31.7174 14.5883 30.9734 13.3186 29.765 12.6303L20.9606 7.6155ZM37.587 12.6326C37.587 11.2589 36.8429 9.98924 35.6345 9.30095L20.9606 0.942884C19.7479 0.252113 18.2522 0.252111 17.0395 0.942882L2.36555 9.30094C1.15714 9.98924 0.413086 11.2589 0.413086 12.6326V29.3672C0.413086 30.7409 1.15714 32.0106 2.36555 32.6989L17.0395 41.0569C18.2522 41.7477 19.7479 41.7477 20.9606 41.057L35.6345 32.6989C36.8429 32.0106 37.587 30.7409 37.587 29.3672V12.6326Z" fill="white"/>
                  <path d="M17.0435 19.7605V4.63838L32.254 11.5664C33.703 12.2264 34.5991 13.6865 34.518 15.2554L33.674 31.5868L18.9726 23.0787C17.7774 22.3871 17.0435 21.1247 17.0435 19.7605Z" fill="white"/>
                </svg>
              </div>
              </div>
          )}
          {isChatBotVisible && (
            <div ref={chatBotRef} className="chatbot">
              <ChatBot isVisible={isChatBotVisible} onClose={() => setIsChatBotVisible(false)} questions={questions} setInitialQuery={qa[selectedIndex]?.question.query} />
            </div>
          )}
    </div>
    <Modal
      title="Create Service Request"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
    <Form layout="vertical" onFinish={handleFormSubmit} form={form}
        // onFinish={onFinish}
        initialValues={{ question: qa[selectedIndex]?.question.query || '' }}>
    <Form.Item
        label="Ticket ID"
        name="ticketId"
        initialValue={ticketId}
        rules={[{ required: true, message: 'Please input the ticket ID!' }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Question"
        name="question"
        rules={[{ required: true, message: 'Please input the question!' }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Raised By"
        name="Raised By"
        initialValue={store.userDetails.username}
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: 'Please input the description!' }]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>


      <Form.Item>
        <Button className='raise-ticket-button' type="primary" htmlType="submit">
          Raise Ticket
        </Button>
      </Form.Item>
    </Form>
  </Modal>
    </div>
  );
};

export default QASection;
