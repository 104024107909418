import { Upload, UploadProps } from "antd";
import React from "react";
import { Upload as UploadIcon } from "@styled-icons/boxicons-regular/Upload";
import MyButton from "../buttons";
const MyUpload = (props: UploadProps) => {
	return (
		<Upload {...props}>
			<MyButton type="default" icon={<UploadIcon size={16} />}>
				Click to Upload
			</MyButton>
		</Upload>
	);
};

export default MyUpload;
