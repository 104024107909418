import React, { useEffect } from "react";
import { IMessagesProps } from "../../model/IMessagesProps";
import { message } from "antd";

const myMessages = (config: IMessagesProps) => {
	if (config.destroy) return message.destroy(config.key);
	switch (config.type) {
		case "success":
			return message.success(
				{ content: config.text, key: config.key },
				config.duration
			);

		case "loading":
			return message.loading(
				{ content: config.text, key: config.key },
				config.duration
			);

		case "error":
			return message.error(
				{ content: config.text, key: config.key },
				config.duration
			);
	}
};

export default myMessages;
