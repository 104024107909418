import React from 'react';
import { Drawer, Button, Spin } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { selectStore } from '../../state/slice/appSlice';

interface QuestionEmailResponseSliderProps {
  id: number;
  visible: boolean;
  onClose: () => void;
}

const QuestionEmailResponseSlider: React.FC<QuestionEmailResponseSliderProps> = ({ id, visible, onClose }) => {
  const store = useSelector(selectStore);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [emailResponse, setEmailResponse] = React.useState<string>('');
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  React.useEffect(() => {
    const fetchEmailResponse = async () => {
      if (visible) {
        setLoading(true);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/generate_email/${store.selectedContract.id}/${store.userDetails.username}/${id}`
          );

          // Log response data for debugging
          console.log('API Response:', res.data);

          // Process the response to convert \n to <br> and \n\n to two <br> tags
          const processedResponse = res.data
            .replace(/\n\n/g, '<br><br>')
            .replace(/\n/g, '<br>');

          setEmailResponse(processedResponse); // Update state with processed response
        } catch (error) {
          console.error('Failed to fetch email response', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEmailResponse();
  }, [visible, id, store.selectedContract.id, store.userDetails.username]);

  return (
    <Drawer
      title="Generated Response"
      placement="right"
      closable={true}
      onClose={onClose}
      className="audio-transcript-drawer"
      open={visible} // Correct usage of `visible` prop
      width={500}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button
            type="default"
            className="close-action-button"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type="default"
            className="share-response-action-button"
          >
            Share Response
          </Button>
        </div>
      }
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <div className='email-response-content'>
          <h6>Share With</h6>
          <input
            className={`send-email-input ${emailError ? 'error' : ''}`}
            type="email"
            placeholder="Enter email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            required
          />
          {emailError && <p className="error-text">Please enter a valid email address.</p>}

          <h6>Generated Response</h6>
          <div 
            className='generated-response-textarea'
            contentEditable
            dangerouslySetInnerHTML={{ __html: emailResponse }}
          />
          <div className='view-change-log-container'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0.00100708C3.13437 0.00100708 0 3.13538 0 7.00101C0 10.8666 3.13437 14.001 7 14.001C10.8656 14.001 14 10.8666 14 7.00101C14 3.13538 10.8656 0.00100708 7 0.00100708ZM7 12.8135C3.79063 12.8135 1.1875 10.2104 1.1875 7.00101C1.1875 3.79163 3.79063 1.18851 7 1.18851C10.2094 1.18851 12.8125 3.79163 12.8125 7.00101C12.8125 10.2104 10.2094 12.8135 7 12.8135Z" fill="#27A6A4"/>
            <path d="M9.72981 8.97767L7.50168 7.36673V3.49954C7.50168 3.43079 7.44543 3.37454 7.37668 3.37454H6.62512C6.55637 3.37454 6.50012 3.43079 6.50012 3.49954V7.80267C6.50012 7.84329 6.51887 7.88079 6.55168 7.90423L9.13606 9.7886C9.19231 9.82923 9.27043 9.81673 9.31106 9.76204L9.75793 9.15267C9.79856 9.09486 9.78606 9.01673 9.72981 8.97767Z" fill="#27A6A4"/>
            </svg>
            <span>View Change log</span>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default QuestionEmailResponseSlider;
