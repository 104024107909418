import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import AudioPlayer from '../../components/audioPlayer';
import TranscriptCard from '../../components/transcriptCard';
import QASection from '../../components/QASection';
import ResourceCardPdf from '../../components/resourceCardPdf';
import ResourceCardTicket from '../../components/resourceCardTicket';
import ResourceCardTable from '../../components/resourceCardTable';
import ResourceCardSOP from '../../components/resourceCardSOP';
import EmailModal from '../../components/emailModal';
import { useSelector } from "react-redux";
import { AudioTranscript, Pdf, Ticket, Table, Sop } from '../../model/IAudioTranscription';
import { selectStore } from "../../state/slice/appSlice";
import { Spin } from 'antd';
import { Chatbot as ChatbotIcon } from "@styled-icons/simple-icons/Chatbot";
import ErrorPage from '../errorPage';
import axios from 'axios';
import ChatBot from '../../components/chatbot';

const App = () => {
//   const store = useSelector(selectStore);
//   const [data, setData] = useState<AudioTranscript | null>(null);
//   const [showModal, setShowModal] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<boolean>(false);
//   const [selectedResources, setSelectedResources] = useState<{
//     pdfs: Pdf[];
//     tickets: Ticket[];
//     tables: Table[];
//     sops: Sop[];
//   }>({ pdfs: [], tickets: [], tables: [], sops: [] });
//   const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
//   const [selectedId, setSelectedId] = useState<number | null>(null);
//   const [isChatBotVisible, setIsChatBotVisible] = useState(false);
//   const chatBotRef = useRef<HTMLDivElement>(null);
//   const [emailLoading, setEmailLoading] = useState(false);
//   const [emailResponse, setEmailResponse] = useState<string>('');
//   const [highlightedStartTime, setHighlightedStartTime] = useState<string | null>(null);

//   const fetchData = async () => {
//     try {
//       const res = await axios.get(
//         `${process.env.REACT_APP_API_URL}/get_audio_data/${store.selectedContract.id}/${store.userDetails.username}`
//       );
//       if (res.data) {
//         setData(res.data);
//         console.log("Fetched Data:", res.data);
//       }
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setError(true);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (store.selectedContract.id && store.userDetails.username) {
//       fetchData();
//     } else {
//       setLoading(false);
//       setError(true);
//     }
//   }, [store.selectedContract, store.userDetails]);

//   const handleQuestionClick = (id: number, index: number, startTime: string, resources: {
//     pdfs: Pdf[];
//     tables: Table[];
//     tickets: Ticket[];
//     sops: Sop[];
//   }) => {
//     setSelectedId(id);
//     setSelectedIndex(index);
//     setSelectedResources(resources);
//     setHighlightedStartTime(startTime);
//   };

//   const handleSendEmail = (email: string, summary: string) => {
//     console.log(`Email sent to ${email} with summary: ${summary}`);
//   };

//   const fetchEmailResponse = async () => {
//     setEmailLoading(true);
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/generate_email/${data?.audio_id}/${store.userDetails.username}/-1`);
//       if (res.data) {
//         setEmailResponse(res.data);
//       }
//     } catch (error) {
//       console.error('Error fetching email response:', error);
//     } finally {
//       setEmailLoading(false);
//     }
//   };

//   const handleGenerateEmailResponse = () => {
//     setShowModal(true);
//     fetchEmailResponse();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (chatBotRef.current && !chatBotRef.current.contains(event.target as Node)) {
//         setIsChatBotVisible(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [chatBotRef]);

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center', padding: '50px' }}>
//         <Spin size="large" />
//       </div>
//     );
//   }

//   if (error || !data) {
//     return <ErrorPage />;
//   }

//   return (
//     <>
//       <div className="app">
//         <div className='button-container'>
//           <button className="email-response" onClick={handleGenerateEmailResponse}>Generate Email Response</button>
//         </div>
//         <AudioPlayer name={data.audioName} src={data.audioSrc} />
//         <br />
//         <br />
//         <TranscriptCard summary={data.summary} transcript={data.transcript} highlightedStartTime={highlightedStartTime}/>
//         <QASection qa={data.qa} selectedIndex={selectedIndex} onQuestionClick={handleQuestionClick} />
//         {showModal && (
//           <EmailModal
//             emailSummary={emailResponse}
//             onClose={() => setShowModal(false)}
//             onSend={handleSendEmail}
//             loading={emailLoading}
//           />
//         )}
//         {selectedId !== null && (
//           <div>
//             <h3>Response Generation Sources</h3>
//             <br />
//             <div className="cards">
//               <div className='resource-cards'>
//                 <h3>Training Docs</h3>
//                 {selectedResources.pdfs.length > 0 ? (
//                   selectedResources.pdfs.map((pdf, index) => (
//                     <ResourceCardPdf
//                       key={index}
//                       name={pdf.name}
//                       url={pdf.url}
//                     />
//                   ))
//                 ) : (
//                   <h6>No PDFs Found</h6>
//                 )}
//               </div>
              
//               <div className='resource-cards'>
//                 <h3>Ticketing System</h3>
//                 {selectedResources.tickets.length > 0 ? (
//                   selectedResources.tickets.map((ticket, index) => (
//                     <ResourceCardTicket
//                       key={index}
//                       ticket={ticket}
//                     />
//                   ))
//                 ) : (
//                   <h6>No Tickets Found</h6>
//                 )}
//               </div>

//               <div className='resource-cards'>
//                 <h3>Database</h3>
//                 <h6>No Relevant Tables Found</h6>
//               </div>

//               <div className='resource-cards'>
//                 <h3>SOPs</h3>
//                 <h6>No Relevant SOPs Found</h6>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//       {!isChatBotVisible && (
//         <div className="chatbot-toggle" onClick={() => setIsChatBotVisible(!isChatBotVisible)}>
//           <ChatbotIcon size={20} />
//           <span className="hover-text">Ask Me Anything</span>
//         </div>
//       )}
//       {isChatBotVisible && (
//         <div ref={chatBotRef}>
//           <ChatBot isVisible={isChatBotVisible} onClose={() => setIsChatBotVisible(false)} />
//         </div>
//       )}
//     </>
//   );
};

export default App;
