import React, { useEffect, useRef, useState } from "react";
import { IChatProps } from "../../model/IChatProps";
import MyInput from "../input";
import { Send } from "@styled-icons/bootstrap/Send";
import { Card, Spin, Tooltip } from "antd";
import { IChatComponentProps } from "../../model/IChatComponentPropsSanofi";
import { Chatbot } from "@styled-icons/simple-icons/Chatbot";
import Cursor from "../cursor";
import "./index.css";

const ExtractAIChat = (props: IChatComponentProps) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");
  const [chatHeight, setChatHeight] = useState(0);

  // Function to keep chat scrolled to bottom
  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  // Remove 'data:' from messages
  const processMessages = (messages: IChatProps[]) => {
    return messages.map((chat) => {
      let text = chat.text || "";
  
      // Remove 'data:' prefix
      text = text.replace(/data:/g, "");
  
      // Handle final message and extract content between ***FINAL*** tags
      if (text.includes("***FINAL***")) {
        const finalTextMatch = text.match(/\*\*\*FINAL\*\*\*\s*<p>(.+)<\/p>/);
        if (finalTextMatch) {
          text = finalTextMatch[1]; // Extract the final message content
        }
      }
  
      // Handle other control characters, e.g., context markers
      if (text.includes("$$$context$$$")) {
        text = ""; // You may want to ignore context messages entirely
      }
  
      return { ...chat, text };
    });
  };
  
  

  // Scroll to bottom on initial render and whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [props.chats]);
  

  // Calculate and set chat height
  useEffect(() => {
    const h = document.getElementById("chat-container")?.clientHeight;
    setChatHeight(h || 0);
  }, [props.chats]);

  const setQuestion = (e: React.MouseEvent<HTMLDivElement>) => {
    const question = (e.target as HTMLElement).innerText;
    setQuery(question);
    props.handleSend(question);
    setQuery("");
  };

  return (
    <div className="bg-chat-bg rounded-md">
      <div
        className="overflow-y-auto flex flex-col"
        style={{
          maxHeight: `${chatHeight - 55}px`,
          minHeight: `${chatHeight - 55}px`,
        }}
        ref={chatRef}
      >
        {!props.loading &&
          processMessages(props.chats).map((chat, index) => (
            <div
              key={index}
              className={`p-4 w-full flex ${
                chat.source === "system" ? "" : "justify-end"
              }`}
              onClick={() => props.handleChatClick(index)}
            >
              {chat.source === "system" && (
                <div className="bg-chat-system-icon rounded-full h-8 w-8 flex justify-center items-center text-sm mr-2">
                  A
                </div>
              )}
              <div
                className={`w-5/6 flex ${
                  chat.source === "system" ? "" : "justify-end"
                }`}
              >
                <div
                  className={`w-fit p-4 ${
                    chat.source === "system"
                      ? "bg-chat-system-bg rounded-r-md rounded-bl-md"
                      : "bg-chat-user-bg rounded-l-md rounded-br-md"
                  }`}
                >
                  {props.blockSend &&
                  chat.source === "system" &&
                  index === props.chats.length - 1 ? (
                    <Cursor text={chat.text} />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: chat.text,
                      }}
                    ></div>
                  )}
                </div>
              </div>
              {chat.source !== "system" && (
                <div className="bg-chat-user-icon rounded-full h-8 w-8 flex justify-center items-center text-sm ml-2">
                  {chat.source[0].toLocaleUpperCase()}
                </div>
              )}
            </div>
          ))}
        {props.loading && (
          <div
            className="flex items-center justify-center text-text-zs-orange"
            style={{
              maxHeight: `${chatHeight - 55}px`,
              minHeight: `${chatHeight - 55}px`,
            }}
          >
            <Spin size="large"></Spin>
          </div>
        )}
        {!props.loading && props.chats.length === 0 && (
          <>
            <div
              className="flex flex-col items-center justify-center relative"
              style={{
                maxHeight: `${chatHeight - 55}px`,
                minHeight: `${chatHeight - 55}px`,
              }}
            >
              <div className="text-gray-500">
                <Chatbot size={42} />
              </div>
              <div className="text-2xl font-normal">
                How can I help you today?
              </div>
              <div
                className="absolute bottom-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "10rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  {/* Example Question Cards */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginTop: "1rem",
                  }}
                >
                  <Card
                    className="quesCard"
                    onClick={setQuestion}
                    hoverable={true}
                  >
                    <Tooltip title="Click to send">
                      <p>
                        Are there any previous cases and solutions for needing
                        access to UAT environment?
                      </p>
                    </Tooltip>
                  </Card>
                  <Card
                    className="quesCard"
                    onClick={setQuestion}
                    hoverable={true}
                  >
                    <Tooltip title="Click to send">
                      <p>
                        Can you tell me the exact steps to enable remote desktop
                        application, if it is not installed (for non UCB laptop
                        users?)
                      </p>
                    </Tooltip>
                  </Card>
                  <Card
                    className="quesCard"
                    onClick={setQuestion}
                    hoverable={true}
                  >
                    <Tooltip title="Click to send">
                      <p>
                        What are the pre-requisites and the process steps to
                        access virtual desktop?
                      </p>
                    </Tooltip>
                  </Card>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="bg-white mx-2 px-2 py-2 flex items-center">
        <MyInput
          value={query}
          placeholder="Type a message"
          className="border-1 border-border-zs-orange rounded-full flex-1"
          onChange={(e) => setQuery(e.target.value)}
          onPressEnter={() => {
            props.handleSend(query);
            setQuery("");
          }}
          disabled={props.loading || props.blockSend}
        />
        <div className="flex items-center justify-end ml-2">
          {props.blockSend ? (
            <Spin size="small"></Spin>
          ) : (
            <Send
              size={15}
              className="cursor-pointer"
              onClick={() => {
                if (!props.loading && query) {
                  props.handleSend(query);
                  setQuery("");
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExtractAIChat;
