import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Tag } from "antd";
import "./index.css";
import { ITableData } from "../../model/ITableData";
import { useDispatch, useSelector } from "react-redux";
import {
	selectStore,
	setContractDetails,
	setUserDetails,
} from "../../state/slice/appSlice";
import axios from "axios";
import ErrorPage from "../errorPage";
import myMessages from "../../components/messages";

const ContractReview = () => {
	const store = useSelector(selectStore);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<ITableData[]>();
	const [tableDataCopy, setTableDataCopy] = useState<ITableData[]>();
	const [columns, setColumns] = useState<any>();
	const [error, setError] = useState<boolean>();
	const [batchId, setBatchId] = useState<any>();

	const reviewContract = (record: any) => {
		dispatch(
			setContractDetails({ name: record.contractName, id: record.id })
		);
		sessionStorage.setItem("contractId", record.id);
		sessionStorage.setItem("contractName", record.contractName);
		navigate("" + record.id);
	};

	const filterData = (data: any) => (formatter: any) => {
		const uniqueValues = new Set();

		return data
			.filter((item: any) => {
				const formattedValue = formatter(item);
				if (!uniqueValues.has(formattedValue)) {
					uniqueValues.add(formattedValue);
					return true;
				}
				return false;
			})
			.map((item: any) => ({
				text: formatter(item),
				value: formatter(item),
			}));
	};

	function getTagColor(record: any) {
		if (record.status === "Open") {
			return (
				<>
					<Tag bordered={false} color={"warning"} key={record.status}>
						{record.status}
					</Tag>
				</>
			);
		} else if (record.status === "Closed") {
			return (
				<>
					<Tag bordered={false} color={"success"} key={record.status}>
						{record.status}
					</Tag>
				</>
			);
		} else if (record.status === "Reviewed") {
			return (
				<>
					<Tag
						bordered={false}
						color={"processing"}
						key={record.status}
					>
						{record.status}
					</Tag>
				</>
			);
		}
	}

	const handleChange = (pagination: any, filters: any, sorter: any) => {
		const filteredData = tableData?.filter((item) => {
			if (!filters.contractName || filters.contractName.length === 0) {
				return true;
			}
			return filters.contractName.includes(item.contractName);
		});
		setTableDataCopy(filteredData);
	};

	const fetchData = async () => {
		// setLoading(true);
		// const data: ITableData[] = [
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// 	{
		// 		id: 1,
		// 		contractName: "Contract 1",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit",
		// 		reviewerName: "Dhruv Gupta",
		// 		reviewerEmpID: "27025",
		// 		status: "Open",
		// 	},
		// 	{
		// 		id: 2,
		// 		contractName: "Contract 2",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 2",
		// 		reviewerName: "Moyeen Sarfaraz",
		// 		reviewerEmpID: "30001",
		// 		status: "Closed",
		// 	},
		// 	{
		// 		id: 3,
		// 		contractName: "Contract 3",
		// 		contractDesc:
		// 			"Lorem, ipsum dolor sit amet consectetur adipisicing elit 3",
		// 		reviewerName: "Ayushi Agarwal",
		// 		reviewerEmpID: "20384",
		// 		status: "Reviewed",
		// 	},
		// ];
		// setTableData(data);

		axios
			.get(
				"https://intelligent-automation.api.demo.zsservices.com/contracts/" +
					store.userDetails.username +
					"/"+
					store.selectedContract.id
			)
			.then((res: any) => {
				console.log("FETCHED DATA", res.data);
				if (Array.isArray(res.data)) {
					const columnsTb2 = [
						{
							title: "ID",
							dataIndex: "id",
							key: "id",
							align: "center" as "center",
							width: "5%",
						},
						{
							title: "Contract Name",
							dataIndex: "contractName",
							key: "contractName",
							// align: "center" as "center",
							onFilter: (value: any, record: any) =>
								record.contractName === value,
							filters: filterData(res.data)(
								(i: any) => i.contractName
							),
							filterSearch: true,
							width: "20%",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.contractName.localeCompare(b.contractName),
							render: (_: any, record: any) => {
								return (
									<>
										<div
											style={{
												color: "#27a6a4",
												fontSize: "14px",
												fontWeight: 500,
												cursor: "pointer",
											}}
											onClick={() => reviewContract(record)}
										>
											{record.contractName.split(".pdf")[0]}
										</div>
									</>
								);
							},
						},
						{
							title: "Contract Description",
							dataIndex: "contractDesc",
							key: "contractDesc",
							// align: "center" as "center",
							width: "30%",
						},
						{
							title: "Date Uploaded",
							align: "center" as "center",
							key: "dateUploaded",
							dataIndex: "dateUploaded",
							width: "15%",
							onFilter: (value: any, record: any) =>
								record.dateUploaded === value,
							filters: filterData(res.data)(
								(i: any) => i.dateUploaded
							),
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								new Date(a.dateUploaded).getTime() -
								new Date(b.dateUploaded).getTime(),
						},
						{
							title: "Uploaded By",
							dataIndex: "reviewerName",
							key: "reviewerName",
							width: "15%",
							// align: "center" as "center",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.reviewerName.localeCompare(b.reviewerName),
							render: (_: any, record: any) => {
								return (
									<div className="grid grid-cols-12 items-center">
										<div className="col-span-2"></div>
										<div className="col-span-2">
											<img
												className="border-2 border-border-zs-teal"
												src={
													"https://zs-adfs01.zsassociates.com/photos/" +
													record.reviewerEmpID +
													".jpg"
												}
												style={{
													borderRadius: "50%",
													width: "1.625rem",
													height: "1.625rem",
													display: "inline-block",
													// paddingRight: "0.125rem",
													marginRight: "0.125rem",
												}}
											/>
										</div>

										<div className="col-span-8">
											{record.reviewerName}
										</div>
										<div className="col-span-2"></div>
									</div>
								);
							},
							onFilter: (value: any, record: any) =>
								record.reviewerName === value,
							filters: filterData(res.data)(
								(i: any) => i.reviewerName
							),
							filterSearch: true,
						},
						{
							title: "Status",
							key: "status",
							dataIndex: "status",
							render: (_: any, record: any) => getTagColor(record),
							align: "center" as "center",
							onFilter: (value: any, record: any) =>
								record.status === value,
							filters: filterData(res.data)((i: any) => i.status),
							filterSearch: true,
							width: "15%",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.status.localeCompare(b.status),
						},
					];
					setColumns(columnsTb2);
					setTableData(res.data);
				}
					
				else {
					const contracts = res.data.contracts;
					const batch_id = res.data.batch_id;
					setBatchId(batch_id);
					const sortedData = contracts.sort((a: any, b: any) => {
						if (a.agreementno < b.agreementno) return -1;
						if (a.agreementno > b.agreementno) return 1;
						return 0;
					});
					const columnsTbl = [
						{
							title: "ID",
							dataIndex: "id",
							key: "id",
							align: "center" as "center",
							width: "5%",
						},
						{
							title: "Contract Name",
							dataIndex: "contractName",
							key: "contractName",
							// align: "center" as "center",
							onFilter: (value: any, record: any) =>
								record.contractName === value,
							filters: filterData(contracts)(
								(i: any) => i.contractName
							),
							filterSearch: true,
							width: "20%",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.contractName.localeCompare(b.contractName),
							render: (_: any, record: any) => {
								return (
									<>
										<div
											style={{
												color: "#27a6a4",
												fontSize: "14px",
												fontWeight: 500,
												cursor: "pointer",
											}}
											onClick={() => reviewContract(record)}
										>
											{record.contractName.split(".pdf")[0]}
										</div>
									</>
								);
							},
						},
						{
							title: "Agreement No.",
							dataIndex: "agreementno",
							key: "agreementno",
							align: "center",
							width: "10%",
							render: (value: any, row: any, index: any) => {
								const obj: any = {
									children: value,
									props: {},
								};
								if (
									sortedData?.findIndex(
										(el: any) =>
											el.agreementno == row.agreementno
									) == index
								) {
									obj.props.rowSpan = sortedData?.filter(
										(an: any) =>
											an.agreementno == row.agreementno
									).length;
								} else {
									obj.props.rowSpan = 0;
								}
								return obj;
							},
						},
						{
							title: "Contract Description",
							dataIndex: "contractDesc",
							key: "contractDesc",
							// align: "center" as "center",
							width: "30%",
						},
						{
							title: "Date Uploaded",
							align: "center" as "center",
							key: "dateUploaded",
							dataIndex: "dateUploaded",
							width: "15%",
							onFilter: (value: any, record: any) =>
								record.dateUploaded === value,
							filters: filterData(contracts)(
								(i: any) => i.dateUploaded
							),
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								new Date(a.dateUploaded).getTime() -
								new Date(b.dateUploaded).getTime(),
						},
						{
							title: "Uploaded By",
							dataIndex: "reviewerName",
							key: "reviewerName",
							width: "15%",
							// align: "center" as "center",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.reviewerName.localeCompare(b.reviewerName),
							render: (_: any, record: any) => {
								return (
									<div className="grid grid-cols-12 items-center">
										<div className="col-span-2"></div>
										<div className="col-span-2">
											<img
												className="border-2 border-border-zs-teal"
												src={
													"https://zs-adfs01.zsassociates.com/photos/" +
													record.reviewerEmpID +
													".jpg"
												}
												style={{
													borderRadius: "50%",
													width: "1.625rem",
													height: "1.625rem",
													display: "inline-block",
													// paddingRight: "0.125rem",
													marginRight: "0.125rem",
												}}
											/>
										</div>

										<div className="col-span-8">
											{record.reviewerName}
										</div>
										<div className="col-span-2"></div>
									</div>
								);
							},
							onFilter: (value: any, record: any) =>
								record.reviewerName === value,
							filters: filterData(contracts)(
								(i: any) => i.reviewerName
							),
							filterSearch: true,
						},
						{
							title: "Status",
							key: "status",
							dataIndex: "status",
							render: (_: any, record: any) => getTagColor(record),
							align: "center" as "center",
							onFilter: (value: any, record: any) =>
								record.status === value,
							filters: filterData(contracts)((i: any) => i.status),
							filterSearch: true,
							width: "15%",
							// defaultSortOrder: "ascend",
							sorter: (a: any, b: any) =>
								a.status.localeCompare(b.status),
						},
					];
					setColumns(columnsTbl);
					setTableData(sortedData);
					setTableDataCopy(sortedData);
				}
				setLoading(false);
			})
			.catch((e) => setError(true));
	};

	useEffect(() => {
		if (store.userDetails.username) fetchData();
	}, [store.userDetails.username]);

	useEffect(() => {
		if (!store.userDetails.username)
			dispatch(
				setUserDetails({
					username: sessionStorage.getItem("username") || "",
				})
			);
	}, []);

	useEffect(() => {
		if (tableData !== undefined && batchId===8 ) {
			console.log("BATCH ID IS 8")
			const columnsTb2 = [
				{
					title: "ID",
					dataIndex: "id",
					key: "id",
					align: "center" as "center",
					width: "5%",
				},
				{
					title: "Contract Name",
					dataIndex: "contractName",
					key: "contractName",
					// align: "center" as "center",
					onFilter: (value: any, record: any) =>
						record.contractName === value,
					filters: filterData(tableData)((i: any) => i.contractName),
					filterSearch: true,
					width: "20%",
					// defaultSortOrder: "ascend",
					// sorter: (a: any, b: any) =>
					// 	a.contractName.localeCompare(b.contractName),
					render: (_: any, record: any) => {
						return (
							<>
								<div
									style={{
										color: "#27a6a4",
										fontSize: "14px",
										fontWeight: 500,
										cursor: "pointer",
									}}
									onClick={() => reviewContract(record)}
								>
									{record.contractName.split(".pdf")[0]}
								</div>
							</>
						);
					},
				},
				{
					title: "Agreement No.",
					dataIndex: "agreementno",
					key: "agreementno",
					align: "center",
					width: "10%",
					render: (value: any, row: any, index: any) => {
						console.log(
							"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
							tableDataCopy
						);
						const obj: any = {
							children: value,
							props: {},
						};
						if (
							tableDataCopy?.findIndex(
								(el) => el.agreementno == row.agreementno
							) == index
						) {
							obj.props.rowSpan = tableDataCopy?.filter(
								(an) => an.agreementno == row.agreementno
							).length;
						} else {
							obj.props.rowSpan = 0;
						}
						return obj;
					},
				},
				{
					title: "Contract Description",
					dataIndex: "contractDesc",
					key: "contractDesc",
					// align: "center" as "center",
					width: "30%",
				},
				{
					title: "Date Uploaded",
					align: "center" as "center",
					key: "dateUploaded",
					dataIndex: "dateUploaded",
					width: "15%",
					// onFilter: (value: any, record: any) =>
					// 	record.dateUploaded === value,
					// filters: filterData(tableData)((i: any) => i.dateUploaded),
					// // defaultSortOrder: "ascend",
					// sorter: (a: any, b: any) =>
					// 	new Date(a.dateUploaded).getTime() -
					// 	new Date(b.dateUploaded).getTime(),
				},
				{
					title: "Uploaded By",
					dataIndex: "reviewerName",
					key: "reviewerName",
					width: "15%",
					// align: "center" as "center",
					// defaultSortOrder: "ascend",
					// sorter: (a: any, b: any) =>
					// 	a.reviewerName.localeCompare(b.reviewerName),
					render: (_: any, record: any) => {
						return (
							<div className="grid grid-cols-12 items-center">
								<div className="col-span-2"></div>
								<div className="col-span-2">
									<img
										className="border-2 border-border-zs-teal"
										src={
											"https://zs-adfs01.zsassociates.com/photos/" +
											record.reviewerEmpID +
											".jpg"
										}
										style={{
											borderRadius: "50%",
											width: "1.625rem",
											height: "1.625rem",
											display: "inline-block",
											// paddingRight: "0.125rem",
											marginRight: "0.125rem",
										}}
									/>
								</div>

								<div className="col-span-8">
									{record.reviewerName}
								</div>
								<div className="col-span-2"></div>
							</div>
						);
					},
					// onFilter: (value: any, record: any) =>
					// 	record.reviewerName === value,
					// filters: filterData(tableData)((i: any) => i.reviewerName),
					// filterSearch: true,
				},
				{
					title: "Status",
					key: "status",
					dataIndex: "status",
					render: (_: any, record: any) => getTagColor(record),
					align: "center" as "center",
					// onFilter: (value: any, record: any) =>
					// 	record.status === value,
					// filters: filterData(tableData)((i: any) => i.status),
					// filterSearch: true,
					width: "15%",
					// defaultSortOrder: "ascend",
					// sorter: (a: any, b: any) =>
					// 	a.status.localeCompare(b.status),
				},
			];
			
			setColumns(columnsTb2);
		}
	}, [tableDataCopy]);

	return (
		<>
			{!error ? (
				<Table
					loading={loading}
					className="contractTable ml-4 mr-4 mt-8"
					columns={columns}
					dataSource={tableData}
					size="small"
					style={{
						borderRadius: "8px",
						boxShadow: "0 3px 6px 0px rgba(0, 0, 0, 0.2)",
					}}
					scroll={{ y: "calc(100vh - 160px)" }}
					pagination={{
						defaultPageSize: 16,
						showSizeChanger: false,
					}}
					rowClassName="rown"
					onChange={handleChange}
				/>
			) : (
				<ErrorPage />
			)}
		</>
	);
};

export default ContractReview;
