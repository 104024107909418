import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopNavbar from "../../components/topNavbar";
import { routesConfig } from "../../configs/routes";
import { IRouteConfig } from "../../model/IRouteConfig";
import Sidebar from "../../components/sidebar";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../state/slice/appSlice";

const Layout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			setUserDetails({
				username: sessionStorage.getItem("username") || "",
			})
		);
		const apiUrl = process.env.REACT_APP_API_URL;
		console.log("ENV FILE", apiUrl);
	}, []);
	const renderRoutes = (routes: IRouteConfig[]) => {
		return routes.map((route: IRouteConfig, index1: number) => (
			<Route key={index1} {...route}>
				{route.children && renderRoutes(route.children)}
			</Route>
		));
	};
	return (
		<div className="flex w-full">
			<BrowserRouter>
				<Sidebar />
				<div className="h-screen flex-grow overflow-y-auto">
					<Routes>{renderRoutes(routesConfig)}</Routes>
				</div>
			</BrowserRouter>
		</div>
	);
};
export default Layout;
