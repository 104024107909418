import { useEffect } from "react";
import Layout from "../pages/layout";
import { useDispatch } from "react-redux";
import {
	setSelectedActiveAgent,
	setUserDetails,
} from "../state/slice/appSlice";

const Home = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			setSelectedActiveAgent({
				name: sessionStorage.getItem("agentName") || "",
				id: Number(sessionStorage.getItem("agentId") || ""),
				icon: "",
			})
		);
		dispatch(
			setUserDetails({
				username: sessionStorage.getItem("username") || "",
			})
		);
	}, []);
	return <Layout />;
};

export default Home;
