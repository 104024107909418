import { ReactComponent as Fallback } from "./contracts-ai.svg";
import { ReactComponent as SupportAssistAi } from "./supportAssist-ai.svg";
import { ReactComponent as ContractsAi } from "./contractsAI.svg";  
import { ReactComponent as FormExtractorAi } from "./formExtractor.svg";
import { ReactComponent as InvoiceAi } from "./invoiceAi.svg";
import { ReactComponent as AssistantAi } from "./assistantsAi.svg";
import { ReactComponent as ChatAi } from "./chatAi.svg";
import { ReactComponent as FormularyAi } from "./formularyAi.svg";
import { ReactComponent as TrainAssistAI } from "./trainAssistAi.svg";
import { PieChart } from "@styled-icons/bootstrap/PieChart";
import { GraphUpArrow } from "@styled-icons/bootstrap/GraphUpArrow";
import { Cylinder } from "@styled-icons/boxicons-regular/Cylinder";
import { HardDrive } from "@styled-icons/fa-regular/HardDrive";
export const icons = [
	{
		name: "contracts-ai",
		icon: <Fallback />,
	},
	{
		name: "ContractsAi",
		icon: <ContractsAi />,
	},
	{
		name: "SupportAssistAi",
		icon: <SupportAssistAi />,
	},
	{
		name: "FormExtractorAi",
		icon: <FormExtractorAi />,
	},
	{
		name: "InvoiceAi",
		icon: <InvoiceAi />,
	},
	{
		name: "AssistantAi",
		icon: <AssistantAi />,
	},
	{
		name: "ChatAi",
		icon: <ChatAi />,
	},
	{
		name: "FormularyAi",
		icon: <FormularyAi />,
	},
	{
		name: "TrainAssistAI",
		icon: <TrainAssistAI />,
	},
	{
		name: "pie-chart",
		icon: <PieChart size={16} />,
	},
	{
		name: "graph-arrow-up",
		icon: <GraphUpArrow size={16} />,
	},
	{
		name: "cylinder",
		icon: <Cylinder size={16} />,
	},
	{
		name: "hard-drive",
		icon: <HardDrive size={16} />,
	},
];
