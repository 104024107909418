import React from 'react';
import { Modal, Tabs } from 'antd';
import { IContextData } from '../../model/IContextData';
import './index.css';

const { TabPane } = Tabs;

interface ContextModalProps {
  isVisible: boolean;
  onClose: () => void;
  contextData: IContextData | null; // Allow null for contextData
}

const ContextModal: React.FC<ContextModalProps> = ({ isVisible, onClose, contextData }) => {
  // Destructure with default empty array fallback if contextData is null
  const { context = [], context_excel = [] } = contextData || {};

  return (
    <Modal
      open={isVisible}
      footer={null}
      onCancel={onClose}
      className="modal-custom"
    >
      <Tabs defaultActiveKey="1" className="modal-tabs">
        <TabPane tab="Document Context" key="1" className="modal-tab">
          {context.length > 0 ? (
            <div className="context-container">
              {context.map((doc, index) => (
                <div key={index} className="doc-container">
                  <h4 className="doc-title">{doc.doc_name}</h4>
                  <p className="doc-text">{doc.text.join(' ')}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-content">No document context available.</p>
          )}
        </TabPane>
        <TabPane tab="Tickets" key="2" className="modal-tab">
          {context_excel.length > 0 ? (
            <div className="table-container">
              <table className="excel-table">
                <thead>
                  <tr>
                    {Object.keys(context_excel[0]).map((key, index) => (
                      <th key={index}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {context_excel.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, cellIndex) => (
                        <td key={cellIndex}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="no-content">No Excel data available.</p>
          )}
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ContextModal;
